._Footer_dhnzn_1 {
  display: flex;
  width: 100%;
  align-self: flex-end;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-grow: 1;
}

._PrimaryMetric_1ey7s_1 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-width: 0;
  gap: var(--p-space-200);
}

._PrimaryMetric_1ey7s_1._Compact_1ey7s_8 {
  flex-grow: 1;
}

._PrimaryMetric_1ey7s_1._Reverse_1ey7s_12 {
  flex-direction: row-reverse;
}

._PrimaryMetric_1ey7s_1:not(:only-child) {
  flex-shrink: 0;
}

._TrendIndicator_1ey7s_21 {
  flex-shrink: 0;
}

._PrimaryMetricValue_1ey7s_25 {
  font-size: var(--p-font-size-500);
  font-weight: var(--p-font-weight-semibold);
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
}

._PrimaryMetricValue_1ey7s_25._Disabled_1ey7s_32 {
  color: var(--p-color-text-disabled);
}

._PrimaryMetricValue_1ey7s_25._Compact_1ey7s_8 {
  font-size: var(--p-font-size-325);
}

._OverflowEllipsis_1ey7s_41 {
  text-overflow: ellipsis;
}

._Reverse_1ey7s_12 {
  color: var(--p-color-text-secondary);
}

._Reverse_1ey7s_12 ._Label_1ey7s_48 {
  color: var(--p-color-text);
}

._Label_1ey7s_48 {
  white-space: nowrap;
  font-size: var(--p-font-size-325);
  overflow-x: hidden;
  font-weight: var(--p-font-weight-semibold);
}

._ValueWrapper_1ey7s_60 {
  display: flex;
  gap: var(--p-space-200);
  align-items: center;
}

._ValueWrapper_1ey7s_60:only-child {
  width: 100%;
}

._PrimaryMetricWrapper_1ey7s_70 {
  min-width: 0;
}

._BenchmarksDataContainer_yv8vi_1 {
  border-radius: var(--p-border-radius-100);
  padding: var(--p-space-050) var(--p-space-150) var(--p-space-100)
    var(--p-space-150);
}

._BenchmarksDataContainer_yv8vi_1:hover,
._BenchmarksDataContainer_yv8vi_1:active {
  background: var(--p-color-bg-surface-hover);
  cursor: pointer;
}

._Container_h17y5_1 {
  max-width: 20rem;
}

._Formula_h17y5_5 {
  font-family: var(--p-font-family-mono);
  font-size: var(--p-font-size-275);
  font-weight: var(--p-font-weight-regular);
  color: var(--p-color-text-secondary);
  line-height: var(--p-font-line-height-400);
}

._Token_h17y5_13,
._Whitespace_h17y5_14 {
  margin: 0;
}

._Metric_h17y5_19 {
  color: #0b608f;
}

._Operator_h17y5_23 {
  color: #8f5da6;
}

._Variable_h17y5_27 {
  color: #308023;
}

._Bracket_h17y5_31,
._StatementSeparator_h17y5_35 {
  color: #616a75;
}

._Title_kpuhg_1 > h2 {
  font-weight: var(--p-font-weight-medium);
}

._Title_kpuhg_1._Disabled_kpuhg_6 {
  color: var(--p-color-text-disabled);
}

._Title_kpuhg_1 h2 {
  line-height: 1.5;
}

._Button_kpuhg_15 {
  max-width: 100%;
  cursor: pointer;
  border: none;
  display: block;
}

._Button_kpuhg_15:hover,
._Button_kpuhg_15:active {
  outline: none;
  border: none;
  border-width: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

._ScrollableContainerWrapper_1xw2k_1 {
  height: 100%;
  margin-top: calc(var(--p-space-200) * -1);
}

._ScrollableContainer_1xw2k_1 {
  height: 100%;
  padding-right: var(--p-space-100);
}

._ListContainer_1xw2k_11 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: var(--p-space-100);
  padding: 0;
  margin: 0;
}

._ListItem_1xw2k_21 {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  padding: var(--p-space-200) var(--p-space-300);
  border-radius: var(--p-border-radius-200);
  background-color: var(--p-color-bg-surface-hover);
}

._ListItemValue_1xw2k_31 {
  display: flex;
}

._TrendIndicator_1xw2k_35 {
  display: flex;
  margin-left: var(--p-space-100);
  min-width: 2.5rem;
  align-items: center;
  justify-content: center;
}

._Metric_sdaxj_3 {
  font-size: var(--p-font-size-750);
  font-weight: var(--p-font-weight-bold);
  line-height: 1;
}

._Container_sdaxj_9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: var(--p-space-100);
}

._GradientBackground_sdaxj_18 {
  background: radial-gradient(
    136.23% 86.43% at 50% 107.92%,
    #05496814,
    #128ac014 25.32%,
    #c8ecfc14 52.6%,
    #ffffff14
  );
}

._GradientBackground_sdaxj_18._positive_sdaxj_27 {
  background: radial-gradient(
    136.23% 86.43% at 50% 107.92%,
    #083d2514,
    #29845a14 25.32%,
    #cdfee114 52.6%,
    #ffffff14
  );
}

._GradientBackground_sdaxj_18._negative_sdaxj_37 {
  background: radial-gradient(
    136.23% 86.43% at 50% 107.92%,
    #5f150714,
    #e51c0014 25.32%,
    #ffedec14 52.6%,
    #ffffff14
  );
}

._GradientBackground_sdaxj_18._neutral_sdaxj_47 {
  background: radial-gradient(
    136.23% 86.43% at 50% 107.92%,
    #30303014,
    #8a8a8a14 25.32%,
    #fafafa14 52.6%,
    #ffffff14
  );
}

._DonutChartContainer_z1fuz_1 {
  height: 100%;
  width: 100%;
  max-width: 31.25rem;
  align-self: center;
}

._Bar_1x4jw_1 {
  height: var(--p-border-width-050);
  border-radius: var(--p-border-radius-full);
}

._Value_1x4jw_6 {
  font-size: var(--p-font-size-350);
  line-height: var(--p-font-line-height-300);
  font-weight: var(--p-font-weight-semibold);
}

._Button_1yt39_1 {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

._Button_1yt39_1:before,
._Button_1yt39_1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

._Button_1yt39_1:before {
  z-index: var(--p-z-index-2);
  border: var(--p-border-width-050) solid var(--p-color-border-emphasis-active);
  opacity: 0;
  pointer-events: none;
}

._Button_1yt39_1:after {
  z-index: var(--p-z-index-5);
}

._Button_1yt39_1._Active_1yt39_36:before,
._Button_1yt39_1:hover:before {
  opacity: 1;
}

._Cell_hubfo_1 {
  margin: 0;
  padding: 0;
}

._Table_1kaz6_1 {
  border-collapse: separate;
  border-spacing: var(--p-space-025);
  width: 100%;
  table-layout: fixed;
}

._CellContent_1kaz6_8 {
  display: flex;
  align-items: center;
  justify-content: center;
}

._Cell_p8zeo_1 {
  position: relative;
  margin: 0;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--p-space-200);
  height: 100%;
}

@media print {
  ._Cell_p8zeo_1 {
    min-width: 0 !important;
  }
}

._HasPadding_p8zeo_17 {
  padding: var(--p-space-200) var(--p-space-300);
}

._AlignEnd_p8zeo_21 {
  justify-content: end;
}

@media print {
  ._AlignEnd_p8zeo_21 {
    max-width: 100%;
  }
}

._AlignTop_p8zeo_29 {
  align-items: start;
}

._Sticky_p8zeo_33 {
  position: sticky;
  left: 0;
  z-index: var(--p-z-index-1);
  background: inherit;
  box-shadow: var(--p-space-025) 0 0 0 var(--p-color-border-secondary);
}

@media print {
  ._Sticky_p8zeo_33 {
    position: relative;
  }
}

._Container_1mmbc_1 {
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  width: 100%;
}

._AlignEnd_1mmbc_8 {
  flex-direction: row-reverse;
  justify-content: end;
}

@media print {
  ._HeaderCell_1mmbc_13 {
    min-width: 0 !important;
  }
}

._Sticky_1mmbc_20 {
  position: sticky;
  left: 0;
  z-index: var(--p-z-index-1);
  background: inherit;
  box-shadow: var(--p-space-025) 0 0 0 var(--p-color-border-secondary);
}

@media print {
  ._Sticky_1mmbc_20 {
    min-width: 0 !important;
  }
}

._RowActions_gaxvw_3 {
  align-self: center;
  flex-shrink: 0;
  display: flex;
  opacity: 0;
}

._RowActionsActive_gaxvw_10,
._ProductsBasketCell_gaxvw_11:hover ._RowActions_gaxvw_3,
div[role="row"]:hover ._RowActions_gaxvw_3 {
  opacity: 1;
}

@media (max-width: 30.6225em) {
  ._RowActionsActive_gaxvw_10,
  ._ProductsBasketCell_gaxvw_11:hover ._RowActions_gaxvw_3,
  div[role="row"]:hover ._RowActions_gaxvw_3 {
    opacity: 0;
  }
}

._Visible_gaxvw_20 {
  opacity: 1;
}

:root {
  --metric-group-right-shadow: var(--p-space-025) 0 0 0
    var(--p-color-border-secondary);
  --metric-group-bottom-shadow: 0 var(--p-space-025) 0 0
    var(--p-color-border-secondary);
}

._Row_cewwg_8 {
  position: relative;
}

._Row_cewwg_8:after,
._Row_cewwg_8:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--p-z-index-1);
  pointer-events: none;
}

._Row_cewwg_8:before {
  box-shadow: var(--metric-group-bottom-shadow);
}

._Row_cewwg_8:after {
  box-shadow: var(--metric-group-right-shadow);
}

._Row_cewwg_8:first-child ._Sticky_cewwg_28 {
  box-shadow: var(--metric-group-right-shadow);
}

._Row_cewwg_8 ._Sticky_cewwg_28:hover,
._Row_cewwg_8 ._Sticky_cewwg_28:hover ~ [role="rowgroup"],
._Row_cewwg_8:hover ._FirstColumnNoSubRows_cewwg_35 {
  background: var(--p-color-bg-surface-hover);
}

._Row_cewwg_8:hover {
  background: var(--p-color-bg-surface-hover);
}

._Row_cewwg_8:hover ._Actions_cewwg_43 {
  display: flex;
}

._GroupedRow_cewwg_48,
._FirstColumnNoSubRows_cewwg_35 {
  position: relative;
}

._GroupedRow_cewwg_48 ._Row_cewwg_8:hover,
._FirstColumnNoSubRows_cewwg_35 ._Row_cewwg_8:hover {
  background: var(--p-color-bg-surface-hover);
}

._GroupedRow_cewwg_48:after,
._FirstColumnNoSubRows_cewwg_35:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--p-z-index-1);
  pointer-events: none;
  box-shadow: var(--metric-group-bottom-shadow);
}

._FirstColumnNoSubRows_cewwg_35 {
  box-shadow: var(--metric-group-bottom-shadow);
}

._FirstColumnNoSubRows_cewwg_35:hover {
  background: var(--p-color-bg-surface-hover);
}

._GroupCell_cewwg_74 {
  position: relative;
}

._GroupCell_cewwg_74:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--p-z-index-1);
  pointer-events: none;
  box-shadow: var(--metric-group-right-shadow);
}

._GroupCell_cewwg_74._Sticky_cewwg_28 {
  box-shadow: var(--metric-group-right-shadow),
    inset var(--metric-group-bottom-shadow);
}

._SummaryCell_cewwg_92 {
  position: relative;
}

._SummaryCell_cewwg_92:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--p-z-index-1);
  pointer-events: none;
  box-shadow: var(--metric-group-bottom-shadow);
}

._SummaryCell_cewwg_92._LastCellWithActions_cewwg_104 {
  box-shadow: var(--metric-group-right-shadow);
}

._Sticky_cewwg_28 {
  left: 0;
  position: sticky;
  background: var(--p-color-bg-surface);
  z-index: var(--p-z-index-2);
  box-shadow: var(--metric-group-right-shadow),
    inset var(--metric-group-bottom-shadow);
}

._ActionsContainer_cewwg_118 {
  position: absolute;
  width: 100%;
  pointer-events: none;
  display: flex;
  height: 100%;
}

._Actions_cewwg_43 {
  position: absolute;
  z-index: var(--p-z-index-1);
  right: var(--p-space-300);
  display: flex;
  pointer-events: auto;
  height: 100%;
  align-items: center;
}

._ActionsWithSubRows_cewwg_136 {
  padding-top: var(--p-space-150);
  align-items: flex-start;
}

._Container_9m9ek_1 {
  align-items: center;
  background: var(--p-color-bg-surface);
  display: flex;
  height: var(--p-space-2000);
  justify-content: center;
}

:root {
  --metric-table-container-box-shadow: var(--p-shadow-bevel-100);
}

._Container_16kpq_5 {
  position: relative;
  overflow: clip;
  margin-top: calc(var(--p-space-025) * -1);
}

._Container_16kpq_5:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: var(--metric-table-container-box-shadow);
  pointer-events: none;
  z-index: var(--p-z-index-4);
  border-radius: 0 0 var(--p-border-radius-300) var(--p-border-radius-300);
}

@media (max-width: 30.6225em) {
  ._Container_16kpq_5:before {
    border-radius: 0;
  }
}

._NoRows_16kpq_25 {
  border-radius: var(--p-border-radius-300);
  overflow: hidden;
}

._NoRows_16kpq_25:before {
  border-radius: var(--p-border-radius-300);
}

._ScrollWrap_16kpq_34 {
  overflow: auto;
}

._RightShadow_16kpq_38 {
  content: "";
  position: absolute;
  z-index: var(--p-z-index-4);
  top: 0;
  right: 0;
  height: 100%;
  width: var(--p-space-150);
  background: linear-gradient(to left, rgba(0, 0, 0, 0.15), transparent);
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--p-motion-duration-300) var(--p-motion-ease);
}

._RightShadowVisible_16kpq_53 {
  opacity: 1;
}

._TableWrapper_16kpq_57 {
  position: relative;
  background: var(--p-color-bg-surface);
}

._Table_16kpq_57 {
  background: var(--p-color-bg-surface);
}

._Header_16kpq_66 {
  border-bottom: var(--p-space-025) solid var(--p-color-border-secondary);
  background: var(--p-color-bg-surface);
}

._SummaryRow_16kpq_71 {
  border-bottom: var(--p-border-width-025) solid var(--p-color-border-secondary);
  background: var(--p-color-bg-surface-tertiary);
}

._StickyHeader_16kpq_76 {
  position: sticky;
  top: 0;
  z-index: var(--p-z-index-3);
  overflow: hidden;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

._StickyHeader_16kpq_76::-webkit-scrollbar {
  display: none;
}

@media print {
  ._StickyHeader_16kpq_76 {
    position: relative;
    top: 0;
  }
}

._Container_gxsuq_1 {
  --metric-table-container-box-shadow: initial;
  position: relative;
  z-index: 1;
  margin: calc(var(--p-space-400) * -1);
  margin-top: 0;
  border-top: 0.0625rem solid var(--p-color-border-secondary);
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

._Container_gxsuq_1::-webkit-scrollbar {
  display: none;
}

._VisualizationWrapper_xmyst_1 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

._VisualizationWrapper_xmyst_1:first-child {
  margin-top: var(--p-space-200);
}

._CompactVisualizationWrapper_xmyst_12 {
  max-height: 1.25rem;
  width: 100%;
}

._CompactVisualizationWrapper_xmyst_12._HasPrimaryMetric_xmyst_16 {
  max-width: 5rem;
  min-width: 0;
  align-items: end;
  margin-top: 0;
}

._CompactVisualizationWrapper_xmyst_12._Hidden_xmyst_23 {
  visibility: hidden;
}
