._CloseButton_1n0yn_1 {
  cursor: pointer;
  pointer-events: all;
}

._FormulaVariable_zq6ol_10 {
  color: #308023;
  margin-right: var(--p-space-100);
}

._FormulaResult_zq6ol_16 {
  color: #0b608f;
  margin-right: var(--p-space-100);
}

._FormulaOperatorOrNumber_zq6ol_22 {
  color: #8f5da6;
  margin-right: var(--p-space-100);
}

._FormulaBracketOrEquals_zq6ol_28 {
  color: #616a75;
  margin-right: var(--p-space-100);
}

._Formula_zq6ol_10 {
  font-family: var(--p-font-family-mono);
  font-size: var(--p-font-size-300);
  font-weight: var(--p-font-weight-regular);
  display: flex;
  flex-wrap: wrap;
}

._MetricLayout_1xiwl_1 {
  --header-height: 1.25rem;
  --flex-gap: var(--p-space-400);
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

._CardWrapper_1xiwl_11 {
  height: 100%;
}

._CardWrapper_1xiwl_11 > [class*="Polaris-ShadowBevel"] {
  height: 100%;
}

._CardWrapper_1xiwl_11
  > [class*="Polaris-ShadowBevel"]
  > [class="Polaris-Box"] {
  background: var(--p-color-bg-surface);
  height: 100%;
}

._HeaderButton_1xiwl_25 {
  display: flex;
  padding: var(--p-space-400) var(--p-space-400) var(--p-space-200);
}

._HeaderButton_1xiwl_25:hover {
  background: var(--p-color-bg-surface-hover);
}

._Header_1xiwl_25 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: var(--header-height);
  width: 100%;
}

._Content_1xiwl_42 {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: var(--flex-gap);
  flex-grow: 1;
  min-height: 0;
}

._Content_1xiwl_42._Compact_1xiwl_50 {
  align-items: center;
}

._CohortsCard_1xiwl_55 {
  justify-content: space-between;
}

._Title_1xiwl_59 {
  flex-grow: 1;
  min-width: 0;
}

._Title_1xiwl_59._HasPrimaryAction_1xiwl_64 {
  margin-right: var(--p-space-600);
}

._PrimaryAction_1xiwl_68 {
  position: absolute;
  top: 0;
  right: 0;
}

._Compact_1xiwl_50 ._Content_1xiwl_42 {
  flex-direction: row;
  gap: var(--flex-gap);
  justify-content: space-between;
}

._Button_1xiwl_82 {
  background: unset;
  border: unset;
  text-align: unset;
  color: unset;
  text-decoration: unset;
  display: block;
  height: 100%;
}

._CompactButtonHover_1xiwl_92 :hover > [class="Polaris-Box"] {
  background: var(--p-color-bg-surface-hover);
}
