/* :root {
  --image-dimensions: 15rem;
  --narrow-image-dimensions: 11.25rem;
  --padding: 1.25rem;
  --column-gap: 1.25rem;
  --column-width: var(--image-dimensions);
  --two-column-width: calc(var(--column-width) * 2);
  --width-when-column-wraps: calc(
    var(--two-column-width) + var(--column-gap) + var(--padding) * 2 - 0.0625rem
  );
}

._PageIllustration_nfny7_16 {
  max-width: 45.5rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--column-width), auto));
  gap: var(--p-space-500) var(--column-gap);
  justify-content: center;
  align-items: center;
  padding: var(--padding);
} */

@media (min-height: 31.25em) {
  ._PageIllustration_nfny7_16 {
    height: 80vh;
  }
}

._WideGap_nfny7_33 {
  max-width: 48rem;
  gap: var(--p-space-1000);
}

._TextContainer_nfny7_38 > :first-child {
  margin-bottom: var(--p-space-600);
}

._TextContainer_nfny7_38 a {
  color: var(--p-color-text-emphasis);
}

@media (min-width: 48em) and (width < 795px) {
  ._ImageContainer_nfny7_50 {
    align-self: end;
  }

  ._ImageContainer_nfny7_50 img {
    width: var(--narrow-image-dimensions);
    height: var(--narrow-image-dimensions);
  }

  ._TextContainer_nfny7_38 {
    align-self: start;
  }
}

@media (width < 555px) {
  ._ImageContainer_nfny7_50 {
    align-self: end;
  }

  ._ImageContainer_nfny7_50 img {
    width: var(--narrow-image-dimensions);
    height: var(--narrow-image-dimensions);
  }

  ._TextContainer_nfny7_38 {
    align-self: start;
  }
}

._ErrorLink_a2cw8_1 {
  display: inline;
  padding: 0;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  font-size: inherit;
  color: var(--p-color-text-emphasis);
  text-decoration: none;
  cursor: pointer;
}

._ErrorLink_a2cw8_1:hover,
._ErrorLink_a2cw8_1:focus,
._ErrorLink_a2cw8_1:active {
  outline: none;
  color: var(--p-color-text-emphasis-hover);
}

._SkeletonBrandingWrapper_12065_4 {
  display: flex;
  align-items: center;
  opacity: 0.5;
  filter: grayscale(100%);
}

._SkeletonShopifyLogoWrapper_12065_11 {
  display: flex;
  align-items: center;
  padding: 0 var(--p-space-200) 0 var(--p-space-500);
  height: 3.5rem;
}

._SkeletonShopifyLogo_12065_11 {
  flex-shrink: 0;
}

._BorderGradient_14m3l_1 {
  --p-color-button-gradient-bg-fill-active-experimental: linear-gradient(
    180deg,
    rgba(10, 10, 10, 1) 0%,
    rgba(18, 18, 18, 1) 50%,
    rgba(41, 41, 41, 1) 100%
  );
  --p-color-border-gradient-active-experimental: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.14) 20%,
    rgba(255, 255, 255, 0.3) 100%
  );
  position: relative;
  max-height: var(--border-gradient-max-height);
  border-radius: var(--border-gradient-border-radius);
  background: var(--border-gradient-bg);
}

._BorderGradient_14m3l_1:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--border-gradient-border-radius);
  border: solid var(--p-border-width-025) transparent;
  background: var(--border-gradient-border) border-box;
  -webkit-mask: linear-gradient(rgba(255, 255, 255, 1) 0 0) padding-box,
    linear-gradient(rgba(255, 255, 255, 1) 0 0);
  mask: linear-gradient(rgba(255, 255, 255, 1) 0 0) padding-box,
    linear-gradient(rgba(255, 255, 255, 1) 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

._Hover_14m3l_36:not(._Active_14m3l_36):not(:active):hover {
  background: var(--border-gradient-bg-hover);
}

._Hover_14m3l_36:not(._Active_14m3l_36):not(:active):hover:before {
  background: var(--border-gradient-border-hover) border-box;
}

._Selected_14m3l_46:active {
  background: var(--border-gradient-bg-selected);
  padding-top: var(--p-space-025);
}

._Selected_14m3l_46:active:before {
  background: var(--border-gradient-border-selected) border-box;
  border-top-width: 0.1875rem;
}

._Active_14m3l_36 {
  background: var(--border-gradient-bg-active);
}

._Active_14m3l_36:before {
  background: var(--border-gradient-border-active) border-box;
}

._TopBarButton_7qazp_2._TopBarButton_7qazp_2._TopBarButton_7qazp_2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--p-height-900);
  min-width: var(--p-height-900);
  cursor: pointer;
  background: none;
  border-radius: var(--p-border-radius-300);
  border: none;
}

._TopBarButton_7qazp_2._TopBarButton_7qazp_2._TopBarButton_7qazp_2:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-050);
  border-radius: var(--p-border-radius-300);
}

._TopBarButton_7qazp_2._TopBarButton_7qazp_2._TopBarButton_7qazp_2:focus-visible
  svg {
  fill: var(--p-color-text-secondary);
}

._TopBarButton_7qazp_2._TopBarButton_7qazp_2._TopBarButton_7qazp_2:active
  > ._Pressed_7qazp_25 {
  color: var(--p-color-text-secondary);
}

._TopBarButton_7qazp_2._TopBarButton_7qazp_2._TopBarButton_7qazp_2:active
  > ._Pressed_7qazp_25
  svg {
  fill: var(--p-color-text-secondary);
}

._Pressed_7qazp_25 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

._ExpandedActive_7qazp_43 {
  box-shadow: 0 0.03125rem 0.03125rem #ffffff1f, 0 0.0625rem 0.125rem #ffffff14,
    0 0.125rem 0.25rem #ffffff1f;
  border-radius: var(--p-border-radius-300);
}

._ExpandedActive_7qazp_43:active {
  box-shadow: none;
}

._Square_7qazp_55 > * {
  max-width: var(--p-height-900);
}

._ContextualButton_1reb8_1 {
  position: relative;
  height: var(--p-height-700);
  border-radius: var(--p-border-radius-200);
  border: none;
  font-size: var(--p-font-size-300);
  font-weight: var(--p-font-weight-medium);
  padding: var(--p-space-150) var(--p-space-300);
  cursor: pointer;
  color: var(--p-color-text);
  text-decoration-line: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

._ContextualButton_1reb8_1:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-025);
}

._Secondary_1reb8_22 {
  background-color: #ffffff14;
}

._Secondary_1reb8_22:hover:not(._Disabled_1reb8_26) {
  background-color: var(--p-color-bg-fill-secondary-hover);
}

._Secondary_1reb8_22:active:not(._Disabled_1reb8_26) {
  background-color: #ffffff2b;
}

._Primary_1reb8_36 {
  border: solid var(--p-border-width-025) transparent;
  color: #303030;
  background-color: var(--p-color-bg-fill-brand);
  background-image: linear-gradient(
      var(--p-color-bg-fill-brand),
      var(--p-color-bg-fill-brand)
    ),
    linear-gradient(to bottom, #0000001a, #0003 78%, #0006);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

._Primary_1reb8_36:hover:not(._Disabled_1reb8_26):not(._Loading_1reb8_57) {
  background-color: var(--p-color-bg-fill-brand-hover);
  background-image: linear-gradient(
      var(--p-color-bg-fill-brand-hover),
      var(--p-color-bg-fill-brand-hover)
    ),
    linear-gradient(to bottom, #0000001a, #0003 78%, #0006);
}

._Primary_1reb8_36:active:not(._Disabled_1reb8_26):not(._Loading_1reb8_57) {
  background-color: var(--p-color-bg-fill-brand-selected);
  background-image: linear-gradient(
      var(--p-color-bg-fill-brand-selected),
      var(--p-color-bg-fill-brand-selected)
    ),
    linear-gradient(to bottom, #00000080, #d4d4d480);
  padding-top: calc(var(--p-space-150) + var(--p-border-width-025));
}

._Primary_1reb8_36 ._Spinner_1reb8_88 svg {
  fill: #4a4a4a;
}

._Disabled_1reb8_26 {
  cursor: auto;
  border: none;
}

._Disabled_1reb8_26._Secondary_1reb8_22 {
  color: #ffffff4d;
}

._Disabled_1reb8_26._Primary_1reb8_36 {
  color: #8a8a8a;
  background-color: #ccc;
  background-image: none;
}

._Disabled_1reb8_26._Loading_1reb8_57 {
  color: transparent;
  border: solid var(--p-border-width-025) rgba(204, 204, 204, 1);
}

._Spinner_1reb8_88 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: transparent;
}

._Spinner_1reb8_88 svg {
  vertical-align: middle;
}

._FullWidth_1reb8_133 {
  width: 100%;
  padding: 0;
}

._Contents_1nw3y_1 {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
  min-width: 0.0625rem;
  max-width: calc(
    var(--pg-layout-width-primary-max) + var(--pg-layout-width-secondary-max) +
      var(--pg-layout-width-inner-spacing-base)
  );
  height: 100%;
  margin: 0 auto;
  padding: var(--p-space-100);
}

@media (min-width: 30.625em) {
  ._Contents_1nw3y_1 {
    padding: var(--p-space-100) var(--p-space-100) var(--p-space-100)
      var(--p-space-200);
  }
}

._MessageContainer_1nw3y_23 {
  justify-self: center;
  display: flex;
  flex-direction: row;
  gap: var(--p-space-200);
  overflow: hidden;
  margin-right: var(--p-space-200);
  align-items: center;
}

._MessageContainer_1nw3y_23 [class*="Polaris-Icon__Svg"] {
  fill: var(--p-color-text-inverse) !important;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action svg {
  fill: var(--p-color-icon-secondary) !important;
}

._MessageContainer_1nw3y_23 [class*="Polaris-Icon"] {
  flex-shrink: 0;
}

._ButtonContainer_1nw3y_42 {
  display: flex;
  gap: var(--p-space-100);
  align-items: center;
  flex-wrap: no-wrap;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 30.625em) {
  ._ButtonContainer_1nw3y_42 {
    justify-content: unset;
    width: unset;
  }
}

._ContextualSaveBar_1nw3y_56 {
  position: relative;
  width: 100%;
  border-radius: var(--p-border-radius-300);
  background: var(--p-color-bg-fill-success-active);
}

._ContextualSaveBar_1nw3y_56 > * {
  transition: background 0.8s var(--p-motion-ease);
}

._Shake_1nw3y_68 {
  animation: _shake_1nw3y_1 0.65s var(--p-motion-ease-in-out);
}

._Shake_1nw3y_68._ContextualSaveBar_1nw3y_56 > * {
  background: var(--p-color-bg-fill-success-active);
  transition: background var(--p-motion-duration-300) var(--p-motion-ease);
}

@keyframes _shake_1nw3y_1 {
  0% {
    transform: translate(0);
  }

  9% {
    transform: translate(var(--polaris-top-bar-shake-offset));
  }

  20% {
    transform: translate(-0.75rem);
  }

  32% {
    transform: translate(0.5rem);
  }

  45% {
    transform: translate(-0.375rem);
  }

  59% {
    transform: translate(0.125rem);
  }

  82% {
    transform: translate(0);
  }

  to {
    transform: translate(0);
  }
}

._TopBar_cih8r_5 {
  --polaris-top-bar-element-height: 2.25rem;
  position: relative;
  height: var(--pg-top-bar-height);
  box-shadow: var(--p-shadow-100);
  background-color: var(--p-color-bg-inverse);
  gap: var(--p-space-100);
}

@media (min-width: 48em) {
  ._TopBar_cih8r_5 {
    gap: var(--p-space-600);
    grid-template-columns: minmax(15rem, 1fr) minmax(auto, 30rem) 1fr;
  }
}

._TopBar_cih8r_5:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: var(--p-border-width-025) solid transparent;
}

._Container_cih8r_31 {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 30rem) 1fr;
  align-items: center;
  width: calc(100vw - var(--pc-app-provider-scrollbar-width));
}

._Container-TopBarAndReframe_cih8r_43 {
  --polaris-top-bar-shake-offset: 0.875rem;
  --polaris-top-bar-dynamic-area-max-width: 40rem;
  width: 100%;
  grid-template-columns:
    1fr minmax(
      0,
      calc(
        var(--polaris-top-bar-dynamic-area-max-width) +
          var(--polaris-top-bar-shake-offset) * 2
      )
    )
    1fr;
}

._LogoDisplayControl_cih8r_58 {
  display: none;
}

@media (min-width: 48em) {
  ._LogoDisplayControl_cih8r_58 {
    display: flex;
  }
}

._LogoDisplayContainer_cih8r_66 {
  display: flex;
}

._LogoContainer_cih8r_70 {
  flex: 0 0 var(--pg-layout-width-nav-base);
  align-items: center;
  height: 100%;
  padding: 0 var(--p-space-200) 0 var(--p-space-400);
  flex-basis: var(--pg-layout-width-nav-base);
  flex-basis: calc(var(--pg-layout-width-nav-base) + env(safe-area-inset-left));
  padding-left: var(--p-space-400);
  padding-left: calc(var(--p-space-400) + env(safe-area-inset-left));
}

._LogoContainer_cih8r_70._hasLogoSuffix_cih8r_85 {
  gap: var(--p-space-200);
}

._Logo_cih8r_58,
._LogoLink_cih8r_91 {
  display: block;
}

._Logo_cih8r_58:focus-visible,
._LogoLink_cih8r_91:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-150);
  border-radius: var(--p-border-radius-200);
}

._ContextControl_cih8r_101 {
  display: none;
}

@media (min-width: 48em) {
  ._ContextControl_cih8r_101 {
    width: var(--pg-layout-width-nav-base);
    display: block;
  }
}

@media (min-width: 90em) {
  ._ContextControl_cih8r_101 {
    width: var(--pg-layout-width-nav-base);
  }
}

._NavigationIcon_cih8r_116 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._NavigationIcon_cih8r_116:focus {
  outline: none;
}

._NavigationIcon_cih8r_116 {
  position: relative;
  align-self: center;
  margin-left: calc(var(--p-space-200) + var(--p-space-050));
  margin-right: var(--p-space-200);
  padding: var(--p-space-200);
  border-radius: var(--p-border-radius-100);
  color: var(--p-color-icon-inverse);
  transition: var(--p-motion-duration-150) color var(--p-motion-ease)
    var(--p-motion-duration-50);
}

._NavigationIcon_cih8r_116._focused_cih8r_128:active,
._NavigationIcon_cih8r_116:hover {
  background-color: var(--p-color-bg-fill-inverse-hover);
}

._NavigationIcon_cih8r_116:after {
  content: "";
  position: absolute;
  top: calc(var(--p-space-200) * -1);
  left: calc(var(--p-space-200) * -1);
  width: calc(100% + var(--p-space-500));
  height: calc(100% + var(--p-space-500));
}

@media (min-width: 48em) {
  ._NavigationIcon_cih8r_116 {
    display: none;
  }
}

._NavigationIcon_cih8r_116:focus-visible:not(:active) {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: 0;
  border-radius: var(--p-border-radius-200);
}

._NavigationIcon-TopBarAndReframe_cih8r_157 {
  margin-left: calc(var(--p-space-200) + var(--p-space-050));
  margin-right: calc(var(--p-space-150) * -1);
}

@media (min-width: 48em) {
  ._NavigationIcon-TopBarAndReframe_cih8r_157 {
    display: none;
    margin-right: var(--p-space-200);
  }
}

._NavigationIcon-TopBarAndReframe_cih8r_157 span,
._NavigationIcon-TopBarAndReframe_cih8r_157 svg {
  color: var(--p-color-icon-inverse);
}

._NavigationButton-TopBarAndReframe_cih8r_172 {
  padding: 0;
}

._LeftContent_cih8r_176 > *,
._DynamicArea_cih8r_177 > *,
._RightContent_cih8r_178 > * {
  z-index: 1;
}

._DynamicArea_cih8r_177 {
  z-index: 1;
}

._LeftContent_cih8r_176 {
  display: flex;
}

._Search_cih8r_194 {
  z-index: var(--p-z-index-1);
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (min-width: 48em) {
  ._Search_cih8r_194 {
    position: relative;
  }
}

._SearchBar-enter_cih8r_207 {
  transform: translateY(calc(-100% - var(--polaris-top-bar-element-height)))
    scale(95%);
}

._SearchBar-enter-active_cih8r_212 {
  transition: transform var(--p-motion-duration-200) var(--p-motion-ease-in-out);
  transform: translateY(0) scale(1);
}

._SearchBar-exit_cih8r_217 {
  transform: translateY(calc(-100% - var(--p-space-050))) scale(1);
}

._SearchBar-exit-active_cih8r_221 {
  transform: translateY(
      calc(-200% - var(--p-space-050) - var(--polaris-top-bar-element-height))
    )
    scale(95%);
  transition: transform var(--p-motion-duration-200) var(--p-motion-ease-in-out);
}

._ContextualSaveBar-enter_cih8r_229 {
  transform: translateY(calc(100% + var(--polaris-top-bar-element-height)))
    scale(95%);
  opacity: 0;
}

._ContextualSaveBar-enter-active_cih8r_235 {
  transition: transform var(--p-motion-duration-200) var(--p-motion-ease-in-out);
  transform: translateY(0) scale(1);
  opacity: 1;
}

._ContextualSaveBar-exit_cih8r_241 {
  transform: translateY(calc(-100% + var(--p-space-050))) scale(1);
}

._ContextualSaveBar-exit-active_cih8r_245 {
  transform: translateY(100%) scale(95%);
  transition: transform var(--p-motion-duration-200) var(--p-motion-ease-in-out);
}

._RightContent_cih8r_178 {
  display: flex;
  justify-content: flex-end;
}

._SecondaryMenu_cih8r_255 {
  margin-left: var(--p-space-200);
}

._SecondaryMenu-TopBarAndReframe_cih8r_259 {
  margin-left: 0;
}

@media (min-width: 48em) {
  ._SecondaryMenu-TopBarAndReframe_cih8r_259 {
    margin-left: var(--p-space-200);
  }
}

._SecondaryMenu_cih8r_255 svg {
  fill: var(--p-color-icon-inverse);
}

._TransitionGroup_cih8r_271 {
  height: var(--pg-top-bar-height);
  overflow: hidden;
  padding: calc(
      (var(--pg-top-bar-height) - var(--polaris-top-bar-element-height)) / 2
    )
    var(--polaris-top-bar-shake-offset);
}

._SearchField_4rw06_1 {
  height: 2rem;
  width: 100%;
  margin-left: var(--p-space-400);
  background-color: var(--p-color-bg-fill-inverse);
  border-radius: var(--p-border-radius-200);
}

@media (min-width: 30.625em) {
  ._SearchField_4rw06_1 {
    margin-left: 0;
  }
}

._SearchField-TopBarAndReframe_4rw06_13 {
  height: 2.375rem;
  width: 100%;
  margin-left: var(--p-space-400);
  background-color: var(--p-color-bg-fill-inverse);
  border-radius: var(--p-border-radius-300);
}

@media (min-width: 30.625em) {
  ._SearchField-TopBarAndReframe_4rw06_13 {
    margin-left: 0;
  }
}

._UserMenu_4rw06_25 {
  min-width: 6.25rem;
  display: flex;
  align-items: center;
  margin-right: var(--p-space-400);
  height: 3.5rem;
}

@media (max-width: 30.6225em) {
  ._UserMenu_4rw06_25 {
    min-width: auto;
  }
}

._Avatar_4rw06_37 {
  width: var(--p-space-800);
  height: var(--p-space-800);
  flex-shrink: 0;
  margin-left: var(--p-space-200);
  background-color: var(--p-color-bg-fill-inverse);
  border-radius: var(--p-border-radius-200);
}

._Avatar-TopBarAndReframe_4rw06_46 {
  width: var(--p-space-800);
  height: var(--p-space-800);
  flex-shrink: 0;
  margin-left: var(--p-space-200);
  background-color: var(--p-color-bg-fill-inverse);
  border-radius: var(--p-border-radius-300);
}

._User_4rw06_25 {
  width: 100%;
  height: 1.25rem;
  background-color: var(--p-color-bg-fill-inverse);
  border-radius: var(--p-border-radius-200);
}

@media (max-width: 30.6225em) {
  ._User_4rw06_25 {
    display: none;
  }
}

._User-TopBarAndReframe_4rw06_66 {
  width: 100%;
  height: 1.25rem;
  background-color: var(--p-color-bg-fill-inverse);
  border-radius: var(--p-border-radius-300);
}

@media (max-width: 30.6225em) {
  ._User-TopBarAndReframe_4rw06_66 {
    display: none;
  }
}

._NavItemContainer_1j851_1 {
  display: flex;
  flex-direction: row;
  padding-top: var(--p-space-150);
  padding-bottom: var(--p-space-150);
  align-items: flex-end;
  margin-left: 1.375rem;
}

._NavigationSkeleton_1j851_11,
._NavigationSkeletonItemSm_1j851_12,
._NavigationSkeletonItemMd_1j851_13,
._NavigationSkeletonItemLg_1j851_14 {
  border-radius: var(--p-border-radius-100);
  background-color: var(--p-color-bg-fill-tertiary);
  display: flex;
  height: 1rem;
}

._NavigationSkeleton_1j851_11 {
  width: 1rem;
  margin-right: var(--p-space-300);
}

._NavigationSkeletonItemSm_1j851_12 {
  width: 3.625rem;
}

._NavigationSkeletonItemMd_1j851_13 {
  width: 4.4375rem;
}

._NavigationSkeletonItemLg_1j851_14 {
  width: 5.75rem;
}

[mobile-bridge="true"]
  [class^="Polaris-Modal-Dialog__Modal"]
  > [class^="Polaris-Box"]:first-of-type
  > [class^="Polaris-Button"]:first-of-type {
  display: none;
}

[mobile-bridge="true"] [class*="Polaris-Modal-Section--titleHidden"] {
  padding-right: var(--p-space-400);
}

._MobileBridgeModalFooter_1ocl6_19 {
  padding-bottom: env(safe-area-inset-bottom);
}

._Content_2tvly_4 a {
  position: relative;
}

._Content_2tvly_4 a:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._Content_2tvly_4 a,
._Content_2tvly_4 a:hover {
  color: inherit;
  text-decoration: underline;
}

._Content_2tvly_4 a:focus {
  color: inherit;
  outline: none;
}

._Content_2tvly_4 a:active {
  color: inherit;
}

._Content_2tvly_4 a:focus-visible:not(:active):after {
  box-shadow: 0 0 0 0.125rem var(--p-color-border-focus);
  outline: var(--p-border-width-025) solid transparent;
}

._Content_2tvly_4 ul {
  margin-top: var(--p-space-0);
  padding-left: var(--p-space-500);
}

._AppLink_x6xk3_6 {
  display: flex;
  margin-right: var(--p-space-100);
}

._AppIcon--skeleton_x6xk3_11 {
  background: var(--p-color-bg-fill-tertiary);
  border-radius: var(--p-border-radius-100);
}

._AppIcon_x6xk3_11 {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
}

._AppIcon_x6xk3_11 img {
  width: 100%;
  height: 100%;
  border-radius: var(--p-border-radius-100);
}

._Title_1qoje_22 {
  padding: 0 var(--p-space-300);
  margin: var(--p-space-200) 0 0.4375rem 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: none;
  flex: 1 1;
  min-height: 1.75rem;
}

._Title_1qoje_22 > h2 {
  display: flex;
  width: 100%;
}

._Title_1qoje_22 ._AppName_1qoje_38 {
  font-size: var(--p-font-size-350);
  line-height: var(--p-font-line-height-500);
}

._Title_1qoje_22 [class*="AppIcon_AppIcon"] {
  width: 1.25rem;
  height: 1.25rem;
}

._Title_1qoje_22._TitleSkeleton_1qoje_48 {
  margin: var(--p-space-300) 0 0.6875rem 0;
}

._Identifier_1qoje_54 {
  flex: 1 1 100%;
}

._Title__Item_1qoje_58 {
  flex: 0 0 auto;
  margin-top: 0;
  margin-left: 0;
}

._Title__Item--grow_1qoje_64 {
  flex-grow: 1;
}

._AppName_1qoje_38 {
  overflow: hidden;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: var(--p-font-weight-regular);
  max-width: 13.75rem;
  padding-left: var(--p-space-100);
}

@media (min-width: 30.625em) {
  ._AppName_1qoje_38 {
    max-width: 25rem;
  }
}

@media (min-width: 48em) {
  ._AppName_1qoje_38 {
    max-width: 20.625rem;
  }
}

@media (min-width: 65em) {
  ._AppName_1qoje_38 {
    max-width: 35.9375rem;
  }
}

@media (min-width: 90em) {
  ._AppName_1qoje_38 {
    max-width: 62.5rem;
  }
}

._AppName_1qoje_38 > a {
  color: inherit;
  text-decoration: none;
}

._AppName_1qoje_38 > a:visited {
  color: inherit;
}

._AppName_1qoje_38 > a {
  text-decoration: none !important;
}

._AppName_1qoje_38 > a:hover {
  text-decoration: underline !important;
}

._CloseButton_1qoje_107 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._CloseButton_1qoje_107:focus {
  outline: none;
}

._CloseButton_1qoje_107 {
  margin-right: calc(var(--p-space-200) * -1);
  padding: var(--p-space-200);
}

._CloseButton_1qoje_107:focus,
._CloseButton_1qoje_107:active {
  border-radius: var(--p-border-radius-200);
  background: #212b361a;
}

._TitleBar_ukuv0_1 {
  border-bottom: var(--p-border-width-025) solid var(--p-color-border-secondary);
  background-color: var(--p-color-bg);
}

._TitleBar_ukuv0_1 ._TitleActionContainer_ukuv0_5 {
  line-height: 0.625rem;
}

@keyframes _pulse_1uiq2_1 {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

._EmbeddedAppLoadingIndicator_1uiq2_12 {
  min-width: 100%;
  min-height: 100%;
  background: linear-gradient(180deg, #edeeef, #f6f6f7);
  opacity: 0;
  animation: _pulse_1uiq2_1 0.75s var(--p-motion-ease-in-out) infinite alternate;
  animation-delay: 1.2;
}

._Container_xn4ki_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--p-space-1000) var(--p-space-500);
  height: 100%;
}

._Image_xn4ki_9 {
  -webkit-user-select: none;
  user-select: none;
}

._Details_1g1mh_4 {
  max-width: 10rem;
  margin-right: 0;
  padding: 0 var(--p-space-200) 0 0.625rem;
}

._Details_1g1mh_4 > p {
  font-size: var(--p-font-size-300);
  line-height: var(--p-font-line-height-400);
}

@media (max-width: 47.9975em) {
  ._Details_1g1mh_4 {
    display: none;
  }
}

._Details-TopBarAndReframe_1g1mh_20 {
  max-width: 10rem;
}

._Details-TopBarAndReframe_1g1mh_20 > p {
  font-size: var(--p-font-size-300);
  line-height: var(--p-font-line-height-400);
}

@media (max-width: 47.9975em) {
  ._Details-TopBarAndReframe_1g1mh_20 {
    display: none;
  }
}

._ActivatorWrapper_1g1mh_33 {
  height: var(--pg-top-bar-height);
  display: flex;
  align-items: center;
  margin-right: var(--p-space-200);
}

._Activator_1g1mh_33 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._Activator_1g1mh_33:focus {
  outline: none;
}

._Activator_1g1mh_33 {
  color: var(--p-color-text-inverse);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  min-height: 2rem;
  padding: var(--p-space-050);
  border: 0;
  cursor: pointer;
  transition: background-color var(--p-motion-duration-100);
  border-radius: var(--p-border-radius-200);
  background-color: var(--p-color-bg-fill-inverse);
}

._Activator_1g1mh_33:focus {
  background-color: var(--p-color-bg-fill-inverse-hover);
  outline: none;
}

._Activator_1g1mh_33:focus-visible:after {
  content: none;
}

._Activator_1g1mh_33:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-050);
}

._Activator_1g1mh_33:hover {
  background-color: var(--p-color-bg-fill-inverse-hover);
}

._Activator_1g1mh_33:active,
._Activator_1g1mh_33[aria-expanded="true"] {
  background-color: var(--p-color-bg-fill-inverse-active);
  outline: none;
  transition: none;
}

._Activator_1g1mh_33:active:after,
._Activator_1g1mh_33[aria-expanded="true"]:after {
  border: none;
}

._Activator_1g1mh_33:active p,
._Activator_1g1mh_33[aria-expanded="true"] p {
  color: var(--p-color-text-inverse);
}

@media (max-width: 47.9975em) {
  ._Activator_1g1mh_33 {
    background-color: var(--p-color-bg-inverse);
  }

  ._Activator_1g1mh_33:focus,
  ._Activator_1g1mh_33:hover,
  ._Activator_1g1mh_33:active,
  ._Activator_1g1mh_33[aria-expanded="true"] {
    opacity: 0.85;
  }
}

._Activator-TopBarAndReframe_1g1mh_99 {
  padding: 0;
}

._Activator-TopBarAndReframe_1g1mh_99:active p,
._Activator-TopBarAndReframe_1g1mh_99[aria-expanded="true"] p {
  color: unset;
}

._AppIcon_1pup3_4,
._AppIcon_1pup3_4 img {
  border-radius: var(--p-border-radius-100);
  height: 2.5rem;
  margin-bottom: var(--p-space-400);
  vertical-align: middle;
  width: 2.5rem;
}

._AppInfo_1pup3_15,
._GetSupportSuccessBanner_1pup3_19,
._GetSupportTextFieldSection_1pup3_23 {
  margin-bottom: var(--p-space-400);
}

._Wrapper_og56u_1 {
  margin-bottom: calc(var(--p-space-100) * -1);
  margin-top: calc(var(--p-space-100) * -1);
}

._IconWithRadius_1wod4_1 {
  border-radius: var(--p-border-radius-200);
  max-width: 100%;
  border: var(--p-border-width-025) solid var(--p-color-border);
}

._StickyFooter_wrvhb_1 {
  position: sticky;
  bottom: 0;
  z-index: var(--p-z-index-2);
  padding: var(--p-space-400);
  background-color: var(--p-color-bg-surface-secondary);
  border-top: var(--p-border-width-025) solid rgb(228, 229, 231);
}

._StickyFooter_wrvhb_1 button {
  margin: 0;
}

._pageActionsContainer_1vvcf_1 {
  margin-top: calc(var(--p-space-400) * -1);
}

._LeftOffset_158w4_1 {
  width: 100%;
}

@media (max-width: 30.6225em) {
  ._NavigationDismissSibling_158w4_5 + button {
    transform: translate(-4rem);
    z-index: 999;
  }

  ._NavigationDismissSibling_158w4_5 + button svg {
    fill: var(--p-color-icon-secondary);
  }
}

@media (max-width: 22.5em) {
  ._Navigation_158w4_5 {
    max-width: 18.75rem;
    width: 100%;
  }
}

._AppFrame_158w4_28 {
  scrollbar-color: auto;
}

._AppFrame_158w4_28 [id="AppFrameTopBar"],
._AppFrame_158w4_28 [class*="Polaris-Frame__ContextualSaveBar"] {
  z-index: var(--p-z-index-9);
}

._AppFrame_158w4_28 [id="AppFrameLoadingBar"] {
  z-index: var(--p-z-index-10);
}

._AppFrame-TopBarAndReframe_158w4_45 {
  background-color: var(--p-color-bg-inverse);
}

._AppFrame-TopBarAndReframe_158w4_45 [id="AppFrameBevel"] {
  transition: transform var(--p-motion-duration-250) var(--p-motion-ease-in-out),
    width var(--p-motion-duration-250) var(--p-motion-ease);
  transform-origin: 50% var(--pg-top-bar-height);
}

._AppFrame-TopBarAndReframe_158w4_45 [id="AppFrameBevel"]:before {
  transition: border-radius var(--p-motion-duration-500)
    var(--p-motion-ease-in-out);
}

._AppFrame-TopBarAndReframe_158w4_45 [id="AppFrameMain"],
._AppFrame-TopBarAndReframe_158w4_45 [class*="Polaris-Navigation"] {
  transition: border-radius var(--p-motion-duration-500)
    var(--p-motion-ease-in-out);
}

._SettingsModalOpen-TopBarAndReframe_158w4_73 [id="AppFrameBevel"] {
  transform: scale(0.99);
  transform-origin: 50% var(--pg-top-bar-height);
}

._SettingsModalOpen-TopBarAndReframe_158w4_73 [id="AppFrameBevel"]:before {
  border-radius: var(--p-border-radius-300);
}

._SettingsModalOpen-TopBarAndReframe_158w4_73 [id="AppFrameMain"] {
  border-radius: var(--p-border-radius-300);
}

._SettingsModalOpen-TopBarAndReframe_158w4_73 [class*="Polaris-Navigation"] {
  border-bottom-left-radius: var(--p-border-radius-300);
}

._FullscreenContextualSaveBar-TopBarAndReframe_12bzn_1 {
  position: fixed;
  z-index: var(--p-z-index-1);
  height: var(--pg-top-bar-height);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--p-color-bg-inverse);
  padding: 0 var(--p-space-150);
}

._Sidebar_mw8py_1 {
  --sidebar-opacity-start: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: bottom, right, width, height;
  z-index: var(--p-z-index-9);
  position: fixed;
  bottom: var(--p-space-200);
  right: var(--p-space-200);
  width: 22.25rem;
  box-shadow: var(--p-shadow-100);
  border-radius: var(--p-border-radius-300);
  height: calc(100vh - 3.5rem - var(--p-space-400));
  opacity: var(--sidebar-opacity-start);
  transform: translate(calc(100% + var(--p-space-200)));
}

._Sidebar_mw8py_1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: var(--p-z-index-11);
  border-radius: var(--p-border-radius-300);
  box-shadow: var(--p-shadow-bevel-100);
  mix-blend-mode: luminosity;
}

._Sidebar-TopBarAndReframe_mw8py_30 {
  --top-bar-height: 3.5rem;
  --sidebar-opacity-start: 1;
  z-index: var(--p-z-index-1);
  right: 0;
  bottom: 0;
  overflow: hidden;
  height: calc(100vh - var(--top-bar-height));
  border-radius: var(--p-border-radius-300) var(--p-border-radius-300) 0 0;
}

._Sidebar-TopBarAndReframe_mw8py_30:before {
  border-radius: var(--p-border-radius-300) var(--p-border-radius-300) 0 0;
}

._SidebarFullscreen-TopBarAndReframe_mw8py_47 {
  z-index: var(--p-z-index-1);
}

._SidebarTransition_mw8py_52 {
  transition: width var(--p-motion-duration-150) var(--p-motion-ease),
    height var(--p-motion-duration-150) var(--p-motion-ease),
    bottom var(--p-motion-duration-150) var(--p-motion-ease),
    right var(--p-motion-duration-150) var(--p-motion-ease);
}

._SidebarActive_mw8py_59 {
  animation: _show-chat_mw8py_1 var(--p-motion-duration-250)
    var(--p-motion-ease) forwards;
}

@media (prefers-reduced-motion) {
  ._SidebarActive_mw8py_59 {
    animation: none;
    transform: translate(0);
    opacity: 1;
  }
}

._SidebarHidden_mw8py_70 {
  animation: _hide-chat_mw8py_1 var(--p-motion-duration-250)
    var(--p-motion-ease) forwards;
}

@media (prefers-reduced-motion) {
  ._SidebarHidden_mw8py_70 {
    animation: none;
    opacity: 1;
    transform: translate(calc(100% + var(--p-space-200)));
  }
}

._SidebarInitial_mw8py_81 {
  opacity: var(--sidebar-opacity-start);
  transform: translate(calc(100% + var(--p-space-200)));
}

._SidebarMinimized_mw8py_86 {
  height: 27rem;
  width: 27rem;
  box-shadow: var(--p-shadow-400);
}

._SidebarMinimizedWithSettingsModal_mw8py_92 {
  z-index: var(--p-z-index-9);
}

._SidebarWithSettingsModal_mw8py_96 {
  right: var(--p-space-200);
  bottom: var(--p-space-200);
  height: calc(100% - var(--p-space-1600) - var(--p-space-200));
}

@media screen and (min-width: 75em) {
  ._SidebarWithSettingsModal_mw8py_96:before {
    box-shadow: none;
  }
}

._SidebarWithSettingsModal_mw8py_96._Sidebar-TopBarAndReframe_mw8py_30 {
  z-index: var(--p-z-index-9);
  right: 0;
  bottom: 0;
  height: calc(100vh - var(--top-bar-height));
}

._SidebarContainer_mw8py_116 {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: var(--p-color-bg-surface);
  border-radius: var(--p-border-radius-300);
  overflow: hidden;
}

._SidebarContainer-TopBarAndReframe_mw8py_127 {
  border-radius: var(--p-border-radius-300) var(--p-border-radius-300) 0 0;
}

@keyframes _show-chat_mw8py_1 {
  0% {
    opacity: var(--sidebar-opacity-start);
    transform: translate(calc(100% + var(--p-space-200)));
  }

  to {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes _hide-chat_mw8py_1 {
  0% {
    transform: translate(0);
    opacity: 1;
  }

  to {
    opacity: var(--sidebar-opacity-start);
    transform: translate(calc(100% + var(--p-space-200)));
  }
}

._EditionsBadge_1mgbd_1 {
  display: inline-block;
  font-style: italic;
  font-weight: var(--p-font-weight-regular);
  font-size: 0.6875rem;
  line-height: var(--p-font-line-height-400);
  padding: var(--p-space-025) 0.3125rem;
  border-radius: var(--p-border-radius-200);
  white-space: nowrap;
  color: var(--p-color-text-inverse);
  border: var(--p-border-width-025) solid var(--p-color-border-inverse);
  background-color: var(--p-color-bg-inverse);
}

._EditionsBadge_1mgbd_1:hover,
._EditionsBadge_1mgbd_1:focus {
  border-color: var(--p-color-border-inverse);
  color: var(--p-color-text-inverse);
  background-color: var(--p-color-bg-fill-inverse-hover);
}

._EditionsBadgePlus_1mgbd_25 {
  color: var(--p-color-text-inverse);
}

@media (max-width: 65.125em) {
  ._EditionsBadgePlus_1mgbd_25 {
    display: none;
  }
}

._Branding_1yrt4_4 {
  display: flex;
  align-items: center;
  padding: 0 0.875rem;
  height: 3.5rem;
}

._ShopName_1yrt4_13 {
  margin-left: var(--p-space-200);
  width: 70%;
}

._ShopifyLogoWrapper_1yrt4_18 {
  display: flex;
  align-items: center;
  padding: 0 var(--p-space-500);
  height: 3.5rem;
}

._LogoWrapper_1yrt4_25 {
  z-index: 1;
  cursor: pointer;
  white-space: nowrap;
}

._ShopifyLogo_1yrt4_18 {
  flex-shrink: 0;
  cursor: pointer;
  transition: transform var(--p-motion-duration-200),
    filter var(--p-motion-duration-300);
  filter: grayscale(100%);
  width: 1.3125rem;
  height: 1.5rem;
}

._ShopifyLogo_1yrt4_18._pressed_1yrt4_43 {
  transform: scale(1.1);
}

._ShopifyLogo_1yrt4_18._mounted_1yrt4_47 {
  filter: grayscale(0%);
}

._ShopifyWordmark_1yrt4_52 {
  flex-shrink: 0;
  margin-top: 0.5625rem;
  width: 3.875rem;
  margin-left: 0.1875rem;
  position: relative;
}

._PlusWordmark_1yrt4_63 {
  width: 5.9375rem;
}

._EditionsBadge_1yrt4_67 {
  opacity: 0;
  margin-left: var(--p-space-200);
  transform: translate3d(-100%, 0, 0);
  transition: all var(--p-motion-duration-200) ease-out;
}

._EditionsBadge_1yrt4_67._animate_1yrt4_73 {
  transform: translateZ(0);
}

._EditionsBadge_1yrt4_67._mounted_1yrt4_47 {
  opacity: 1;
}

._Logo_1yrt4_25._enter_1yrt4_83 {
  opacity: 0;
  transform: scale(0);
}

._Logo_1yrt4_25._enter-active_1yrt4_88 {
  opacity: 1;
  transform: scale(1);
  animation: _bounce-in_1yrt4_1 var(--p-motion-duration-200)
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

._Logo_1yrt4_25._exit_1yrt4_95 {
  opacity: 1;
  transform: scale(1);
}

._Logo_1yrt4_25._exit-active_1yrt4_100 {
  opacity: 0;
  transform: scale(0);
}

._Logo_1yrt4_25._enter-active_1yrt4_88,
._Logo_1yrt4_25._exit-active_1yrt4_100 {
  transition: all var(--p-motion-duration-150);
}

@keyframes _bounce-in_1yrt4_1 {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scaleZ(1);
  }
}

._PlusNavDrawer_kbiwr_4 {
  --p-color-bg-surface: rgba(0, 0, 0, 1);
  height: 100%;
}

@media (width < 768px) {
  ._PlusNavDrawer_kbiwr_4 {
    --p-color-border-secondary: transparent;
  }
}

._NavContainer_kbiwr_15 {
  position: relative;
}

._NavItem_yoszi_4 {
  position: relative;
  z-index: 1;
}

._NavItem_yoszi_4,
._NavItem_yoszi_4 > ul {
  padding: 0;
  margin: 0;
}

._NavItem_yoszi_4._hasViewMoreToggle_yoszi_19
  [class*="Polaris-Navigation__SecondaryNavigation"] {
  margin-bottom: 0;
}

._NavItem_yoszi_4 img,
._IconSvg_yoszi_28 {
  transform: scale(0.8);
}

._IconSvg_yoszi_28 {
  border-radius: var(--p-border-radius-100);
}

._IconImage_yoszi_36 {
  --pc-navigation-filter-icon: saturate(0) brightness(0.75) contrast(2);
  --pc-navigation-filter-icon-action-primary: var(--pc-navigation-filter-icon);
  --pc-navigation-filter-icon-on-interactive: var(--pc-navigation-filter-icon);
  display: block;
  font-size: 0;
  opacity: 0.63;
  border-radius: var(--p-border-radius-100);
}

._ViewMoreToggle_yoszi_58 {
  padding: 0 var(--p-space-200);
  margin-bottom: var(--p-space-200);
}

._ViewMoreToggleButton_yoszi_63 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._ViewMoreToggleButton_yoszi_63:focus {
  outline: none;
}

._ViewMoreToggleButton_yoszi_63 {
  position: relative;
}

._ViewMoreToggleButton_yoszi_63:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._ViewMoreToggleButton_yoszi_63 {
  outline: var(--p-border-width-025) solid transparent;
  border-radius: var(--p-border-radius-100);
  color: var(--p-color-text-emphasis);
  font-weight: var(--p-font-weight-medium);
  padding: var(--p-space-100) var(--p-space-100) var(--p-space-100)
    var(--p-space-1000);
  text-align: left;
  width: 100%;
}

._ViewMoreToggleButton_yoszi_63:hover,
._ViewMoreToggleButton_yoszi_63:active {
  background: var(--p-color-bg-surface-hover);
}

._ViewMoreToggleButton_yoszi_63:hover {
  color: var(--p-color-text-emphasis-hover);
}

._ViewMoreToggleButton_yoszi_63:active {
  color: var(--p-color-text-emphasis-active);
}

._ViewMoreToggleButton_yoszi_63:focus-visible:after {
  box-shadow: 0 0 0 0.125rem var(--p-color-border-focus);
  outline: var(--p-border-width-025) solid transparent;
}

._overlay_yoszi_93 ._ViewMoreToggle_yoszi_58 {
  display: none;
}

._overlay_yoszi_93 [class*="Polaris-Navigation__ItemInnerWrapper"] {
  box-shadow: 0 0.25rem 1.25rem #00000026, 0 0 0.1875rem #0000001a;
  background-color: var(--p-color-bg-surface);
}

._overlay_yoszi_93
  [class*="Polaris-Navigation__ItemInnerWrapper"]
  [class*="Polaris-Navigation__Item"] {
  background-color: var(--p-color-bg-surface);
  cursor: grabbing;
  border-radius: var(--p-border-radius-100);
}

._overlay_yoszi_93
  [class*="Polaris-Navigation__ItemInnerWrapper"]
  [class*="Polaris-Navigation__Item"]:before {
  display: none;
}

._overlay_yoszi_93 [class*="Navigation__SecondaryNavigation"] {
  display: none;
}

._mobileNav_yoszi_120
  [class*="Polaris-Navigation__ItemWrapper"]
  > [class*="Polaris-Navigation__Item"] {
  padding-right: 0;
}

._Heading_xvijd_4 {
  padding: 0 var(--p-space-300);
}

@supports (scrollbar-gutter: stable) {
  ._Heading_xvijd_4 {
    padding: 0 calc(var(--p-space-300) - var(--pc-app-provider-scrollbar-width));
  }
}

._Overrides_xvijd_13 [class^="Polaris-PositionedOverlay"] {
  z-index: var(--p-z-index-9);
}

._Icon_xvijd_20 {
  padding-right: var(--p-space-100);
  height: var(--p-space-500);
  width: var(--p-space-500);
  padding-top: var(--p-space-050);
}

._Icon_xvijd_20 svg {
  fill: var(--p-color-icon-secondary);
}

._Button_xvijd_32 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._Button_xvijd_32:focus {
  outline: none;
}

._Button_xvijd_32 {
  font-size: 0.8125rem;
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-font-line-height-400);
  text-transform: uppercase;
}

@media (min-width: 48em) {
  ._Button_xvijd_32 {
    font-size: var(--p-font-size-300);
  }
}

._Button_xvijd_32 {
  position: relative;
}

._Button_xvijd_32:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._Button_xvijd_32 {
  outline: var(--p-border-width-025) solid transparent;
  color: var(--p-color-text-secondary);
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  border-radius: var(--p-border-radius-200);
  padding: var(--p-space-100) 0 var(--p-space-100) var(--p-space-200);
}

._Button_xvijd_32:hover,
._Button_xvijd_32:active {
  background-color: transparent;
  color: var(--p-color-text);
}

._Button_xvijd_32:hover ._Icon_xvijd_20 svg,
._Button_xvijd_32:active ._Icon_xvijd_20 svg {
  fill: var(--p-color-icon);
}

._Button_xvijd_32:active {
  background-color: transparent;
}

._Button_xvijd_32:focus-visible:after {
  content: none;
}

._Button_xvijd_32:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-050);
}

._Line_xdwh3_1 {
  border-top: var(--p-border-width-025) solid var(--p-color-bg-fill-tertiary);
  margin: var(--p-space-200) var(--p-space-500);
}

._DragReorderItem_j51sm_1 {
  position: relative;
  z-index: 1;
}

._DragReorderItem_j51sm_1._IsDragging_j51sm_10 {
  z-index: 100;
  height: 0;
}

._DragReorderItem_j51sm_1._IsDragging_j51sm_10
  > *:not(._DragIndicator_j51sm_15) {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
}

._DragReorderItemOverlay_j51sm_23 ._DragIndicator_j51sm_15 {
  display: none;
}

._DragIndicator_j51sm_15 {
  position: relative;
  width: calc(100% - 2.6875rem);
  margin: 0 auto;
}

._DragIndicator_j51sm_15:before {
  content: "";
  position: absolute;
  left: -0.0625rem;
  top: -0.0625rem;
  height: 0.125rem;
  width: 100%;
  background-color: var(--p-color-border-focus);
}

._DragIndicator_j51sm_15:after {
  content: "";
  position: absolute;
  top: -0.1875rem;
  left: -0.375rem;
  display: block;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: var(--p-border-radius-full);
  background-color: var(--p-color-border-focus);
}

._DragReorderList_1csum_1,
._Items_1csum_2 {
  list-style: none;
  margin: 0;
  padding: 0;
}

._DragReorderList_1csum_1._OnlyActiveItem_1csum_7,
._Items_1csum_2._OnlyActiveItem_1csum_7 {
  padding-top: var(--p-space-200);
}

._DragOverlayWrapper_1csum_12 {
  position: absolute;
  z-index: var(--p-z-index-2);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  cursor: grabbing;
}

._RecommendationButton_1n1rx_4 {
  padding: 0 var(--p-space-300);
}

._Button_1n1rx_8 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._Button_1n1rx_8:focus {
  outline: none;
}

._Button_1n1rx_8 {
  font-size: 0.9375rem;
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-font-line-height-400);
  text-transform: initial;
  letter-spacing: initial;
}

@media (min-width: 48em) {
  ._Button_1n1rx_8 {
    font-size: var(--p-font-size-350);
  }
}

._Button_1n1rx_8 {
  position: relative;
}

._Button_1n1rx_8:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._Button_1n1rx_8 {
  outline: var(--p-border-width-025) solid transparent;
  text-transform: none;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: var(--p-border-radius-200);
  padding: var(--p-space-200) var(--p-space-100);
  color: var(--p-color-text-secondary);
  font-size: var(--p-font-size-325);
  font-weight: var(--p-font-weight-medium);
}

._Button_1n1rx_8:hover,
._Button_1n1rx_8:active {
  background: var(--p-color-bg-surface-hover);
}

._Button_1n1rx_8:is(:hover, :focus-visible) {
  background: var(--p-color-bg-fill-transparent-hover);
}

._Button_1n1rx_8:active {
  background: var(--p-color-bg-fill-transparent-active);
}

._Button_1n1rx_8:focus-visible:not(:active):after {
  content: none;
}

._Button_1n1rx_8:focus-visible:not(:active) {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
}

@media (min-width: 48em) {
  ._Button_1n1rx_8 {
    padding: var(--p-space-100) var(--p-space-200);
  }
}

._Icon_1n1rx_46 {
  margin-right: var(--p-space-200);
}

._Section_qklpz_1 {
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

._Spacer_qklpz_8 {
  height: 0.75rem;
}

._NavItemContainer_qklpz_12 {
  display: flex;
  flex-direction: row;
  padding-bottom: var(--p-space-300);
  align-items: flex-end;
  animation: _slide-in_qklpz_1 var(--p-motion-duration-400) forwards;
  opacity: 0;
  margin-left: 1.375rem;
}

._NavigationSkeleton_qklpz_23,
._NavigationSkeletonItem70_qklpz_24,
._NavigationSkeletonItem80_qklpz_25,
._NavigationSkeletonItem100_qklpz_26,
._NavigationSkeletonItem140_qklpz_27 {
  border-radius: var(--p-border-radius-100);
  background-color: var(--p-color-bg-fill-tertiary);
  display: flex;
  height: 0.875rem;
}

._NavigationSkeleton_qklpz_23 {
  width: 1rem;
  margin-right: var(--p-space-300);
}

._NavigationSkeletonItem70_qklpz_24 {
  width: 4.375rem;
}

._NavigationSkeletonItem80_qklpz_25 {
  width: 5rem;
}

._NavigationSkeletonItem100_qklpz_26 {
  width: 6.25rem;
}

._NavigationSkeletonItem140_qklpz_27 {
  width: 8.75rem;
}

@keyframes _slide-in_qklpz_1 {
  0% {
    opacity: 0;
    transform: translateY(-2%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

._Section_fmdqr_1 {
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

._SubSection_fmdqr_8 {
  padding-bottom: var(--p-space-400);
}

._TransferDisabledBanner_1ppyt_1 {
  margin: var(--p-space-200);
}

._TransferDisabledText_1ppyt_5 {
  font-weight: var(--p-font-weight-regular);
  font-size: var(--p-font-size-400);
}

@media (min-width: 48em) {
  ._TransferDisabledText_1ppyt_5 {
    font-weight: var(--p-font-weight-medium);
    font-size: var(--p-font-size-350);
  }
}

._StickyBottomNav_1ria9_1 {
  position: sticky;
  z-index: 1;
  bottom: 0;
  padding-top: var(--p-space-200);
  transition: box-shadow var(--p-motion-duration-350) var(--p-motion-ease),
    border-top var(--p-motion-duration-350) var(--p-motion-ease);
  background: var(--p-color-bg-surface-secondary-active);
}

._StickyBottomNav_1ria9_1 .Polaris-Navigation__ListItem {
  padding-bottom: 16px;
}

._StickyBottomNav_1ria9_1 .Polaris-Navigation__Section {
  padding-bottom: 0;
}

._BodyShadow_1ria9_14 {
  position: absolute;
  z-index: var(--p-z-index-9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

._BodyShadow_1ria9_14:before {
  content: "";
  position: absolute;
  bottom: 0.0625rem;
  left: 0;
  right: 0;
  height: var(--p-space-100);
  box-shadow: var(--p-shadow-100);
  opacity: 0;
  transition: opacity var(--p-motion-ease) var(--p-motion-duration-300);
}

._ShadowActive_1ria9_36:before {
  opacity: 1;
}

._TopShadow_1ria9_42 {
  box-shadow: calc(var(--p-space-200) * -1) calc(var(--p-space-050) * -1)
    var(--p-space-200) #0000000d;
}

._FadeTransition_v2lpc_1 {
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity var(--p-motion-duration-0);
}

._FadeTransition_v2lpc_1._enter_v2lpc_7,
._FadeTransition_v2lpc_1._entered_v2lpc_11,
._FadeTransition_v2lpc_1._exit_v2lpc_15 {
  opacity: 0;
}

._FadeTransition_v2lpc_1._exited_v2lpc_19 {
  opacity: 1;
}

._SearchActivator_1ko2y_11 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
}

._SearchActivator_1ko2y_11:focus {
  outline: none;
}

._SearchActivator_1ko2y_11 {
  position: relative;
}

._SearchActivator_1ko2y_11:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._SearchActivator_1ko2y_11 {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  width: 100%;
  height: 2rem;
  padding: var(--p-space-200);
  color: var(--p-color-text-inverse-secondary);
  background-color: var(--p-color-bg-fill-inverse);
  border: var(--p-border-width-0165) solid var(--p-color-border-inverse);
  border-radius: var(--p-border-radius-200);
}

._SearchActivator_1ko2y_11:hover {
  border-color: var(--p-color-border-hover);
}

._SearchActivator_1ko2y_11:hover ._Icon_1ko2y_31 svg {
  fill: var(--p-color-icon-secondary);
}

._SearchActivator_1ko2y_11:active,
._SearchActivator_1ko2y_11:focus {
  box-shadow: inset 0 0 0 var(--p-border-width-025) var(--p-color-border);
}

._SearchActivator_1ko2y_11 ._Shortcut_1ko2y_41 {
  margin-left: auto;
}

@media (max-width: 47.9975em) {
  ._SearchActivator_1ko2y_11 ._Shortcut_1ko2y_41 {
    visibility: hidden;
  }
}

._SearchActivator-TopBarAndReframe_1ko2y_50 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._SearchActivator-TopBarAndReframe_1ko2y_50:focus {
  outline: none;
}

._SearchActivator-TopBarAndReframe_1ko2y_50 {
  position: relative;
}

._SearchActivator-TopBarAndReframe_1ko2y_50:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._SearchActivator-TopBarAndReframe_1ko2y_50 {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  width: 100%;
  padding: var(--p-space-200);
  color: var(--p-color-text-secondary);
  border-radius: var(--p-border-radius-200);
}

._SearchActivator-TopBarAndReframe_1ko2y_50:hover ._Icon_1ko2y_31 svg {
  fill: var(--p-color-icon-secondary);
}

._SearchActivator-TopBarAndReframe_1ko2y_50 ._Shortcut_1ko2y_41 {
  margin-left: auto;
}

@media (max-width: 47.9975em) {
  ._SearchActivator-TopBarAndReframe_1ko2y_50 ._Shortcut_1ko2y_41 {
    visibility: hidden;
  }
}

._Label_1ko2y_78 {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._Icon_1ko2y_31 {
  display: flex;
  height: 1.125rem;
  pointer-events: none;
  margin-right: var(--p-space-100);
}

._Icon_1ko2y_31 svg {
  fill: var(--p-color-icon-secondary);
  transition: calc(var(--p-motion-duration-200) - 33ms) fill
    var(--p-motion-ease) 33ms;
}

._SearchTagButton_1v8qd_4 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._SearchTagButton_1v8qd_4:focus {
  outline: none;
}

._SearchTagButton_1v8qd_4 {
  position: relative;
}

._SearchTagButton_1v8qd_4:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._SearchTagButton_1v8qd_4 {
  padding-right: var(--p-space-100);
}

._SearchTagButton_1v8qd_4:hover {
  background: var(--p-color-bg-fill-tertiary-hover);
  outline: var(--p-border-width-025) solid transparent;
}

._SearchTagButton_1v8qd_4:focus {
  background-color: transparent;
}

._SearchTagButton_1v8qd_4:focus-visible:not(:active):after {
  box-shadow: 0 0 0 0.125rem var(--p-color-border-focus);
  outline: var(--p-border-width-025) solid transparent;
}

._SearchTagButton_1v8qd_4:active {
  background: var(--p-color-bg-fill-tertiary-active);
}

._SearchTagButton_1v8qd_4 ._Label_1v8qd_26:first-child {
  margin-left: var(--p-space-100);
}

._SearchTag_1v8qd_4 {
  --search-tag-height: 1.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: var(--p-space-100);
  max-width: 100%;
  min-height: var(--search-tag-height);
  background-color: var(--p-color-bg-fill-tertiary);
  border-radius: var(--p-border-radius-100);
  color: var(--p-color-text);
}

._Icon_1v8qd_44 {
  margin-left: var(--p-space-100);
  height: 1rem;
  width: 1rem;
}

._Label_1v8qd_26 {
  margin-left: var(--p-space-200);
}

._Label_1v8qd_26:last-child {
  margin-right: var(--p-space-100);
}

._RemoveButton_1v8qd_58 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._RemoveButton_1v8qd_58:focus {
  outline: none;
}

._RemoveButton_1v8qd_58 {
  position: relative;
}

._RemoveButton_1v8qd_58:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._RemoveButton_1v8qd_58 {
  height: 100%;
  padding: var(--p-space-100);
  margin-left: var(--p-space-100);
  border-radius: 0 var(--p-border-radius-100) var(--p-border-radius-100) 0;
}

._RemoveButton_1v8qd_58:hover {
  background: var(--p-color-bg-fill-tertiary-hover);
  outline: var(--p-border-width-025) solid transparent;
}

._RemoveButton_1v8qd_58:focus {
  background-color: transparent;
}

._RemoveButton_1v8qd_58:focus-visible:not(:active):after {
  box-shadow: 0 0 0 0.125rem var(--p-color-border-focus);
  outline: var(--p-border-width-025) solid transparent;
}

._RemoveButton_1v8qd_58:active {
  background: var(--p-color-bg-fill-tertiary-active);
}

._RemoveButton_1v8qd_58 svg {
  fill: var(--p-color-icon);
}

._AutoComplete_1n15a_1 {
  color: var(--p-color-text-secondary);
  position: absolute;
  cursor: pointer;
  font-family: var(--p-font-family-sans);
  font-size: var(--p-font-size-325);
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
}

._Hidden_1n15a_13 {
  display: none;
}

._Invisible_1n15a_17 {
  visibility: hidden;
}

._SearchField_hnr24_20 {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  color: var(--p-color-text);
  height: 2rem;
}

._SearchField_hnr24_20:focus-within ._SearchIcon_hnr24_31 {
  fill: var(--p-color-icon);
}

._SearchField_hnr24_20:focus-within ._Clear_hnr24_35 svg {
  fill: var(--p-color-icon-secondary);
}

._Spinner_hnr24_44 {
  transform: scale(0.8);
  margin-top: -0.0625rem;
}

._FilterButton_hnr24_50 {
  z-index: var(--p-z-index-1);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--p-border-radius-200);
  margin-right: var(--p-space-200);
  margin-left: calc(var(--p-space-050) * -1);
}

._FilterButton_hnr24_50 path {
  fill: var(--p-color-icon);
}

._FilterButtonActive_hnr24_68 {
  background-color: var(--p-color-bg-surface-secondary-active);
}

._FilterBadgeContainer_hnr24_72 {
  z-index: var(--p-z-index-1);
}

._SearchIcon_hnr24_31 {
  z-index: 1;
  display: flex;
  width: 1.125rem;
  height: 1.125rem;
  fill: var(--p-color-icon-secondary);
  margin-left: var(--p-space-200);
}

._Input_hnr24_88 {
  z-index: 1;
  caret-color: var(--p-color-text);
  color: var(--p-color-text);
  font-family: var(--p-font-family-sans);
  font-size: var(--p-font-size-325);
  line-height: var(--p-font-line-height-600);
  position: relative;
  flex: 1 1;
  min-width: 0;
  min-height: 2rem;
  margin: 0 var(--p-space-200) 0 var(--p-space-025);
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}

._Input_hnr24_88::placeholder {
  color: var(--p-color-text-secondary);
}

._Input_hnr24_88:focus-visible ~ ._Backdrop_hnr24_112 {
  background-color: var(--p-color-input-bg-surface-active);
  border: var(--p-border-width-0165) solid var(--p-color-input-border-active);
  box-shadow: inset 0 0 0 var(--p-border-width-025)
    var(--p-color-input-border-active);
}

._Input_hnr24_88:hover:not(:focus-visible) ~ ._Backdrop_hnr24_112 {
  background-color: var(--p-color-input-bg-surface-hover);
  border-color: var(--p-color-input-border-hover);
}

._Clear_hnr24_35 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._Clear_hnr24_35:focus {
  outline: none;
}

._Clear_hnr24_35 {
  position: relative;
}

._Clear_hnr24_35:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._Clear_hnr24_35 {
  z-index: 1;
  cursor: pointer;
  width: auto;
  margin-right: var(--p-space-200);
}

._Clear_hnr24_35:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-050);
  border-radius: var(--p-border-radius-200);
}

._Tags_hnr24_146 {
  z-index: 1;
  margin: 0 0.3125rem;
}

._Backdrop_hnr24_112 {
  position: relative;
}

._Backdrop_hnr24_112:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._Backdrop_hnr24_112 {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: var(--p-color-input-bg-surface);
  border: var(--p-border-width-0165) solid var(--p-color-input-border);
  border-radius: var(--p-border-radius-200);
  pointer-events: none;
}

._Footer_f96qf_1 {
  position: sticky;
  bottom: 0;
  background-color: var(--p-color-bg-fill-tertiary);
  color: var(--p-color-text-secondary);
  border-top: var(--p-border-width-025) solid var(--p-color-border);
  padding: var(--p-space-300) var(--p-space-400);
}

._SearchResultSection_izb45_1 {
  padding: 0 var(--p-space-300) 0;
}

._SearchResultSection_izb45_1:not(:first-child) {
  margin-top: var(--p-space-200);
}

._SearchResultSection_izb45_1:not(:first-child)
  ._Rows_izb45_8:first-child:before {
  content: "";
  display: block;
  background-color: var(--p-color-border-secondary);
  margin: var(--p-space-200) 0;
  height: 0.0625rem;
}

._SearchResultSection_izb45_1:last-child {
  padding-bottom: var(--p-space-300);
}

._SearchResultSection_izb45_1._CondensedSection_izb45_21 {
  margin-top: 0;
}

._FooterSection_izb45_26 {
  position: sticky;
  bottom: 0;
  background: #f3f3f3;
  padding: var(--p-space-200) var(--p-space-300) 0;
}

._FooterSection_izb45_26 ._Rows_izb45_8:first-child:before {
  display: none;
}

._Title_izb45_40 {
  margin: var(--p-space-100) var(--p-space-050);
}

._Title_izb45_40 > * {
  text-transform: none;
}

._TitleAction_izb45_48 {
  padding-right: var(--p-space-200);
}

._TitleAction_izb45_48 [class*="Polaris-Button__Text"] {
  font-size: var(--p-font-size-300);
}

._Rows_izb45_8 {
  padding: 0;
  margin: 0;
  list-style: none;
}

._SearchResults_1f9q7_1 {
  padding: 0;
}

._BaseRow_5e42z_1 {
  display: block;
  width: 100%;
  padding: var(--p-space-200);
  border-radius: var(--p-border-radius-200);
  background: none;
  border: 0;
  text-align: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

._Selected_5e42z_15 {
  background: var(--p-color-bg-surface-secondary);
}

._FilterRow_1w8dw_1._Selected_1w8dw_2 button {
  background: var(--p-color-bg-fill-tertiary-hover);
}

._SearchBox_dcy5y_8 {
  display: flex;
  color: var(--p-color-text);
  flex-direction: column;
  align-items: left;
  justify-content: top;
  min-width: 100%;
  background-color: var(--p-color-bg-surface);
  border-radius: var(--p-border-radius-300);
  box-shadow: var(--p-shadow-600);
  overflow-y: auto;
  scroll-behavior: smooth;
}

._SearchField_dcy5y_22 {
  z-index: 3;
  position: sticky;
  top: 0;
  background-color: var(--p-color-bg-surface);
  padding: var(--p-space-300) var(--p-space-300)
    calc(var(--p-space-300) - 0.1875rem);
  margin-bottom: 0.1875rem;
}

._SearchResults_dcy5y_38 {
  z-index: 1;
  position: relative;
  padding: 0;
  visibility: visible;
}

._SearchResults-hidden_dcy5y_46 {
  display: none;
}

._Footer_dcy5y_50 {
  z-index: 2;
  position: sticky;
  bottom: 0;
}

._SearchIcon_12vmt_1 {
  width: 3.75rem;
  height: 3.75rem;
  align-self: center;
}

._FooterRowSelected_brgon_6 {
  background: var(--p-color-bg-fill-tertiary);
}

._RowContentContainer_brgon_10 {
  display: flex;
  flex-direction: column;
  align-items: normal;
  overflow: hidden;
  gap: var(--p-space-050);
}

._PaginationRow_brgon_18 {
  padding-left: var(--p-space-1000);
  color: var(--p-color-text-emphasis);
  line-height: var(--p-font-line-height-500);
}

._Icon_brgon_24 {
  display: flex;
  flex: 0 0 var(--p-space-600);
  justify-content: center;
  width: var(--p-space-600);
  border-radius: var(--p-border-radius-150);
  overflow: hidden;
}

._Icon_brgon_24._withBorder_brgon_32 {
  height: var(--p-space-600);
  border: var(--p-border-width-025) solid var(--p-color-border);
  box-sizing: border-box;
}

._ImageIcon_brgon_39 img {
  display: block;
  width: var(--p-space-600);
  height: var(--p-space-600);
  object-fit: cover;
}

._HighlightedTerm-Title_brgon_1 {
  font-weight: var(--p-font-weight-bold);
  color: var(--p-color-text);
  background-color: transparent;
}

._HighlightedTerm_brgon_48 {
  font-weight: var(--p-font-weight-semibold);
  color: var(--p-color-text);
  background-color: transparent;
}

._StarIcon_brgon_59 {
  height: var(--p-space-400);
  width: var(--p-space-400);
  margin-top: calc(var(--p-space-050) * -1);
  margin-right: var(--p-space-100);
}

._ExtraBoldText_hdvz2_1 {
  font-weight: 750;
}

._HighlightedTerm_1rmaq_1 {
  font-weight: var(--p-font-weight-semibold);
  color: var(--p-color-text);
  background-color: transparent;
}

._HighlightedTerm-Bold_1rmaq_7 {
  font-weight: var(--p-font-weight-bold);
  color: var(--p-color-text);
  background-color: transparent;
}

._HighlightedTerm-ExtraBold_1rmaq_13 {
  font-weight: 750;
  color: var(--p-color-text);
  background-color: transparent;
}

._SearchResultsContainer_amss5_1 {
  min-height: 10.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

._SearchResultsContainer_amss5_1 > :empty {
  min-height: 10.375rem;
}

._withActiveFilterOrNoResults_amss5_12 {
  min-height: 9.75rem;
  padding-top: calc(var(--p-space-600) + var(--p-space-100));
}

._withActiveFilterOrNoResults_amss5_12 > :empty {
  min-height: 8.375rem;
}

._withResults_amss5_21 {
  min-height: 0;
  padding-top: initial;
}

._Overlay_bslhs_8 {
  z-index: var(--p-z-index-2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

._Overlay-TopBarAndReframe_bslhs_17 {
  z-index: var(--p-z-index-12);
}

._AdminSearch_bslhs_21 {
  --p-color-bg-surface: rgba(255, 255, 255, 1);
  --p-color-icon: rgba(92, 95, 98, 1);
  --p-color-text: rgba(32, 34, 35, 1);
  position: fixed;
  left: 0;
  top: var(--p-space-100);
  height: auto;
  min-width: 40.5rem;
  max-width: 40.5rem;
  min-height: 6.25rem;
}

@media (max-width: 40.5em) {
  ._AdminSearch_bslhs_21 {
    top: 0;
    min-width: 100vw;
    max-width: 100vw;
    margin-left: 0;
  }
}

._AdminSearch_bslhs_21 {
  animation: _expand-in_bslhs_1 40ms var(--p-motion-ease-in-out);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity, min-width, max-width;
}

@keyframes _expand-in_bslhs_1 {
  0% {
    opacity: 0;
    min-width: 31.5rem;
    max-width: 31.5rem;
  }

  to {
    opacity: 1;
    min-width: 40.5rem;
    max-width: 40.5rem;
  }
}

._SearchBox_bslhs_70 {
  max-height: calc(100vh - var(--p-space-1000));
  animation: _elevate-in_bslhs_1 40ms var(--p-motion-ease-in-out);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: box-shadow;
}

._SearchBox_bslhs_70 > * {
  opacity: 0;
  animation: var(--p-motion-keyframes-fade-in) 40ms 40ms forwards;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
}

@media (max-width: 40.5em) {
  ._SearchBox_bslhs_70 {
    border-radius: 0;
    max-height: 100vh;
  }
}

@keyframes _elevate-in_bslhs_1 {
  0% {
    box-shadow: none;
  }

  to {
    box-shadow: var(--p-shadow-600);
  }
}

._SearchActivatorWrapper_bslhs_102 {
  width: 100%;
}

._CurrentShop_1ulhh_4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: var(--p-color-bg-surface-secondary-selected);
  padding: var(--p-space-300);
  border-radius: 0;
  cursor: default;
}

@media (min-width: 48em) {
  ._CurrentShop_1ulhh_4 {
    background-color: var(--p-color-bg-surface-brand-selected);
    padding: var(--p-space-100) var(--p-space-150);
    height: var(--p-space-800);
    margin-bottom: calc(var(--p-space-100) * -1);
    border-radius: var(--p-border-radius-200);
    margin-left: calc((var(--p-space-100) / 2) * -1);
  }
}

._CurrentShop_1ulhh_4 svg {
  fill: var(--p-color-icon);
}

._CurrentShop_1ulhh_4 ._ShopName_1ulhh_27 {
  flex: 2 1 auto;
  padding-right: var(--p-space-050);
  max-width: 80%;
}

._CurrentShop_1ulhh_4 > span:nth-child(1) {
  margin-right: var(--p-space-150);
}

._CurrentShopPlus_1ulhh_38 {
  position: relative;
}

@media (min-width: 48em) {
  ._CurrentShopPlus_1ulhh_38 {
    margin-left: var(--p-space-500);
  }
}

@media (min-width: 48em) {
  ._CurrentShopPlus_1ulhh_38:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 0.0625rem solid var(--p-color-border);
    margin-left: calc(var(--p-space-150) * -1);
  }
}

._CurrentProfile_1ltum_1 {
  padding: var(--p-space-300);
  cursor: default;
}

@media (min-width: 48em) {
  ._CurrentProfile_1ltum_1 {
    padding: var(--p-space-150) var(--p-space-200) var(--p-space-050)
      var(--p-space-100);
  }
}

._CurrentProfile_1ltum_1 ._UserName_1ltum_10 {
  font-size: var(--p-font-size-325);
}

._NotificationsList_k2zqk_1 {
  margin: 0;
  padding: 0;
}

._NotificationsListTitle_k2zqk_6 {
  padding: var(--p-space-200) 0;
  display: flex;
  align-items: center;
}

._NotificationsListTitle_k2zqk_6:before,
._NotificationsListTitle_k2zqk_6:after {
  content: "";
  flex: 1 1;
  border-bottom: 0.0625rem solid var(--p-color-border-secondary);
}

._NotificationsListTitle_k2zqk_6:before {
  margin-right: var(--p-space-200);
}

._NotificationsListTitle_k2zqk_6:after {
  margin-left: var(--p-space-200);
}

._NotificationItemButton_k2zqk_27 {
  height: var(--notification-button-size);
  width: var(--notification-button-size);
  padding: 0;
  background: none;
  border: 0;
  border-radius: var(--p-border-radius-100);
  pointer-events: none;
  opacity: 0;
}

@media (hover: none) and (pointer: coarse) {
  ._NotificationItemButton_k2zqk_27 {
    opacity: 1;
  }
}

._NotificationItemButton_k2zqk_27:focus {
  opacity: 1;
}

._NotificationItemButton_k2zqk_27._NotificationButtonClickable_k2zqk_46 {
  cursor: pointer;
  pointer-events: auto;
}

._NotificationHeadingButton_k2zqk_52 {
  height: var(--notification-button-size);
  width: var(--notification-button-size);
  padding: 0;
  background: none;
  border: 0;
  border-radius: var(--p-border-radius-100);
  pointer-events: none;
}

._NotificationHeadingButton_k2zqk_52:hover {
  background: var(--p-color-bg-surface-secondary-hover);
}

._NotificationHeadingButton_k2zqk_52._NotificationButtonClickable_k2zqk_46 {
  cursor: pointer;
  pointer-events: auto;
}

._NotificationItem_k2zqk_27 {
  list-style: none;
}

._NotificationItem_k2zqk_27 [class^="Polaris-Link"],
._NotificationItem_k2zqk_27 [class^="Polaris-Link"]:hover,
._NotificationItem_k2zqk_27
  [class^="Polaris-Link"]:hover
  ._NotificationItemHeading_k2zqk_82 {
  color: var(--p-color-text);
}

._NotificationItem_k2zqk_27
  [class^="Polaris-Link"]:hover
  ._NotificationItemButton_k2zqk_27,
._NotificationItem_k2zqk_27
  [class^="Polaris-Link"]:focus
  ._NotificationItemButton_k2zqk_27 {
  opacity: 1;
}

._NotificationItem_k2zqk_27 [class*=" Polaris-Link--removeUnderline"]:hover {
  text-decoration: none;
}

._NotificationItem_k2zqk_27
  [class*=" Polaris-Link--removeUnderline"]:hover
  ._NotificationItemLink_k2zqk_101 {
  background: var(--p-color-bg-surface-secondary-hover);
}

._NotificationItem_k2zqk_27 + ._NotificationItem_k2zqk_27 {
  border-top: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._NotificationItemInner_k2zqk_112 {
  padding: 0.625rem var(--p-space-300) var(--p-space-400) var(--p-space-300);
}

._NotificationItemContent_k2zqk_117 {
  margin: calc(var(--p-space-100) * -1);
}

._NotificationIcon_k2zqk_121 {
  height: 1.75rem;
}

._CriticalIconContainer_k2zqk_125 {
  position: absolute;
  display: inline-block;
  height: var(--notification-critical-icon-size);
  width: var(--notification-critical-icon-size);
  vertical-align: middle;
  left: calc(
    var(--p-space-300) + var(--notification-read-indicator-size) / 2 - 0.625rem
  );
  margin: 0;
}

._CriticalIconContainer_k2zqk_125 [class*="Polaris-Icon--colorCritical"] svg {
  fill: var(--p-color-text-critical);
}

._NotificationItemHeading_k2zqk_82 {
  height: 1.75rem;
  display: flex;
  align-items: center;
}

._ReadIndicatorContainer_k2zqk_152 {
  margin-top: var(--p-space-200);
  padding-top: var(--p-space-050);
}

._ReadIndicator_k2zqk_152 {
  width: var(--notification-read-indicator-size);
  height: var(--notification-read-indicator-size);
  border-radius: 0.1875rem;
  background-color: var(--p-color-icon-emphasis);
}

._NotificationsInner_1gzjd_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--p-space-400);
  min-height: var(--p-space-1200);
}

._NotificationsFooter_kfmvb_1 {
  border-top: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._UnreadFilterButton_fsw2r_1 {
  height: 1.75rem;
  width: 1.75rem;
  padding: 0;
  background: none;
  border: 0;
  border-radius: var(--p-border-radius-100);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

._UnreadFilterButton_fsw2r_1:hover {
  background: var(--p-color-bg-surface-secondary-hover);
}

._SpinnerWrapper_fsw2r_18 {
  height: 1.75rem;
  width: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

._SpinnerWrapper_fsw2r_18 svg {
  display: block;
}

._UnreadFilterClickable_fsw2r_30 {
  cursor: pointer;
  pointer-events: auto;
}

._NotificationsHeading_7ighe_1 {
  --p-color-icon-secondary: var(--p-color-icon-disabled);
}

._ActivatorWrapper_1l8g6_4 {
  height: 3.5rem;
  display: flex;
  align-items: center;
  margin-right: var(--p-space-200);
}

._ActivatorWrapper-TopBarAndReframe_1l8g6_12 {
  margin-right: var(--p-space-150);
}

@media (min-width: 48em) {
  ._ActivatorWrapper-TopBarAndReframe_1l8g6_12 {
    margin-right: var(--p-space-200);
  }
}

._Activator_1l8g6_4 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._Activator_1l8g6_4:focus {
  outline: none;
}

._Activator_1l8g6_4 {
  position: relative;
}

._Activator_1l8g6_4:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._Activator_1l8g6_4 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  transition: background-color var(--p-motion-duration-100);
  color: var(--p-color-text-inverse);
  padding: var(--p-space-150);
  background-color: var(--p-color-bg-fill-inverse);
  border-radius: var(--p-border-radius-200);
  min-width: auto;
}

._Activator_1l8g6_4:focus-visible:after {
  content: none;
}

._Activator_1l8g6_4:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-050);
}

._Activator_1l8g6_4:is(:hover, :focus-visible) {
  background-color: var(--p-color-bg-fill-inverse-hover);
}

._Activator_1l8g6_4:is(:hover, :focus-visible)
  [class*="alerts-feed-unread-count-indicator"] {
  border-color: var(--p-color-bg-surface-hover);
}

._Activator_1l8g6_4:active,
._Activator_1l8g6_4[aria-expanded="true"] {
  background-color: var(--p-color-bg-fill-inverse-active);
  outline: none;
  transition: none;
  color: var(--p-color-text-emphasis);
}

._Activator_1l8g6_4:active [class*="alerts-feed-unread-count-indicator"],
._Activator_1l8g6_4[aria-expanded="true"]
  [class*="alerts-feed-unread-count-indicator"] {
  border-color: var(--p-color-bg-surface-active);
  transition: none;
}

._Activator_1l8g6_4:active svg,
._Activator_1l8g6_4[aria-expanded="true"] svg {
  fill: var(--p-color-icon-inverse);
}

._Activator_1l8g6_4:active:after,
._Activator_1l8g6_4[aria-expanded="true"]:after {
  border: none;
}

@media (max-width: 47.9975em) {
  ._Activator_1l8g6_4 {
    background-color: var(--p-color-bg-inverse);
  }

  ._Activator_1l8g6_4:focus,
  ._Activator_1l8g6_4:hover,
  ._Activator_1l8g6_4:active,
  ._Activator_1l8g6_4[aria-expanded="true"] {
    background-color: transparent;
    opacity: 0.85;
  }
}

@media (max-width: 64.9975em) {
  ._Activator_1l8g6_4 {
    background-color: var(--p-color-bg-inverse);
  }
}

._Onboarding_1l8g6_89 {
  margin: var(--p-space-200) var(--p-space-400) var(--p-space-400);
  padding: var(--p-space-400);
  background-color: var(--p-color-bg-surface-hover);
  border-radius: var(--p-border-radius-050);
  text-align: center;
}

._MenuPopover_1l8g6_97 {
  --notification-button-size: 1.75rem;
  --notification-critical-icon-size: 1.25rem;
  --notification-read-indicator-size: 0.5rem;
  width: 23.75rem;
  max-width: calc(100vw - 2rem);
}

._IndicatorWrapper_cxjdc_1 {
  position: relative;
}

._Indicator_cxjdc_1 {
  --p-color-text: white;
  --indicator-font-size: 0.5rem;
  position: absolute;
  z-index: 1;
  color: var(--p-color-text);
  transition: border-color var(--p-motion-duration-100);
  top: -0.1875rem;
  left: unset;
  right: -0.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--p-color-icon-critical);
  padding: var(--p-space-050) 0.1875rem;
  border: 0;
  border-radius: var(--p-border-radius-200);
  min-width: var(--p-space-300);
  height: var(--p-space-300);
  font-weight: var(--p-font-weight-bold);
  font-size: var(--indicator-font-size);
  line-height: var(--indicator-font-size);
}

._Indicator_cxjdc_1._LeftReduced_cxjdc_31 {
  left: 0.3rem;
}

._Indicator_cxjdc_1._LeftReset_cxjdc_35 {
  left: 0;
}

._SecondaryMenuContainer_14ay1_4 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  margin-left: var(--p-space-200);
}

._SecondaryMenuContainer-TopBarAndReframe_14ay1_13 {
  margin-left: calc(var(--p-space-150) * -1);
}

@media (min-width: 48em) {
  ._SecondaryMenuContainer-TopBarAndReframe_14ay1_13 {
    margin-left: var(--p-space-200);
  }
}

._AccountMenuContainer_14ay1_21 {
  z-index: 1;
}

._ClickMask_14ay1_26 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

._CircularProgressBarContainer_9aw9n_1 {
  position: relative;
  display: inline-block;
}

._CircularProgressBar_9aw9n_1 {
  transform: rotate(-90deg);
  display: block;
}

._CircularProgressBarSize--small_9aw9n_11 {
  width: 1.25rem;
  height: 1.25rem;
}

._CircularProgressBarSize--large_9aw9n_16 {
  width: 2.5rem;
  height: 2.5rem;
}

._CircularProgressBarTrack_9aw9n_21 {
  stroke: var(--p-color-bg);
}

._CircularProgressBarStroke_9aw9n_25 {
  stroke: var(--p-color-icon-emphasis);
  stroke-linecap: round;
}

._CircularProgressBarStroke--error_9aw9n_30 {
  stroke: var(--p-color-icon-caution);
}

._ErrorIcon_9aw9n_34 {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  display: inline-block;
}

._StatusItem_olb7w_1 {
  padding: var(--p-space-100);
  display: flex;
  margin-bottom: var(--p-space-100);
}

._ProgressIndicator_olb7w_7 {
  margin-right: var(--p-space-200);
}

._SecondaryContent_olb7w_11 {
  margin-left: auto;
  color: var(--p-color-text-secondary);
}

._Panel_pf81s_4 {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: var(--p-shadow-600);
  border-radius: var(--p-border-radius-100);
  max-width: calc(100vw - var(--p-space-400) * 2);
  width: 31.5rem;
  gap: var(--p-space-200);
}

._Content_pf81s_17 {
  padding: var(--p-space-500) var(--p-space-400) 0;
}

._Footer_pf81s_21 {
  border-top: var(--p-border-width-025) solid var(--p-color-border-secondary);
  display: flex;
  justify-content: flex-end;
  padding: var(--p-space-300) var(--p-space-400);
}

._Action_pf81s_28 {
  font-weight: var(--p-font-weight-medium);
  border: none;
  background: none;
  cursor: pointer;
  padding: var(--p-space-100);
}

._Destructive_pf81s_36 {
  color: var(--p-color-text-critical);
  margin-right: var(--p-space-100);
}

._MinimizedView_15kll_1 {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: var(--p-shadow-600);
  border-radius: var(--p-border-radius-full);
  padding: var(--p-space-100);
  cursor: pointer;
  border: none;
}

._GlobalStatusPanelContainer_1dud5_1 {
  position: fixed;
  z-index: var(--p-z-index-10);
  right: var(--p-space-400);
  bottom: var(--p-space-400);
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity var(--p-motion-duration-200) var(--p-motion-ease-in-out);
}

._GlobalStatusPanelHidden_1dud5_12 {
  opacity: 0;
  top: 100vh;
}

._GlobalStatusPanel_1dud5_1 {
  max-width: 100%;
  width: 21.875rem;
}

.gui-Dewvc {
  --animation-speed: 0.85;
  --card-stack-transition-duration: calc(
    var(--p-motion-duration-300) * var(--animation-speed)
  );
  --card-content-fade-duration: calc(
    var(--p-motion-duration-200) * var(--animation-speed)
  );
  --entry-animation-delay: var(--p-motion-duration-200);
}

@media (prefers-reduced-motion: reduce) {
  .gui-Dewvc {
    --animation-speed: 0;
  }
}

.gui-Dewvc {
  position: relative;
  box-sizing: content-box;
}

.gui-Myone {
  background-color: var(--p-color-bg-surface);
  border-radius: var(--p-border-radius-200);
  padding-left: var(--p-space-200);
  padding-right: var(--p-space-200);
  container-type: inline-size;
  column-gap: var(--p-space-300);
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: -webkit-min-content 0fr;
  grid-template-rows: min-content 0fr;
  grid-template-areas: "status title image" "status content image";
  --transition-props: var(--p-motion-duration-150) var(--p-motion-ease-in-out);
  --transition: background-color var(--transition-props),
    grid-template-rows var(--transition-props);
}

.gui-Myone:hover {
  background-color: var(--p-color-bg-surface-hover);
}

.gui-Myone:active {
  background-color: var(--p-color-bg-surface-active);
}

.gui-LV08h,
.gui-SK9dW,
.gui-f0BIR,
.gui-6BtLv {
  background-color: var(--p-color-bg-surface-active);
  grid-template-rows: -webkit-min-content 1fr;
  grid-template-rows: min-content 1fr;
}

.gui-LV08h .gui-wVb1O,
.gui-SK9dW .gui-wVb1O,
.gui-f0BIR .gui-wVb1O,
.gui-6BtLv .gui-wVb1O {
  cursor: default;
  padding-bottom: var(--p-space-100);
}

.gui-LV08h .gui-TjTBp,
.gui-SK9dW .gui-TjTBp,
.gui-f0BIR .gui-TjTBp,
.gui-6BtLv .gui-TjTBp {
  opacity: 1;
}

.gui-LV08h .gui--wcCg,
.gui-SK9dW .gui--wcCg,
.gui-f0BIR .gui--wcCg,
.gui-6BtLv .gui--wcCg {
  padding-bottom: calc(var(--p-space-200));
}

.gui-LV08h:hover,
.gui-LV08h:active,
.gui-SK9dW:hover,
.gui-SK9dW:active,
.gui-f0BIR:hover,
.gui-f0BIR:active,
.gui-6BtLv:hover,
.gui-6BtLv:active {
  background-color: var(--p-color-bg-surface-active);
}

.gui-Nna23,
.gui-hYKmp {
  grid-template-rows: -webkit-min-content 0fr;
  grid-template-rows: min-content 0fr;
  background-color: var(--p-color-bg-surface);
}

.gui-Nna23 .gui-wVb1O,
.gui-hYKmp .gui-wVb1O {
  padding-bottom: var(--p-space-200);
}

.gui-Nna23 .gui--wcCg,
.gui-hYKmp .gui--wcCg {
  padding-bottom: 0;
}

.gui-Nna23 .gui-TjTBp,
.gui-hYKmp .gui-TjTBp {
  opacity: 0;
}

.gui-SK9dW,
.gui-Nna23 {
  transition: var(--transition);
}

.gui-SK9dW .gui-wVb1O,
.gui-Nna23 .gui-wVb1O {
  transition: padding-bottom var(--transition-props);
}

.gui-SK9dW .gui--wcCg,
.gui-Nna23 .gui--wcCg {
  transition: padding-bottom var(--transition-props);
  overflow: hidden;
}

.gui-SK9dW .gui-TjTBp,
.gui-Nna23 .gui-TjTBp {
  transition: opacity var(--transition-props);
}

.gui-wVb1O {
  cursor: pointer;
  vertical-align: middle;
  background: none;
  padding: 0;
  padding-top: var(--p-space-200);
  padding-bottom: var(--p-space-200);
  text-align: left;
  border: none;
  grid-area: title;
  color: var(--p-color-text);
}

.gui-DE27I {
  padding-top: var(--p-space-200);
  padding-bottom: var(--p-space-200);
  grid-area: status;
}

.gui--wcCg {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-area: content;
  align-items: flex-start;
  gap: var(--p-space-300);
}

.gui-TjTBp {
  flex-shrink: 0;
  padding-right: var(--p-space-200);
  padding-left: calc(var(--p-width-600) * 3);
  display: none;
  align-items: center;
  grid-area: image;
  overflow: hidden;
  opacity: 0;
}

@media (width >= 990px) {
  .gui-TjTBp {
    display: flex;
  }
}

.gui-OkX83 {
  width: 10.25rem;
  height: 7.75rem;
  position: relative;
}

.gui-OkX83 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gui-OkX83:after {
  content: "";
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 100%;
  height: 20%;
  background: linear-gradient(
    0deg,
    var(--p-color-bg-surface-active),
    var(--p-color-bg-surface-active) 60%,
    var(--p-color-bg-surface-transparent) 100%
  );
}

.gui-BS1l3,
.gui-6Za99 {
  padding: var(--p-space-200);
}

.gui-6Za99 {
  padding-top: 0;
}

.gui-OEXJk {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: -webkit-min-content 0fr;
  grid-template-rows: min-content 0fr;
  --transition: grid-template-rows var(--p-motion-duration-150)
    var(--p-motion-ease-in-out);
}

.gui-dtKUe {
  overflow: hidden;
}

.gui-8IEFs,
.gui-jHp-m,
.gui-LQtTF,
.gui-6Ud0w {
  grid-template-rows: -webkit-min-content 1fr;
  grid-template-rows: min-content 1fr;
}

.gui-SKWVq,
.gui-T201v {
  grid-template-rows: -webkit-min-content 0fr;
  grid-template-rows: min-content 0fr;
}

.gui-jHp-m,
.gui-SKWVq {
  transition: var(--transition);
}

.gui-ilkg8 {
  width: var(--p-width-500);
  height: var(--p-height-500);
}

.gui-NSCuO {
  cursor: pointer;
  vertical-align: middle;
  background: none;
  padding: 0;
  border: none;
  display: flex;
}

.gui-NSCuO[aria-disabled="true"] {
  cursor: default;
  pointer-events: none;
}

.gui-3kC8T {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: var(--p-space-300);
  background-color: var(--p-color-bg-surface-secondary);
  border-top: 0.0625rem solid var(--p-color-border);
}

.gui-QbIYT {
  opacity: 0;
  margin-bottom: var(--p-space-600);
  padding: 0 var(--p-space-150);
  animation: gui-GvuYY var(--card-stack-transition-duration)
    var(--p-motion-ease-in-out) var(--entry-animation-delay);
  animation-fill-mode: forwards;
}

.gui-QbIYT > h2 {
  margin-bottom: var(--p-space-100);
}

@keyframes gui-GvuYY {
  0% {
    transform: translateY(0.625rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.gui-zP6Uc {
  position: relative;
  --xs-front-card-width: 335;
  --xs-middle-card-width: 271;
  --xs-back-card-width: 207;
  --sm-front-card-width: 450;
  --sm-middle-card-width: 386;
  --sm-back-card-width: 322;
  --md-front-card-width: 752;
  --md-middle-card-width: 688;
  --md-back-card-width: 624;
}

.gui-jVnZx {
  position: absolute;
  width: 100%;
  margin-top: 0;
  transform-origin: top;
  transition: all var(--card-stack-transition-duration) ease-out,
    z-index var(--p-motion-duration-0);
}

@media (max-width: 30.6225em) {
  .gui-jVnZx {
    padding-bottom: var(--p-space-3200);
  }
}

.gui-jVnZx.gui-lS8i6.gui-oDrKF {
  transition: all var(--card-stack-transition-duration) ease-out,
    opacity calc(var(--card-stack-transition-duration) / 2) ease-out
      calc(var(--card-stack-transition-duration) / 2);
}

.gui-jVnZx.gui-dteNT.gui-9JIhy {
  transition: opacity calc(var(--card-stack-transition-duration) / 2),
    all var(--card-stack-transition-duration) ease-out;
}

.gui-jVnZx.gui-NDwf4 {
  --top-margin: 0.75rem;
  margin-top: var(--top-margin);
}

.gui-jVnZx.gui-dgv7p {
  --top-margin: 1.75rem;
  margin-top: var(--top-margin);
}

.gui-jVnZx.gui--MjH- {
  transform: scale(
    calc(var(--xs-middle-card-width) / var(--xs-front-card-width))
  );
}

@media (min-width: 30.625em) {
  .gui-jVnZx.gui--MjH- {
    transform: scale(
      calc(var(--sm-middle-card-width) / var(--sm-front-card-width))
    );
  }
}

@media (min-width: 48em) {
  .gui-jVnZx.gui--MjH- {
    transform: scale(
      calc(var(--md-middle-card-width) / var(--md-front-card-width))
    );
  }
}

.gui-jVnZx.gui---4R1,
.gui-jVnZx.gui-JYCPo {
  transform: scale(
    calc(var(--xs-back-card-width) / var(--xs-front-card-width))
  );
}

@media (min-width: 30.625em) {
  .gui-jVnZx.gui---4R1,
  .gui-jVnZx.gui-JYCPo {
    transform: scale(
      calc(var(--sm-back-card-width) / var(--sm-front-card-width))
    );
  }
}

@media (min-width: 48em) {
  .gui-jVnZx.gui---4R1,
  .gui-jVnZx.gui-JYCPo {
    transform: scale(
      calc(var(--md-back-card-width) / var(--md-front-card-width))
    );
  }
}

.gui-jVnZx.gui-oDrKF {
  margin-top: var(--p-space-2400);
  opacity: 0;
  pointer-events: none;
}

.gui-jVnZx.gui-oDrKF .gui-QBSpf {
  opacity: 0;
  pointer-events: none;
}

.gui-I7-H0 {
  padding: var(--p-space-600) var(--p-space-500) var(--p-space-500);
  border-radius: var(--p-border-radius-300);
  background: var(--p-color-bg-surface);
  outline: none;
}

@media (min-width: 48em) {
  .gui-I7-H0 {
    padding: var(--p-space-800);
  }
}

.gui-lS8i6 .gui-I7-H0,
.gui-dteNT .gui-I7-H0 {
  transition: box-shadow var(--card-stack-transition-duration) ease-out;
}

.gui-LKZm4 .gui-I7-H0 {
  box-shadow: 0 1.75rem 0.9375rem -2rem #43415740,
    0 -2rem 0.625rem -2rem #4341571a, 0 -0.625rem 0.0625rem -0.75rem #4341571a;
}

.gui-NDwf4 .gui-I7-H0 {
  box-shadow: 0 1.75rem 2.5rem -2rem #43415740, 0 -2rem 1.25rem -2rem #43415733,
    0 -2rem 0.0625rem -2rem #43415733;
}

.gui-dgv7p .gui-I7-H0 {
  box-shadow: 0 2rem 1.875rem -1.5rem #43415740,
    0 -2rem 1.875rem -2rem #43415733, 0 -2rem 0.0625rem -2rem #43415733;
}

.gui-XUTl2 {
  height: 10.25rem;
  opacity: 0;
  visibility: hidden;
}

.gui-XUTl2.gui-9JIhy {
  opacity: 1;
  visibility: visible;
}

.gui-XUTl2.gui-lS8i6,
.gui-XUTl2.gui-dteNT {
  overflow: hidden;
}

.gui-XUTl2.gui-lS8i6 {
  transition: height var(--card-stack-transition-duration) ease-out,
    opacity var(--card-content-fade-duration) ease-out
      calc(
        var(--card-stack-transition-duration) +
          var(--card-content-fade-duration) / 2
      );
}

.gui-XUTl2.gui-lS8i6.gui-oDrKF {
  transition: all var(--p-motion-duration-0)
    var(--card-stack-transition-duration);
}

.gui-XUTl2.gui-dteNT {
  transition: height var(--card-stack-transition-duration) ease-out,
    opacity var(--p-motion-duration-0) var(--card-stack-transition-duration),
    visibility var(--p-motion-duration-0) var(--card-stack-transition-duration);
}

.gui-XUTl2.gui-dteNT.gui-9JIhy {
  transition: opacity var(--card-content-fade-duration) ease-out
    var(--card-stack-transition-duration);
}

.gui-iKjrx {
  display: flex;
  justify-content: space-between;
}

.gui-QBSpf {
  margin-top: var(--p-space-500);
  visibility: hidden;
  animation: gui-BxBvj var(--card-stack-transition-duration)
    var(--p-motion-ease-in-out)
    calc(var(--card-stack-transition-duration) * 1.5);
  animation-fill-mode: forwards;
}

.gui-QBSpf.gui-lBYaG {
  animation: gui-odr-l var(--card-stack-transition-duration)
    var(--p-motion-ease-in-out);
}

.gui-mUMp7 {
  opacity: 0;
}

.gui-mUMp7.gui-roS2W {
  opacity: 1;
}

.gui-mUMp7.gui-2XP5C {
  visibility: hidden;
  animation: gui-BxBvj var(--card-stack-transition-duration)
    var(--p-motion-ease-in-out) var(--entry-animation-delay);
  animation-fill-mode: forwards;
}

.gui-2XP5C .gui-dgv7p,
.gui-2XP5C .gui-NDwf4 {
  animation: gui-tM9KM var(--card-stack-transition-duration)
    var(--p-motion-ease-in-out) var(--entry-animation-delay);
}

@keyframes gui-BxBvj {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes gui-odr-l {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
  }
}

@keyframes gui-tM9KM {
  0% {
    margin-top: 0;
  }

  to {
    margin-top: var(--top-margin);
  }
}

.gui-hvdeg a:link,
.gui-hvdeg a:active,
.gui-hvdeg a:visited {
  color: var(--p-color-text-link);
  text-decoration: none;
}

.gui-hvdeg a:hover {
  cursor: pointer;
  color: var(--p-color-text-link-hover);
  text-decoration: underline;
}

.gui-asO1B {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: -webkit-min-content 0fr;
  grid-template-rows: min-content 0fr;
  border-top: var(--p-border-width-025) solid var(--p-color-border);
  --transition: grid-template-rows var(--p-motion-duration-150)
    var(--p-motion-ease-in-out);
}

.gui-mqt-0 .gui-lxY41 {
  border-bottom: var(--p-border-width-025) solid var(--p-color-border);
}

.gui-zw78r {
  overflow: hidden;
}

.gui-BC4wf,
.gui-89Taj,
.gui-YI8MI,
.gui-mPGlG {
  grid-template-rows: -webkit-min-content 1fr;
  grid-template-rows: min-content 1fr;
}

.gui-xvIDx,
.gui-xoEMM {
  grid-template-rows: -webkit-min-content 0fr;
  grid-template-rows: min-content 0fr;
}

.gui-89Taj,
.gui-xvIDx {
  transition: var(--transition);
}

.gui-nUVHv {
  margin-left: auto;
}

.gui-TmOq5 {
  color: var(--p-color-text);
  border: none;
  border-radius: var(--p-border-radius-200);
  background: none;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--p-space-150) var(--p-space-300);
  font: inherit;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  font-size: var(--p-font-size-325);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-font-line-height-500);
}

@media (min-width: 48em) {
  .gui-TmOq5 {
    font-size: var(--p-font-size-300);
    line-height: var(--p-font-line-height-400);
  }
}

.gui-TmOq5.gui-GBT8Z {
  color: var(--p-color-text-disabled);
  box-shadow: none;
  border-color: transparent;
}

.gui-TmOq5.gui-GBT8Z svg {
  fill: var(--p-color-text-disabled);
}

.gui-TmOq5:hover {
  background: var(--p-color-bg-fill-transparent-hover);
  border-color: transparent;
}

.gui-TmOq5:active {
  background: var(--p-color-bg-fill-transparent-active);
  border-color: transparent;
}

.gui-TmOq5:focus-visible {
  background: var(--p-color-bg-fill-transparent-hover);
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-025);
}

.gui-lQMIe {
  margin: calc(var(--p-space-050) * -1) calc((var(--p-space-100)) * -1)
    calc(var(--p-space-050) * -1) var(--p-space-050);
}

.gui-GBT8Z {
  cursor: default;
  pointer-events: none;
}

.gui-bJ2nv {
  margin-top: calc(var(--p-space-100) * -1);
  margin-bottom: calc(var(--p-space-100) * -1);
}

.gui-p31AC path {
  fill: var(--p-color-icon-secondary);
}

.gui-p31AC circle {
  display: none;
  stroke: var(--p-color-icon-secondary);
}

.gui-p31AC:hover circle {
  display: block;
}

.gui-p31AC:hover path {
  display: none;
}

.gui-p31AC:active circle {
  fill: var(--p-color-bg-fill-tertiary-active);
}

.gui-6G93w {
  position: relative;
}

.gui-Jerij {
  padding: 0 var(--p-space-100);
  margin-bottom: var(--p-space-500);
}

.gui-hqAVM {
  width: var(--p-width-500);
  height: var(--p-height-500);
  opacity: 0;
  transform: scale(0) rotate(-120deg);
}

.gui-hqAVM circle {
  fill: var(--p-color-icon-brand);
}

.gui-hqAVM path {
  fill: var(--p-color-icon-inverse);
}

.gui-hqAVM:hover path,
.gui-hqAVM:active path {
  fill: var(--p-color-icon-secondary);
}

.gui-qdGuF {
  opacity: 0;
  transform: scale(0) rotate(-120deg);
}

.gui-U3NXH {
  opacity: 1;
  transition: opacity var(--p-motion-duration-500) var(--p-motion-ease),
    transform var(--p-motion-duration-500) var(--p-motion-ease);
  transform: scale(1) rotate(0);
}

.gui-gX-rd,
.gui-8LaUx {
  opacity: 1;
  transform: scale(1) rotate(0);
}

.gui-jmovd {
  font-size: var(--p-font-size-400);
  line-height: var(--p-font-line-height-600);
  font-weight: var(--p-font-weight-semibold);
}

.gui-9Dmuy {
  padding-bottom: calc(var(--p-space-400) / 2);
}

.gui-AhO5a > a,
.gui-AhO5a > a:hover,
.gui-AhO5a > a:focus,
.gui-AhO5a > a:active {
  border-color: var(--p-color-border-caution);
}

[class*="Polaris-Banner--toneWarning"] {
  background-color: #fcf0d4;
}

.gui-A9LVR {
  position: relative;
}

.gui-A9LVR input {
  padding-left: var(--p-space-800);
  padding-right: var(--p-space-1200);
}

.gui-A9LVR:before,
.gui-A9LVR:after {
  position: absolute;
  top: 2rem;
  z-index: 100;
  color: var(--p-color-text-secondary);
}

.gui-A9LVR:before {
  content: "$";
  left: 0.9375rem;
}

.gui-A9LVR:after {
  content: "USD";
  right: 0.9375rem;
  text-align: right;
}

.gui-9lvr7 {
  margin-bottom: var(--p-space-400);
  container-type: inline-size;
  --focus-ring-spacing: calc(var(--p-border-width-050) + var(--p-space-025));
}

.gui-P1ABK {
  overflow-y: auto;
  margin: calc(var(--focus-ring-spacing) * -1);
}

.gui-SbYwU {
  list-style-type: none;
  padding: var(--focus-ring-spacing);
  margin: 0;
  display: grid;
  gap: var(--p-space-200);
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
}

@media (min-width: 48em) {
  .gui-SbYwU {
    grid-template-columns: repeat(2, 1fr);
  }
}

.gui-x5UqY {
  width: 4.5rem;
}

.gui-x5UqY [class^="Polaris-ProgressBar"] {
  height: var(--p-space-100);
  --pc-progress-bar-indicator: var(--p-color-text);
}

.gui-GWaGN,
.gui-UOaTw {
  display: flex;
}

.gui-Cf6We .gui-GWaGN,
.gui-Cf6We .gui-UOaTw {
  border: var(--p-border-width-025) solid var(--p-color-border);
  border-radius: var(--p-border-radius-200);
}

.gui-Cf6We .gui-GWaGN {
  padding: 0 var(--p-space-200) 0 var(--p-space-050);
}

.gui-Cf6We .gui-UOaTw {
  padding: 0 var(--p-space-200);
}

.gui-wv9s1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  margin: 0;
  width: 100%;
  padding: var(--p-space-300) calc(var(--p-space-300) + var(--p-space-050))
    var(--p-space-300) var(--p-space-400);
  background: var(--p-color-bg-surface-secondary);
}

.gui-wv9s1:hover,
.gui-wv9s1:focus {
  background: var(--p-color-bg-surface-secondary-hover);
}

.gui-wv9s1:focus {
  background: var(--p-color-bg-surface-secondary-active);
}

.gui-ocKeU {
  color: var(--p-color-text);
  flex: 1 1;
  text-align: left;
}

.gui-kzW9p {
  display: flex;
}

.gui-79Pjj {
  position: relative;
}

.gui-DwuT1 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--p-space-800);
  background: linear-gradient(to bottom, #ffffff1a, #fff);
  pointer-events: none;
}

.gui-EsNdu {
  height: 100%;
  display: flex;
  border-radius: var(--p-border-radius-300);
  background-color: var(--p-color-bg-surface-secondary);
}

.gui-EsNdu:hover {
  background-color: var(--p-color-bg-surface-secondary-hover);
}

.gui-EsNdu:active {
  background-color: var(--p-color-bg-surface-secondary-active);
}

.gui-EsNdu.gui--zvNA {
  background-color: var(--p-color-bg-fill-brand-selected);
}

@media (hover: hover) {
  .gui-EsNdu.gui--zvNA:hover {
    background-color: var(--p-color-bg-fill-brand-hover);
  }
}

.gui-EsNdu > label {
  width: 100%;
  padding: var(--p-space-400);
  gap: var(--p-space-300);
  flex-direction: row-reverse;
}

.gui-EsNdu > label > span:nth-child(1) {
  margin-right: 0;
}

.gui-EsNdu > label > span:nth-child(2) {
  flex: 1 1;
}

.gui-EsNdu.gui-D0xC4 > label {
  padding: var(--p-space-300) var(--p-space-400);
}

.gui-EsNdu.gui-kwQ2z > label {
  padding-top: var(--p-space-150);
  padding-bottom: var(--p-space-150);
}

.gui-FmBM4 {
  width: 100%;
  position: relative;
}

.gui-FmBM4.gui-09x9c {
  height: 0;
  transform: scale(0);
}

.gui-BFU3y {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  color: var(--p-color-text-brand-on-bg-fill);
  width: 200%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.gui-BFU3y::placeholder {
  color: var(--p-color-text-inverse-secondary);
}

.gui-BFU3y.gui-09x9c {
  visibility: hidden;
  pointer-events: none;
}

.gui-Z-JIR {
  align-items: center;
  display: flex;
  gap: var(--p-space-300);
}

.gui-j397i {
  position: relative;
  display: flex;
  width: var(--p-width-800);
}

.gui-OYZvQ {
  position: absolute;
  top: calc(var(--p-space-200) * -1);
  left: 35%;
}

.gui-OYZvQ span {
  background-color: var(--p-color-bg-fill-brand);
  color: var(--p-color-text-brand-on-bg-fill);
  font-size: var(--p-font-size-300);
  font-weight: var(--p-font-weight-regular);
}

.gui-U-UVn {
  color: var(--p-color-text);
  font-weight: var(--p-font-weight-medium);
}

.gui-U-UVn.gui-2-T1Z {
  transition: var(--p-motion-duration-200) ease;
}

.gui-U-UVn.gui-ekRHx {
  font-size: var(--p-font-size-275);
  line-height: var(--p-font-line-height-300);
}

.gui-4aTem {
  color: var(--p-color-text-secondary);
}

.gui-y7K7n {
  height: var(--p-width-800);
  width: var(--p-height-800);
  object-fit: contain;
}

.gui-2rq9l .gui-y7K7n {
  filter: contrast(2) invert(1);
}

.gui-2rq9l .gui-U-UVn {
  color: var(--p-color-text-brand-on-bg-fill);
}

.gui-2rq9l .gui-4aTem {
  color: var(--p-color-text-inverse-secondary);
}

.gui-YMZBD {
  margin: var(--p-space-025);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gui-1gIm9 {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

.gui-b1fK0 {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: var(--p-border-radius-300);
  transition: border-color var(--p-motion-duration-100) var(--p-motion-ease-out);
}

.gui-1gIm9:focus-visible + .gui-b1fK0 {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-025);
}

.gui-FCkBI {
  position: relative;
  margin: var(--p-space-025);
}

.gui-FCkBI:has(.gui-zQ7bS:focus-visible) ~ .gui-QPoaK {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-025);
}

.gui-jXTGJ .gui-bm1Bm {
  border-width: 0;
  box-shadow: inset 0 0 0 var(--p-border-width-0165) var(--p-color-input-border);
  transition: border-color var(--p-motion-duration-100) var(--p-motion-ease-out),
    border-width var(--p-motion-duration-100) var(--p-motion-ease-out),
    box-shadow var(--p-motion-duration-100) var(--p-motion-ease-out);
  transform: translateZ(0);
}

.gui-jXTGJ:hover .gui-bm1Bm {
  border-color: var(--p-color-input-border-hover);
  box-shadow: inset 0 0 0 var(--p-border-width-0165)
    var(--p-color-input-border-hover);
  background-color: var(--p-color-input-bg-surface-hover);
}

.gui-jXTGJ:active .gui-bm1Bm,
.gui-jXTGJ:checked .gui-bm1Bm {
  border-color: var(--p-color-bg-fill-brand);
  border-width: 0;
  box-shadow: inset 0 0 0 var(--p-space-050) var(--p-color-bg-fill-brand);
}

.gui-jXTGJ:hover .gui-zQ7bS:checked + .gui-bm1Bm {
  box-shadow: inset 0 0 0 var(--p-space-800) var(--p-color-bg-fill-brand-hover);
}

.gui-zQ7bS {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

.gui-zQ7bS:checked + .gui-bm1Bm,
.gui-zQ7bS.gui-DVsyc + .gui-bm1Bm {
  border-color: var(--p-color-bg-fill-brand);
  background-color: var(--p-color-bg-fill-brand-selected);
  box-shadow: inset 0 0 0 var(--p-space-800)
    var(--p-color-bg-fill-brand-selected);
}

.gui-zQ7bS:checked + .gui-bm1Bm:before,
.gui-zQ7bS.gui-DVsyc + .gui-bm1Bm:before {
  opacity: 1;
  transform: scale(1);
}

@media (-ms-high-contrast: active) {
  .gui-zQ7bS:checked + .gui-bm1Bm:before,
  .gui-zQ7bS.gui-DVsyc + .gui-bm1Bm:before {
    border: var(--p-border-width-050) solid windowText;
  }
}

.gui-zQ7bS:checked ~ .gui-KgFvP,
.gui-zQ7bS.gui-DVsyc ~ .gui-KgFvP {
  transition: opacity var(--p-motion-duration-150) var(--p-motion-ease-out),
    transform var(--p-motion-duration-150) var(--p-motion-ease-out);
  opacity: 1;
}

.gui-zQ7bS:checked ~ .gui-KgFvP svg,
.gui-zQ7bS.gui-DVsyc ~ .gui-KgFvP svg {
  fill: var(--p-color-text-brand-on-bg-fill);
}

.gui-zQ7bS:checked ~ .gui-KgFvP.gui-XqWnW,
.gui-zQ7bS.gui-DVsyc ~ .gui-KgFvP.gui-XqWnW {
  transition: initial;
}

.gui-zQ7bS:disabled + .gui-bm1Bm {
  border-color: transparent;
  background-color: var(--p-color-checkbox-bg-surface-disabled);
  box-shadow: none;
}

.gui-zQ7bS:disabled + .gui-bm1Bm:before {
  background-color: transparent;
}

.gui-zQ7bS:disabled + .gui-bm1Bm:hover {
  cursor: default;
}

.gui-zQ7bS:disabled ~ .gui-KgFvP svg {
  color: var(--p-color-checkbox-icon-disabled);
}

.gui-zQ7bS:disabled:checked + .gui-bm1Bm,
.gui-zQ7bS:disabled.gui-DVsyc + .gui-bm1Bm {
  background-color: var(--p-color-checkbox-bg-surface-disabled);
}

.gui-zQ7bS:disabled:checked + .gui-bm1Bm:before,
.gui-zQ7bS:disabled.gui-DVsyc + .gui-bm1Bm:before {
  background-color: transparent;
}

.gui-bm1Bm {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--p-color-bg-surface);
  border: var(--p-border-width-0165) solid var(--p-color-input-border);
  border-radius: var(--p-border-radius-100);
}

.gui-bm1Bm.gui-gwLTT,
.gui-bm1Bm:hover {
  cursor: pointer;
  border-color: var(--p-color-input-border-hover);
}

.gui-QPoaK {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: var(--p-border-radius-300);
  transition: border-color var(--p-motion-duration-100) var(--p-motion-ease-out);
}

.gui-KgFvP {
  position: absolute;
  transform-origin: 50% 50%;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--p-motion-duration-100) var(--p-motion-ease-out),
    transform var(--p-motion-duration-100) var(--p-motion-ease-out);
  inset: calc(var(--p-space-050) * -1) calc(var(--p-space-050) * -1)
    calc(var(--p-space-050) * -1) calc(var(--p-space-050) * -1);
}

.gui-KgFvP.gui-XqWnW {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: var(--p-space-050);
  transition: initial;
}

.gui-KgFvP svg {
  color: var(--p-color-text-brand-on-bg-fill);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (-ms-high-contrast: active) {
  .gui-KgFvP {
    fill: windowText;
  }
}

.gui-XqWnW svg > path {
  stroke-dasharray: 2;
  stroke-dashoffset: 2;
}

.gui-XqWnW svg > path.gui-HiiOo {
  animation-name: gui-pEljC;
  animation-duration: var(--p-motion-duration-150);
  animation-fill-mode: forwards;
  animation-timing-function: var(--p-motion-linear);
  animation-direction: normal;
  animation-iteration-count: 1;
  opacity: 1;
}

@keyframes gui-pEljC {
  0% {
    stroke-dashoffset: 2;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.gui-B5r1K {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-block: var(--p-space-100) var(--p-space-100);
  -webkit-tap-highlight-color: transparent;
}

.gui-PnAqj > .gui-CdE-Z {
  position: absolute;
  top: 0;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

.gui-PnAqj .gui-OYWN- {
  margin-top: 0;
  margin-right: 0;
}

.gui-U60ZX {
  cursor: default;
}

.gui-U60ZX > .gui-CdE-Z {
  color: var(--p-color-text-disabled);
}

.gui-U60ZX > .gui-CdE-Z:hover {
  cursor: default;
}

@media (-ms-high-contrast: active) {
  .gui-U60ZX > .gui-CdE-Z {
    color: grayText;
  }
}

.gui-U60ZX + .gui-j--Cv {
  color: var(--p-color-text-disabled);
}

.gui-OYWN- {
  display: flex;
  flex: 0 0 auto;
  align-items: stretch;
  margin-right: var(--p-space-200);
  height: var(--p-height-500);
  width: var(--p-width-500);
}

.gui-OYWN- > * {
  width: 100%;
}

.gui-CdE-Z {
  -webkit-tap-highlight-color: transparent;
}

.gui-CdE-Z:hover {
  cursor: pointer;
}

.gui-j--Cv {
  padding-left: var(--p-space-200);
}

.gui-UonTK {
  margin-bottom: var(--p-space-100);
}

._UnsupportedBrowser_my03m_1 {
  padding: var(--p-space-500);
}

._OuterContainer_19gip_19 {
  transition: height var(--p-motion-duration-200) var(--p-motion-ease);
  height: 100vh;
}

._OuterContainer_19gip_19 [class^="Polaris-Frame__Navigation"] {
  transition: height var(--p-motion-duration-200) var(--p-motion-ease);
}

._OuterContainer_19gip_19._DevToolVisible_19gip_28 {
  overflow: auto;
  height: calc(100vh - 18.75rem);
}

._OuterContainer_19gip_19._DevToolVisible_19gip_28
  [class^="Polaris-Frame__Navigation"] {
  height: calc(100% - 22.25rem);
}

._Hidden_19gip_41 {
  visibility: hidden;
}

._DevTool_19gip_28 {
  position: relative;
  z-index: 519 !important;
  display: none;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 18.75rem;
  border-radius: 0.625rem 0.625rem 0 0;
  color: #fff;
  font-family: var(--p-font-family-mono);
  background: #38393a;
}

._DevTool_19gip_28._show_19gip_63 {
  display: flex;
}

._DevTool_19gip_28 h1 {
  font-weight: var(--p-font-weight-bold);
}

._DevTool_19gip_28 main {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

._DevTool_19gip_28 svg {
  width: 1.25rem;
}

._Header_19gip_82 {
  border-bottom: var(--p-border-width-025) solid #202123;
  display: flex;
  justify-content: space-between;
  padding: 0.9375rem var(--p-space-500) 0.625rem;
}

._Header_19gip_82 svg {
  fill: #fff;
}

._Header_19gip_82 ._HeaderLeft_19gip_95 {
  display: flex;
  height: auto;
}

._Cancel_19gip_101 {
  color: inherit;
  background: transparent;
  border: none;
  cursor: pointer;
}

._SideBar_19gip_108 {
  background: #202123;
  width: 15rem;
}

._SideBar_19gip_108 ul {
  list-style: none;
  padding: 0;
}

._SideBar_19gip_108 svg {
  fill: #fff;
}

._MenuItem_19gip_124 {
  position: relative;
  margin: 0.3125rem 0.625rem;
  padding: 0.3125rem 0.625rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
}

._MenuItem_19gip_124:focus,
._MenuItem_19gip_124:hover {
  background: #38393a;
  color: #36a3ff;
  cursor: pointer;
}

._MenuItem_19gip_124:focus:before,
._MenuItem_19gip_124:hover:before {
  content: "";
  position: absolute;
  left: -0.625rem;
  width: 0.1875rem;
  height: 1.875rem;
  background: #36a3ff;
  border-radius: var(--p-border-radius-100);
  margin-right: 0.625rem;
}

._MenuItem_19gip_124:focus svg,
._MenuItem_19gip_124:hover svg {
  fill: #36a3ff;
}

._ExtensionList_19gip_163 {
  width: 100%;
  overflow: auto;
  flex: 1 1;
}

._ExtensionList_19gip_163 table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
}

._ExtensionList_19gip_163 hr {
  color: #a7aaad;
}

._ExtensionList_19gip_163 tr {
  line-height: 1.875rem;
  border-bottom: var(--p-border-width-025) solid #202123;
}

._ExtensionList_19gip_163 th {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0.625rem;
  background: #38393a;
}

._ExtensionList_19gip_163 svg {
  fill: #a7aaad;
}

._UIExtensionsDevToolOverrides_19gip_203 {
  overflow: hidden;
}

._UIExtensionsDevToolOverrides_19gip_203
  [class^="Polaris-Modal-Dialog__Container"] {
  bottom: 18.75rem;
}

._UIExtensionsDevToolOverrides_19gip_203
  [class^="Polaris-Modal-Dialog__Modal"] {
  max-height: calc(100vh - 22.5rem);
}

._UIExtensionsDevToolOverrides_19gip_203
  [class*="UIExtensions-Dialog-Container"] {
  bottom: 18.75rem;
  z-index: 518 !important;
}

._UIExtensionsDevToolOverrides_19gip_203 [class*="UIExtensions-AppOverlay"] {
  position: fixed !important;
  height: 100%;
}

._UIExtensionsDevToolOverrides_19gip_203 [class^="Polaris-Backdrop"] {
  bottom: 18.75rem;
}

._UIExtensionsDevToolOverrides_19gip_203 [class^="Polaris-PositionedOverlay"] {
  z-index: var(--p-z-index-10);
}

._UIExtensionsDevToolOverrides_19gip_203 [class^="Polaris-Frame"] {
  min-height: auto;
}

._UIExtensionsDevToolOverrides_19gip_203 [class^="Polaris-Frame__Main"] {
  min-height: calc(100vh - 18.75rem);
}

._Iframe_19gip_254 {
  position: relative;
  border: none;
  height: 100%;
  width: 100%;
}

._DevConsoleMessage_19gip_261 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

._DevConsoleClose_19gip_268 {
  position: absolute;
  right: 0;
  padding: 0.9375rem var(--p-space-500) 0.625rem;
}
