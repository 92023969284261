._CloseButton_1n0yn_1 {
  cursor: pointer;
  pointer-events: all;
}

._FormulaVariable_zq6ol_10 {
  color: #308023;
  margin-right: var(--p-space-100);
}

._FormulaResult_zq6ol_16 {
  color: #0b608f;
  margin-right: var(--p-space-100);
}

._FormulaOperatorOrNumber_zq6ol_22 {
  color: #8f5da6;
  margin-right: var(--p-space-100);
}

._FormulaBracketOrEquals_zq6ol_28 {
  color: #616a75;
  margin-right: var(--p-space-100);
}

._Formula_zq6ol_10 {
  font-family: var(--p-font-family-mono);
  font-size: var(--p-font-size-300);
  font-weight: var(--p-font-weight-regular);
  display: flex;
  flex-wrap: wrap;
}

._MetricLayout_zn76l_1 {
  --header-height: 1.25rem;
  --flex-gap: var(--p-space-400);
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

._CardWrapper_zn76l_11 {
  height: 100%;
}

._CardWrapper_zn76l_11 > [class*="Polaris-ShadowBevel"] {
  height: 100%;
}

._CardWrapper_zn76l_11
  > [class*="Polaris-ShadowBevel"]
  > [class="Polaris-Box"] {
  background: var(--p-color-bg-surface);
  height: 100%;
}

._HeaderButton_zn76l_25 {
  display: flex;
  padding: var(--p-space-400) var(--p-space-400) var(--p-space-200);
}

._HeaderButton_zn76l_25:hover {
  background: var(--p-color-bg-surface-hover);
}

._Header_zn76l_25 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: var(--header-height);
  width: 100%;
}

._Content_zn76l_42 {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: var(--flex-gap);
  flex-grow: 1;
  min-height: 0;
}

._CohortsCard_zn76l_51 {
  justify-content: space-between;
}

._Title_zn76l_55 {
  flex-grow: 1;
  min-width: 0;
}

._Title_zn76l_55._HasPrimaryAction_zn76l_60 {
  margin-right: var(--p-space-600);
}

._PrimaryAction_zn76l_64 {
  position: absolute;
  top: 0;
  right: 0;
}

._Compact_zn76l_70 ._Content_zn76l_42 {
  flex-direction: row;
  gap: var(--flex-gap);
  justify-content: space-between;
}

._Button_zn76l_78 {
  background: unset;
  border: unset;
  text-align: unset;
  color: unset;
  text-decoration: unset;
  display: block;
  height: 100%;
}

._CompactButtonHover_zn76l_88 :hover > [class="Polaris-Box"] {
  background: var(--p-color-bg-surface-hover);
}

._SVG_1ou8g_150 {
  display: block;
  font-feature-settings: normal;
  outline: none;
}

.Polaris-ShadowBevel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 32;
  box-shadow: 0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset,
    -0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset,
    0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, 0.17) inset,
    0rem 0.0625rem 0rem 0rem rgba(204, 204, 204, 0.5) inset;
  border-radius: var(--pc-shadow-bevel-border-radius);
  pointer-events: none;
  mix-blend-mode: luminosity;
}

._ChartContainer_e4we5_150 {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
  font-size: 0.75rem;
}

._ChartDimensions_qgx6z_1 {
  height: 100%;
  width: 100%;
}

._ChartDimensions_qgx6z_1 {
  min-height: 100px !important;
}

._HoverContainer_3iemw_31 ._ChartDimensions_qgx6z_1 {
  min-height: 20px !important;
}

._HoverContainer_3iemw_31 ._ChartContainer_e4we5_150 {
  background-color: transparent !important;
}

/* 
  .Polaris-ShadowBevel::before {
    content: initial;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--pc-shadow-bevel-z-index);
    box-shadow: var(--p-shadow-bevel-100);
    border-radius: var(--pc-shadow-bevel-border-radius);
    pointer-events: none;
    mix-blend-mode: luminosity;
  } */
