:root {
  --image-dimensions: 15rem;
  --narrow-image-dimensions: 11.25rem;
  --padding: 1.25rem;
  --column-gap: 1.25rem;
  --column-width: var(--image-dimensions);
  --two-column-width: calc(var(--column-width) * 2);
  --width-when-column-wraps: calc(
    var(--two-column-width) + var(--column-gap) + var(--padding) * 2 - 0.0625rem
  );
}

._LogoWrapper_1yrt4_25 {
  z-index: 1;
  cursor: pointer;
  white-space: nowrap;
}

._ShopifyLogoWrapper_1yrt4_18 {
  display: flex;
  align-items: center;
  padding: 0 var(--p-space-500);
  height: 3.5rem;
}

._ShopifyLogo_1yrt4_18._mounted_1yrt4_47 {
  filter: grayscale(0%);
}
._ShopifyLogo_1yrt4_18 {
  flex-shrink: 0;
  cursor: pointer;
  transition: transform var(--p-motion-duration-200),
    filter var(--p-motion-duration-300);
  filter: grayscale(100%);
  width: 1.3125rem;
  height: 1.5rem;
}

._ShopifyWordmark_1yrt4_52 {
  flex-shrink: 0;
  margin-top: 0.5625rem;
  width: 3.875rem;
  margin-left: 0.1875rem;
  position: relative;
}

._NotificationsList_k2zqk_1 {
  margin: 0;
  padding: 0;
}

._NotificationsListTitle_k2zqk_6 {
  padding: var(--p-space-200) 0;
  display: flex;
  align-items: center;
}

._NotificationsListTitle_k2zqk_6:before,
._NotificationsListTitle_k2zqk_6:after {
  content: "";
  flex: 1 1;
  border-bottom: 0.0625rem solid var(--p-color-border-secondary);
}

._NotificationsListTitle_k2zqk_6:before {
  margin-right: var(--p-space-200);
}

._NotificationsListTitle_k2zqk_6:after {
  margin-left: var(--p-space-200);
}

._NotificationItemButton_k2zqk_27 {
  height: var(--notification-button-size);
  width: var(--notification-button-size);
  padding: 0;
  background: none;
  border: 0;
  border-radius: var(--p-border-radius-100);
  pointer-events: none;
  opacity: 0;
}

@media (hover: none) and (pointer: coarse) {
  ._NotificationItemButton_k2zqk_27 {
    opacity: 1;
  }
}

._NotificationItemButton_k2zqk_27:focus {
  opacity: 1;
}

._NotificationItemButton_k2zqk_27._NotificationButtonClickable_k2zqk_46 {
  cursor: pointer;
  pointer-events: auto;
}

._NotificationHeadingButton_k2zqk_52 {
  height: var(--notification-button-size);
  width: var(--notification-button-size);
  padding: 0;
  background: none;
  border: 0;
  border-radius: var(--p-border-radius-100);
  pointer-events: none;
}

._NotificationHeadingButton_k2zqk_52:hover {
  background: var(--p-color-bg-surface-secondary-hover);
}

._NotificationHeadingButton_k2zqk_52._NotificationButtonClickable_k2zqk_46 {
  cursor: pointer;
  pointer-events: auto;
}

._NotificationItem_k2zqk_27 {
  list-style: none;
}

._NotificationItem_k2zqk_27 [class^="Polaris-Link"],
._NotificationItem_k2zqk_27 [class^="Polaris-Link"]:hover,
._NotificationItem_k2zqk_27
  [class^="Polaris-Link"]:hover
  ._NotificationItemHeading_k2zqk_82 {
  color: var(--p-color-text);
}

._NotificationItem_k2zqk_27
  [class^="Polaris-Link"]:hover
  ._NotificationItemButton_k2zqk_27,
._NotificationItem_k2zqk_27
  [class^="Polaris-Link"]:focus
  ._NotificationItemButton_k2zqk_27 {
  opacity: 1;
}

._NotificationItem_k2zqk_27 [class*=" Polaris-Link--removeUnderline"]:hover {
  text-decoration: none;
}

._NotificationItem_k2zqk_27
  [class*=" Polaris-Link--removeUnderline"]:hover
  ._NotificationItemLink_k2zqk_101 {
  background: var(--p-color-bg-surface-secondary-hover);
}

._NotificationItem_k2zqk_27 + ._NotificationItem_k2zqk_27 {
  border-top: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._NotificationItemInner_k2zqk_112 {
  padding: 0.625rem var(--p-space-300) var(--p-space-400) var(--p-space-300);
}

._NotificationItemContent_k2zqk_117 {
  margin: calc(var(--p-space-100) * -1);
}

._NotificationIcon_k2zqk_121 {
  height: 1.75rem;
}

._CriticalIconContainer_k2zqk_125 {
  position: absolute;
  display: inline-block;
  height: var(--notification-critical-icon-size);
  width: var(--notification-critical-icon-size);
  vertical-align: middle;
  left: calc(
    var(--p-space-300) + var(--notification-read-indicator-size) / 2 - 0.625rem
  );
  margin: 0;
}

._CriticalIconContainer_k2zqk_125 [class*="Polaris-Icon--colorCritical"] svg {
  fill: var(--p-color-text-critical);
}

._NotificationItemHeading_k2zqk_82 {
  height: 1.75rem;
  display: flex;
  align-items: center;
}

._ReadIndicatorContainer_k2zqk_152 {
  margin-top: var(--p-space-200);
  padding-top: var(--p-space-050);
}

._ReadIndicator_k2zqk_152 {
  width: var(--notification-read-indicator-size);
  height: var(--notification-read-indicator-size);
  border-radius: 0.1875rem;
  background-color: var(--p-color-icon-emphasis);
}

._NotificationsInner_1gzjd_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--p-space-400);
  min-height: var(--p-space-1200);
}

._NotificationsFooter_kfmvb_1 {
  border-top: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._UnreadFilterButton_fsw2r_1 {
  height: 1.75rem;
  width: 1.75rem;
  padding: 0;
  background: none;
  border: 0;
  border-radius: var(--p-border-radius-100);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

._UnreadFilterButton_fsw2r_1:hover {
  background: var(--p-color-bg-surface-secondary-hover);
}

._SpinnerWrapper_fsw2r_18 {
  height: 1.75rem;
  width: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

._SpinnerWrapper_fsw2r_18 svg {
  display: block;
}

._UnreadFilterClickable_fsw2r_30 {
  cursor: pointer;
  pointer-events: auto;
}

._NotificationsHeading_7ighe_1 {
  --p-color-icon-secondary: var(--p-color-icon-disabled);
}

._ActivatorWrapper_1l8g6_4 {
  height: 3.5rem;
  display: flex;
  align-items: center;
  margin-right: var(--p-space-200);
}

._ActivatorWrapper-TopBarAndReframe_1l8g6_12 {
  margin-right: var(--p-space-150);
}

@media (min-width: 48em) {
  ._ActivatorWrapper-TopBarAndReframe_1l8g6_12 {
    margin-right: var(--p-space-200);
  }
}

._Activator_1l8g6_4 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

._Activator_1l8g6_4:focus {
  outline: none;
}

._Activator_1l8g6_4 {
  position: relative;
}

._Activator_1l8g6_4:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._Activator_1l8g6_4 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  transition: background-color var(--p-motion-duration-100);
  color: var(--p-color-text-inverse);
  padding: var(--p-space-150);
  background-color: var(--p-color-bg-fill-inverse);
  border-radius: var(--p-border-radius-200);
  min-width: auto;
}

._Activator_1l8g6_4:focus-visible:after {
  content: none;
}

._Activator_1l8g6_4:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-050);
}

._Activator_1l8g6_4:is(:hover, :focus-visible) {
  background-color: var(--p-color-bg-fill-inverse-hover);
}

._Activator_1l8g6_4:is(:hover, :focus-visible)
  [class*="alerts-feed-unread-count-indicator"] {
  border-color: var(--p-color-bg-surface-hover);
}

._Activator_1l8g6_4:active,
._Activator_1l8g6_4[aria-expanded="true"] {
  background-color: var(--p-color-bg-fill-inverse-active);
  outline: none;
  transition: none;
  color: var(--p-color-text-emphasis);
}

._Activator_1l8g6_4:active [class*="alerts-feed-unread-count-indicator"],
._Activator_1l8g6_4[aria-expanded="true"]
  [class*="alerts-feed-unread-count-indicator"] {
  border-color: var(--p-color-bg-surface-active);
  transition: none;
}

._Activator_1l8g6_4:active svg,
._Activator_1l8g6_4[aria-expanded="true"] svg {
  fill: var(--p-color-icon-inverse);
}

._Activator_1l8g6_4:active:after,
._Activator_1l8g6_4[aria-expanded="true"]:after {
  border: none;
}

@media (max-width: 47.9975em) {
  ._Activator_1l8g6_4 {
    background-color: var(--p-color-bg-inverse);
  }

  ._Activator_1l8g6_4:focus,
  ._Activator_1l8g6_4:hover,
  ._Activator_1l8g6_4:active,
  ._Activator_1l8g6_4[aria-expanded="true"] {
    background-color: transparent;
    opacity: 0.85;
  }
}

@media (max-width: 64.9975em) {
  ._Activator_1l8g6_4 {
    background-color: var(--p-color-bg-inverse);
  }
}

._Onboarding_1l8g6_89 {
  margin: var(--p-space-200) var(--p-space-400) var(--p-space-400);
  padding: var(--p-space-400);
  background-color: var(--p-color-bg-surface-hover);
  border-radius: var(--p-border-radius-050);
  text-align: center;
}

._MenuPopover_1l8g6_97 {
  --notification-button-size: 1.75rem;
  --notification-critical-icon-size: 1.25rem;
  --notification-read-indicator-size: 0.5rem;
  width: 23.75rem;
  max-width: calc(100vw - 2rem);
}

._IndicatorWrapper_cxjdc_1 {
  position: relative;
}

._Indicator_cxjdc_1 {
  --p-color-text: white;
  --indicator-font-size: 0.5rem;
  position: absolute;
  z-index: 1;
  color: var(--p-color-text);
  transition: border-color var(--p-motion-duration-100);
  top: -0.1875rem;
  left: unset;
  right: -0.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--p-color-icon-critical);
  padding: var(--p-space-050) 0.1875rem;
  border: 0;
  border-radius: var(--p-border-radius-200);
  min-width: var(--p-space-300);
  height: var(--p-space-300);
  font-weight: var(--p-font-weight-bold);
  font-size: var(--indicator-font-size);
  line-height: var(--indicator-font-size);
}

._Indicator_cxjdc_1._LeftReduced_cxjdc_31 {
  left: 0.3rem;
}

._Indicator_cxjdc_1._LeftReset_cxjdc_35 {
  left: 0;
}

._SecondaryMenuContainer_14ay1_4 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  margin-left: var(--p-space-200);
}

._SecondaryMenuContainer-TopBarAndReframe_14ay1_13 {
  margin-left: calc(var(--p-space-150) * -1);
}

@media (min-width: 48em) {
  ._SecondaryMenuContainer-TopBarAndReframe_14ay1_13 {
    margin-left: var(--p-space-200);
  }
  .Polaris-Navigation__SectionHeading > :first-child {
    margin: 0;
  }
  .Polaris-Navigation {
    height: calc(100% - 3.5rem) !important;
  }
}

/* .Polaris-Navigation {
  height: 90vh;
} */

.Polaris-Navigation__Section + .Polaris-Navigation__Section {
  padding-top: 0;
}

.Polaris-Navigation__SectionHeading {
  padding: 4px 0px 4px 8px;
}

.Polaris-Navigation__Action {
  padding: 0 !important;
  padding-right: 4px !important;
}

.Polaris-Icon__Img {
  transform: scale(0.8);
}

.Polaris-Frame__NavigationDismiss {
  display: none;
}

.table-heading-date {
  min-width: 136.797px;
}

._Wrapper_lc8vr_1 {
  padding-right: var(--p-space-400);
}
