._Text_8d88o_1 {
  border-bottom: var(--p-space-050) dotted var(--p-color-border-tertiary);
  font-size: var(--p-font-size-300);
  color: var(--p-color-bg-inverse);
  margin-left: calc(var(--p-space-100) * -1);
}

._NotificationDotWrapper_1i4ce_1 {
  position: absolute;
  top: -0.625rem;
  right: -0.625rem;
}

._EmptyStateContainer_1nd80_1 {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: var(--p-border-radius-400);
  border: var(--p-border-width-025) solid var(--p-color-border-disabled);
}

._CloseButton_9gwre_1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
  outline: none;
  border: none;
  background: transparent;
  border-radius: var(--p-border-radius-100);
}

._CloseButton_9gwre_1 svg {
  fill: var(--p-color-icon-secondary);
}

._CloseButton_9gwre_1:hover svg {
  fill: var(--p-color-icon-active);
}

._OverviewDashboard_pkhhu_1 [class^="Polaris-Page"] {
  margin: 0;
}

._CustomizationMode_pkhhu_1 {
  padding-top: 3.5rem;
}

._HeaderActions_pkhhu_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--p-space-400);
  flex-wrap: wrap;
  margin-bottom: var(--p-space-400);
}

@media (max-width: 490px) {
  ._HeaderActions_pkhhu_1 {
    padding: 0 var(--p-space-400);
    flex-direction: column;
    align-items: stretch;
  }
}

._DashboardMetricCard_pkhhu_3 {
  height: 22.125rem;
}

._DashboardMetricCard_pkhhu_3:hover ._DragHandle_pkhhu_8 svg {
  fill: var(--p-color-icon-active);
}

._DashboardMetricCard_pkhhu_3 [class^="Polaris-DataTable"] {
  border-radius: 0 !important;
}

._DashboardMetricCard_pkhhu_3
  [class^="Polaris-DataTable__TableRow"]
  > [class^="Polaris-DataTable__Cell"] {
  padding-top: var(--p-space-300);
  padding-bottom: var(--p-space-300);
}

._DashboardMetricCard_pkhhu_3
  [class^="Polaris-DataTable__TableRow"]
  > [class^="Polaris-DataTable__Cell"]:first-child {
  padding-left: var(--p-space-400);
}

._DashboardMetricCard_pkhhu_3
  [class^="Polaris-DataTable__TableRow"]
  > [class^="Polaris-DataTable__Cell"]:last-child {
  padding-right: var(--p-space-400);
}

._IsEditing_pkhhu_42 {
  pointer-events: none;
}

._Disabled_1b98p_3 {
  color: var(--p-color-text-disabled);
}

._titleWrapper_1b98p_7 {
  padding: var(--p-space-400) var(--p-space-500);
  border-color: var(--p-color-border-secondary);
  border-right-style: none;
  border-bottom-style: solid;
  border-bottom-width: var(--p-border-width-025);
  display: flex;
  gap: var(--p-space-800);
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 65em) {
  ._titleWrapper_1b98p_7 {
    max-width: 17.5rem;
    border-bottom-style: none;
    border-right-width: var(--p-border-width-025);
    border-right-style: solid;
  }
}

@media (min-width: 111.25em) {
  ._titleWrapper_1b98p_7 {
    max-width: 25rem;
  }
}

._metricsWrapper_1b98p_34 {
  flex-grow: 1;
  overflow: scroll hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

._metricsWrapper_1b98p_34::-webkit-scrollbar {
  display: none;
}

._metricWrapper_1b98p_46 {
  flex: 1 1;
}

@media (min-width: 111.25em) {
  ._metricWrapper_1b98p_46 {
    min-width: 27.5rem;
    flex: none;
  }
}

._divider_1b98p_56 {
  border-bottom: var(--p-border-width-025) solid var(--p-color-border-secondary);
  flex-grow: 1;
}

._titleWrapper_ex83v_2 {
  padding: var(--p-space-400);
  border: none;
  border-bottom: var(--p-border-width-025) solid var(--p-color-border-secondary);
  min-width: 15rem;
}

._titleWrapper_ex83v_2._lgUp_ex83v_10 {
  border: none;
  border-right: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._titleInner_ex83v_17 {
  width: 10rem;
}

._metricsWrapper_ex83v_21 {
  flex-grow: 1;
}

._divider_ex83v_25 {
  border-bottom: var(--p-border-width-025) solid var(--p-color-border-secondary);
  flex-grow: 1;
}

._Container_kt7wl_4 {
  margin-bottom: var(--p-space-400);
}

._OverviewDashboard_1snse_1 [class^="Polaris-Page"] {
  margin: 0;
}

._CustomizationMode_1snse_1 {
  padding-top: 3.5rem;
}

._HeaderActions_1snse_3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--p-space-400);
  flex-wrap: wrap;
  margin-bottom: var(--p-space-400);
}

@media (max-width: 490px) {
  ._HeaderActions_1snse_3 {
    padding: 0 var(--p-space-400);
    flex-direction: column;
    align-items: stretch;
  }
}

._HeaderActions_1snse_3 {
  display: flex;
  align-items: center;
  gap: var(--p-space-200);
  padding-bottom: var(--p-space-400);
}

@media (max-width: 30.6225em) {
  ._HeaderActions_1snse_3 {
    padding: 0 var(--p-space-400) var(--p-space-400);
    flex-direction: column;
    align-items: stretch;
  }
}

._CardContainer_1snse_16 {
  height: 22.125rem;
}

._MetricPreview_68s6r_1 {
  text-align: left;
  background: var(--p-color-bg-surface);
  box-shadow: var(--p-shadow-500);
  border-radius: var(--p-border-radius-200);
  padding: var(--p-space-400);
  cursor: default;
}

._VizPreview_68s6r_10 {
  width: 100%;
  margin-top: var(--p-space-200);
  margin-bottom: var(--p-space-400);
}

._Description_68s6r_16 {
  margin-top: var(--p-space-050);
  font-size: var(--p-font-size-300);
  line-height: var(--p-font-line-height-400);
}

._AddToDashboardButton_68s6r_22 {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  font-size: var(--p-font-size-300);
  font-weight: var(--p-font-weight-semibold);
  color: var(--p-color-text-emphasis);
  cursor: pointer;
}

._AddToDashboardButton_68s6r_22:hover {
  color: var(--p-color-text-emphasis-hover);
}

._MetricsLibrary_vfwa5_12 {
  position: sticky;
  top: 3.5rem;
  height: calc(100vh - 3.5rem);
  background-color: var(--p-color-bg-surface);
}

._MetricsLibrary_vfwa5_12._Overflow_vfwa5_20 {
  overflow-y: auto;
}

._MetricsLibraryButton_vfwa5_25 {
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  border-radius: var(--p-border-radius-100);
  padding: var(--p-space-100);
  color: var(--p-color-bg-inverse);
  transition: background-color var(--p-motion-duration-200)
      var(--p-motion-ease-in),
    box-shadow var(--p-motion-duration-200) var(--p-motion-ease-in);
}

._MetricsLibraryItem_vfwa5_39 {
  position: relative;
  display: flex;
  width: 100%;
  cursor: inherit;
}

._MetricsLibraryItem_vfwa5_39:hover {
  background-color: var(--p-color-bg-surface-hover);
  text-decoration: none;
  outline: var(--p-border-width-050) solid transparent;
}

._MetricsLibraryItem_vfwa5_39:active {
  box-shadow: var(--p-shadow-400);
  cursor: grabbing;
}

._MetricsLibraryItem_vfwa5_39:hover + ._PreviewContainer_vfwa5_60,
._MetricsLibraryItem_vfwa5_39:focus + ._PreviewContainer_vfwa5_60,
._MetricsLibraryItem_vfwa5_39:focus-visible + ._PreviewContainer_vfwa5_60,
._MetricsLibraryItem_vfwa5_39:focus-within + ._PreviewContainer_vfwa5_60 {
  display: block;
}

._IconWrapper_vfwa5_66 {
  position: relative;
  height: 1.25rem;
  width: 1.25rem;
}

._DragHandle_vfwa5_72,
._Icon_vfwa5_66 {
  position: absolute;
  opacity: 0;
  transition: opacity var(--p-motion-duration-200) var(--p-motion-ease-in-out);
}

._DragHandle_vfwa5_72._FadeIn_vfwa5_78,
._Icon_vfwa5_66._FadeIn_vfwa5_78 {
  opacity: 1;
}

._Disabled_vfwa5_83 {
  opacity: 50%;
  transition: opacity var(--p-motion-duration-200) var(--p-motion-ease);
}

._Hidden_vfwa5_90 {
  visibility: hidden;
}

._EmptyState_vfwa5_94 {
  display: inline-grid;
  height: calc(79vh + 3.5rem);
  text-align: center;
}

._DraggableItem_vfwa5_101 {
  cursor: pointer;
}

._isDragging_vfwa5_105 {
  visibility: hidden;
}

._PreviewContainer_vfwa5_60 {
  position: fixed;
  left: 12.5rem;
  z-index: var(--p-z-index-3);
  width: 15.625rem;
}

._PreviewContainer_vfwa5_60:hover {
  display: block;
}

._PreviewContainer_vfwa5_60._isDragging_vfwa5_105 {
  position: fixed;
  display: none;
}

._PreviewContainer_vfwa5_60._Above_vfwa5_125 {
  bottom: -0.3125rem;
}

._PreviewContainer_vfwa5_60._Below_vfwa5_130 {
  top: -0.3125rem;
}

._ItemButtonAnimation_vfwa5_136 {
  background-color: none;
  animation: _item-bkgd_vfwa5_1 var(--p-motion-duration-5000);
}

@keyframes _item-bkgd_vfwa5_1 {
  0% {
    background: none;
  }

  5%,
  15% {
    background: var(--p-color-bg-surface-brand-hover);
  }

  21% {
    background: none;
  }
}

._DragOverlay_qujsz_1 {
  cursor: grabbing;
  box-shadow: var(--p-shadow-600);
  outline: var(--p-border-width-050) solid var(--p-color-bg-surface-active);
  border-radius: var(--p-border-radius-200);
}

._DroppableContainer_5geio_1 {
  padding-bottom: var(--p-space-400);
}

._SortableItem_1qee8_1 {
  cursor: grab;
}

._SortableItem_1qee8_1 > :active {
  position: relative;
  z-index: var(--p-z-index-1);
  border-radius: var(--p-border-radius-200);
  cursor: grabbing;
  box-shadow: var(--p-shadow-600);
  outline: var(--p-border-width-050) solid var(--p-color-bg-surface-active);
}

._isDragging_1qee8_14 {
  opacity: 0;
}

._DragAffordancePreview_1qee8_18 {
  background: var(--p-color-bg-surface-brand-hover);
  border-radius: var(--p-border-radius-200);
}

._OverviewDashboard_o2wc9_9 [class^="Polaris-Page"] {
  margin: 0;
}

._CustomizationMode_o2wc9_16 {
  padding-top: 3.5rem;
}

._HeaderActions_o2wc9_21 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--p-space-400);
  flex-wrap: wrap;
  margin-bottom: var(--p-space-400);
}

@media (max-width: 490px) {
  ._HeaderActions_o2wc9_21 {
    padding: 0 var(--p-space-400);
    flex-direction: column;
    align-items: stretch;
  }
}
