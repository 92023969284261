._Button_18yn4_1 {
    text-align: left;
    margin-left: calc(var(--p-space-100) * -1);
    padding: 0 var(--p-space-100);
    border-radius: var(--p-border-radius-100);
    border: var(--p-border-width-025) solid transparent;
    cursor: default;
    background: transparent;
    color: unset;
  }
  
  ._Button_18yn4_1._Inline_18yn4_11 {
    padding: 0;
    margin-left: 0;
  }
  
  ._Button_18yn4_1._Active_18yn4_16 {
    border-color: var(--p-color-border-emphasis);
  }
  
  ._Button_18yn4_1:hover {
    background-color: var(--p-color-bg-surface-hover);
  }
  
  ._Button_18yn4_1:active {
    background-color: var(--p-color-bg-surface-active);
  }
  
  ._WrappedContent_18yn4_29 {
    border-bottom: 0.125rem dotted var(--p-color-border-tertiary);
    border-color: var(--p-color-border-tertiary);
  }
  
  ._DefinitionContainer_18yn4_34 {
    max-width: 15.625rem;
  }