._Container_3iemw_4 {
  position: relative;
  display: flex;
  align-items: center;
  margin: var(--p-space-200) 0;
  scroll-snap-align: start;
  flex: 1 0 10rem;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  min-width: -moz-fit-content;
  max-width: 16.25rem;
}

._Container_3iemw_4:not(:last-of-type) {
  border-right: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._Container_3iemw_4:not(:first-child) {
  scroll-margin-left: 0.75rem;
  scroll-snap-margin-left: 0.75rem;
}

._HoverContainer_3iemw_31 {
  width: 100%;
  height: 100%;
  border-radius: var(--p-border-radius-200);
  padding: var(--p-space-100) var(--p-space-300);
  margin: 0 var(--p-space-300);
}

._EnableLink_3iemw_39 ._HoverContainer_3iemw_31 {
  cursor: pointer;
  transition: background var(--p-motion-duration-150) var(--p-motion-ease);
}

._EnableLink_3iemw_39 ._HoverContainer_3iemw_31:hover,
._EnableLink_3iemw_39 ._HoverContainer_3iemw_31:focus-visible,
._EnableLink_3iemw_39 ._HoverContainer_3iemw_31:focus-within {
  background: var(--p-color-bg-surface-tertiary);
}

._Link_3iemw_52 {
  position: absolute;
  z-index: var(--p-z-index-1);
  left: 0;
  top: calc(var(--p-space-200) * -1);
  bottom: calc(var(--p-space-200) * -1);
  width: 100%;
  outline: 0;
}

._Viz_3iemw_62 {
  height: var(--p-space-400);
  width: 100%;
  flex: 1 1;
}

._ContentWrapper_plwmu_1 {
  display: block;
  align-items: center;
}

@media (min-width: 30.625em) {
  ._ContentWrapper_plwmu_1 {
    display: flex;
  }
}

._Container_plwmu_10 {
  position: relative;
  width: 100%;
  height: 4.25rem;
  overflow: auto hidden;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

._Container_plwmu_10:focus {
  box-shadow: inset 0 0 0 var(--p-border-width-050)
    var(--p-color-border-emphasis);
}

._Container_plwmu_10::-webkit-scrollbar {
  display: none;
}

@media (min-width: 30.625em) {
  ._Container_plwmu_10 {
    scroll-snap-type: none;
  }
}

._DropdownButton_176si_1 {
  border: none;
  height: 4.25rem;
  cursor: pointer;
  min-width: 8rem;
  background: none;
  border-right: var(--p-border-width-025) solid var(--p-color-border-secondary);
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  padding: var(--p-space-200);
  outline: none;
}

._DropdownButton_176si_1:hover ._DropdownButtonInnerWrapper_176si_17,
._DropdownButton_176si_1:focus ._DropdownButtonInnerWrapper_176si_17 {
  background: var(--p-color-bg-surface-tertiary);
}

._DropdownButton_176si_1._Disabled_176si_22 {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--p-color-text-disabled);
}

._DropdownButton_176si_1:focus ._DropdownButtonInnerWrapper_176si_17 {
  box-shadow: inset 0 0 0 var(--p-border-width-050)
    var(--p-color-border-emphasis);
}

._IconDisabled_176si_36 svg {
  fill: var(--p-color-icon-disabled);
}

._DropdownButtonInnerWrapper_176si_17 {
  border-radius: var(--p-border-radius-200);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._DropdownButtonInnerWrapper_176si_17._DropdownIsOpen_176si_49 {
  background: var(--p-color-bg-surface-hover);
}

._LocaleEN_176si_55,
._LocaleCS_176si_56,
._LocaleDA_176si_57,
._LocaleDE_176si_58,
._LocaleES_176si_59,
._LocaleIT_176si_60,
._LocaleNB_176si_61,
._LocalePT_176si_62,
._LocaleSV_176si_63,
._LocaleTH_176si_64,
._LocaleTR_176si_65,
._LocaleVI_176si_66 {
  width: 8rem;
}

._LocaleFI_176si_71,
._LocaleFR_176si_72,
._LocaleNL_176si_73 {
  width: 9.25rem;
}

._LocaleJA_176si_77,
._LocaleKO_176si_78,
._LocaleZH_176si_79 {
  min-width: 7.375rem;
  width: 7.375rem;
}

._LocaleHI_176si_85,
._LocaleMS_176si_92,
._LocalePL_176si_99 {
  width: 8rem;
}

._HorizontalNavigation_13tze_1 {
  position: relative;
}

._HorizontalNavigation_13tze_1 [class^="Polaris-InlineStack"] > div:last-child {
  padding-right: var(--p-space-1600);
}

._HorizontalNavigation_13tze_1:hover ._ArrowsWrapper_13tze_11 {
  opacity: 1;
}

._ArrowsWrapper_13tze_11 {
  transition: opacity var(--p-motion-duration-200) var(--p-motion-ease-in-out);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  opacity: 0;
  padding-right: var(--p-space-400);
  box-shadow: 0 0 var(--p-width-500) var(--p-width-500)
    var(--p-color-bg-surface);
  background-color: var(--p-color-bg-surface);
  height: 100%;
}

._ArrowsWrapper_13tze_11:focus-within {
  opacity: 1;
}

._OverflowGradient_13tze_36 {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.375rem;
  background: linear-gradient(
    90deg,
    transparent 0%,
    var(--p-color-bg-surface) 48.96%
  );
}

._Container_tk6bp_4 {
  position: relative;
  display: flex;
  align-items: center;
  margin: var(--p-space-200) 0;
  scroll-snap-align: start;
  flex: 1 0 10rem;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  min-width: -moz-fit-content;
  max-width: 16.25rem;
}

._Container_tk6bp_4:not(:last-of-type) {
  border-right: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._Container_tk6bp_4:not(:first-child) {
  scroll-margin-left: 0.75rem;
  scroll-snap-margin-left: 0.75rem;
}

._HoverContainer_tk6bp_31 {
  width: 100%;
  height: 100%;
  border-radius: var(--p-border-radius-200);
  padding: var(--p-space-100) var(--p-space-300);
  margin: 0 var(--p-space-300);
  cursor: pointer;
  transition: background var(--p-motion-duration-150) var(--p-motion-ease);
}

._HoverContainer_tk6bp_31:hover,
._HoverContainer_tk6bp_31:focus-visible,
._HoverContainer_tk6bp_31:focus-within {
  background: var(--p-color-bg-surface-tertiary);
}

._Link_tk6bp_47 {
  position: absolute;
  z-index: var(--p-z-index-1);
  left: 0;
  top: calc(var(--p-space-200) * -1);
  bottom: calc(var(--p-space-200) * -1);
  width: 100%;
  outline: 0;
}

._Viz_tk6bp_57 {
  height: var(--p-space-400);
  width: 100%;
  flex: 1 1;
}
