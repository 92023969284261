@charset "UTF-8";
._MissingPermissions_10655_1 {
  position: relative;
  height: 100%;
}

._Background_10655_6 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  height: 100%;
  filter: blur(0.75rem);
  --polaris-animation-skeleton-shimmer: none;
}

._Background_10655_6 svg {
  display: none;
}

._Container_10655_25 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._Main_10655_38 {
  display: flex;
  flex-wrap: wrap;
  padding: var(--p-space-300) 3.5rem;
}

._Content_10655_45 {
  display: flex;
  flex-direction: column;
}

._Content_10655_45 > * + * {
  margin-top: var(--p-space-300);
}

._Illustration_10655_56 {
  margin-right: var(--p-space-800);
  margin-bottom: 1.75rem;
}

._ButtonContainer_1how6_4 {
  display: flex;
}

._Title_1how6_8 {
  color: #fff;
}

._Title_1how6_8 a {
  color: inherit;
}

._ContentContainer_1how6_17 {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  gap: var(--p-space-200);
  align-items: self-start;
}

@media only screen and (width >= 490px) {
  ._ContentContainer_1how6_17 {
    align-items: center;
    flex-flow: row wrap;
  }
}

._DismissButton_1how6_31 {
  background: none;
  border-color: transparent;
  border-radius: var(--p-border-radius-200);
  cursor: pointer;
  padding: var(--p-space-100);
  margin-right: calc(var(--p-space-200) * -1);
}

._DismissButton_1how6_31 svg {
  fill: var(--p-color-bg-surface);
}

._DismissButton_1how6_31:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
}

._DismissButton_1how6_31:hover svg {
  fill: var(--p-color-bg-surface-info-hover);
}

._ActionButton_1how6_54 {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  min-width: 2.25rem;
  margin: 0;
  padding: var(--p-space-200) var(--p-space-400);
  background: var(--p-color-bg-surface-secondary);
  box-shadow: var(--p-shadow-200);
  border-radius: var(--p-border-radius-100);
  color: var(--p-color-text);
  border: var(--p-border-width-025) solid var(--p-color-border-tertiary);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

._ActionButton_1how6_54 svg {
  fill: var(--p-color-icon);
}

._ActionButton_1how6_54:hover {
  background: var(--p-color-bg-surface-secondary-hover);
  outline: var(--p-border-width-025) solid transparent;
}

._ActionButton_1how6_54:focus-visible:after {
  content: none;
}

._ActionButton_1how6_54:focus-visible {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
  outline-offset: var(--p-space-050);
  box-shadow: var(--p-shadow-200);
}

._ActionButton_1how6_54:active {
  background: var(--p-color-bg-surface-secondary-active);
  box-shadow: var(--p-shadow-200);
}

._ActionButton_1how6_54:active:after {
  border: none;
  box-shadow: none;
}

._ActionButton_1how6_54._pressed_1how6_1 {
  background: var(--p-color-bg-fill-inverse);
  box-shadow: var(--p-shadow-inset-200);
  color: #fff;
  border-color: var(--p-color-border-inverse);
}

._ActionButton_1how6_54._pressed_1how6_1 svg {
  fill: currentcolor;
}

@media (-ms-high-contrast: active) {
  ._ActionButton_1how6_54 {
    border: var(--p-border-width-025) solid windowText;
  }
}

._ActionButton_1how6_54 {
  font-size: 0.9375rem;
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-font-line-height-400);
  text-transform: initial;
  letter-spacing: initial;
}

@media (min-width: 48em) {
  ._ActionButton_1how6_54 {
    font-size: var(--p-font-size-350);
  }
}

._ActionButton_1how6_54 {
  position: relative;
}

._ActionButton_1how6_54:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._ActionButton_1how6_54 {
  min-height: 1.75rem;
  padding: 0.1875rem var(--p-space-300);
  color: var(--p-color-text);
  text-decoration: none;
  border-radius: var(--p-border-radius-100);
  box-shadow: none;
}

._ActionButton_1how6_54:focus-visible {
  position: relative;
}

._ActionButton_1how6_54:focus-visible:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-color-border-focus);
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  border-radius: calc(var(--p-border-radius-100) + 0.0625rem);
}

._ActionButton_1how6_54._info_1how6_70 {
  border-color: var(--p-color-border-info);
}

._ActionButton_1how6_54._info_1how6_70:hover {
  border-color: var(--p-color-border-info);
  background: var(--p-color-bg-surface-info-hover);
}

._ActionButton_1how6_54._info_1how6_70:active {
  border-color: var(--p-color-border-info);
  background: var(--p-color-bg-surface-info-active);
}

._ActionButton_1how6_54._info_1how6_70:focus {
  border-color: var(--p-color-border-info);
  background: var(--p-color-bg-surface-info);
}

._ActionButton_1how6_54._warning_1how6_89 {
  border-color: var(--p-color-border-caution);
}

._ActionButton_1how6_54._warning_1how6_89:hover {
  border-color: var(--p-color-border-caution);
  background: var(--p-color-bg-surface-caution-hover);
}

._ActionButton_1how6_54._warning_1how6_89:active {
  border-color: var(--p-color-border-caution);
  background: var(--p-color-bg-surface-caution-active);
}

._ActionButton_1how6_54._warning_1how6_89:focus {
  border-color: var(--p-color-border-caution);
  background: var(--p-color-bg-surface-caution);
}

._ActionButton_1how6_54._alert_1how6_108 {
  border-color: var(--p-color-border-critical);
}

._ActionButton_1how6_54._alert_1how6_108:hover {
  border-color: var(--p-color-border-critical);
  background: var(--p-color-bg-surface-critical-hover);
}

._ActionButton_1how6_54._alert_1how6_108:active {
  border-color: var(--p-color-border-critical);
  background: var(--p-color-bg-surface-critical-active);
}

._ActionButton_1how6_54._alert_1how6_108:focus {
  border-color: var(--p-color-border-critical);
  background: var(--p-color-bg-surface-critical);
}

._Icon_123oc_1 {
  padding: var(--p-space-100);
  background-color: var(--anakiwa);
  color: var(--p-color-text);
  border-radius: var(--p-border-radius-200);
}

@media only screen and (width < 490px) {
  ._Icon_123oc_1 {
    display: none;
  }
}

._Banner_123oc_12 {
  background-color: #000;
  color: #fff;
  padding: var(--p-space-300);
  border-radius: inherit;
  box-shadow: var(--p-shadow-200);
}

._MobileButtonContainer_123oc_22 {
  display: none;
}

@media only screen and (width < 490px) {
  ._MobileButtonContainer_123oc_22 {
    display: flex;
  }
}

._ButtonContainer_123oc_30 {
  display: flex;
}

@media only screen and (width < 490px) {
  ._ButtonContainer_123oc_30 {
    display: none;
  }
}

._DismissButton_123oc_38 {
  display: flex;
}

@media only screen and (width < 490px) {
  ._DismissButton_123oc_38 {
    display: none;
  }
}

._ContentContainer_123oc_46 {
  flex: 1 1;
  align-content: center;
}

._MobileDismissButton_123oc_51 {
  display: none;
}

@media only screen and (width < 490px) {
  ._MobileDismissButton_123oc_51 {
    display: flex;
  }
}

._BannerText_123oc_59 {
  display: block;
}

@media only screen and (width < 490px) {
  ._BannerText_123oc_59 {
    display: flex;
  }
}

._Notifications_nopas_3 {
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
  list-style-type: none;
}

@media (min-width: 30.625em) {
  ._Notifications_nopas_3 {
    margin-top: var(--p-space-500);
    margin-bottom: var(--p-space-800);
  }
}

._Notification_nopas_3 {
  position: relative;
  padding: var(--p-space-400) 0;
}

._Notification_nopas_3:before {
  content: "";
  position: relative;
  bottom: var(--p-space-400);
  display: block;
  height: 0.0625rem;
  margin: 0 calc(var(--p-space-100) * -1);
}

._Notification_nopas_3:first-child {
  padding-top: 0;
}

._Notification_nopas_3:first-child:before {
  content: none;
}

._Notification_nopas_3:not(:first-child) {
  margin-top: var(--p-space-500);
}

._Notification_nopas_3:last-child {
  padding-bottom: 0;
}

._NotificationContainer_nopas_43 {
  overflow: hidden;
  padding: var(--p-space-300) var(--p-space-400);
  box-shadow: none;
}

@media (min-width: 30.625em) {
  ._NotificationContainer_nopas_43 {
    border-radius: var(--p-border-radius-200);
  }
}

._AcquisitionIncentiveNotification_nopas_53 {
  border: var(--p-border-width-025) solid var(--p-color-border);
  border-radius: var(--p-border-radius-200);
}

@media screen and (max-width: 30.6225em) {
  ._AcquisitionIncentiveNotification_nopas_53 {
    border-radius: 0;
    border-right: none;
    border-left: none;
  }
}

._trial_nopas_64 {
  --p-color-icon: var(--p-color-icon-inverse);
  --p-color-icon-hover: var(--p-color-bg-fill-inverse-hover);
  background-color: #000;
  color: var(--p-color-text-inverse);
  border: var(--p-border-width-025) solid var(--p-color-border-inverse);
}

._trial_nopas_64 ._Notification_nopas_3:before {
  background-color: var(--p-color-bg-inverse);
}

._info_nopas_79 {
  background-color: var(--p-color-bg-surface-info);
  border: var(--p-border-width-025) solid var(--p-color-border-info);
}

._info_nopas_79 ._Notification_nopas_3:before {
  background-color: var(--p-color-border-info);
}

._alert_nopas_88 {
  background-color: var(--p-color-bg-surface-critical);
  border: var(--p-border-width-025) solid var(--p-color-border-critical);
}

._alert_nopas_88 ._Notification_nopas_3:before {
  background-color: var(--p-color-border-critical);
}

._warning_nopas_97 {
  background-color: var(--p-color-bg-surface-caution);
  border: var(--p-border-width-025) solid var(--p-color-border-caution);
}

._warning_nopas_97 ._Notification_nopas_3:before {
  background-color: var(--p-color-border-caution);
}

._ContentContainer_j6sgc_1 a:link,
._ContentContainer_j6sgc_1 a:active,
._ContentContainer_j6sgc_1 a:visited {
  color: var(--p-color-text-emphasis);
  text-decoration: none;
}

._ContentContainer_j6sgc_1 a:hover {
  cursor: pointer;
  color: var(--p-color-text-emphasis-hover);
  text-decoration: underline;
}

._Title_5ke8q_1 {
  color: inherit;
}

._Title_5ke8q_1 > a {
  text-wrap: nowrap;
  color: inherit;
}

._Icon_5ke8q_10 {
  padding: var(--p-space-100);
  background-color: var(--anakiwa);
  border-radius: var(--p-border-radius-200);
}

._ButtonContainer_5ke8q_16 {
  min-width: var(--p-space-2400);
}

._Banner_5ke8q_20 {
  border-radius: inherit;
  box-shadow: var(--p-shadow-200);
}

._Dismiss_1y3lm_1 {
  display: flex;
  justify-content: space-between;
  margin-right: var(--p-space-800);
}

._OverflowMenu_1y3lm_7 {
  position: absolute;
  z-index: var(--p-z-index-2);
  top: 0;
  right: 0;
  margin: var(--p-space-400);
}

._Hidden_16oie_1 {
  display: none;
}

._OverflowMenu_19bs3_1 {
  position: absolute;
  z-index: var(--p-z-index-2);
  top: 0;
  right: 0;
  margin: var(--p-space-400);
}

._PageHeader_zq6rf_1 {
  padding: 0 var(--p-space-100);
  margin-bottom: var(--p-space-600);
}

@media (max-width: 47.9975em) {
  ._PageHeader_zq6rf_1 {
    padding: 0 var(--p-space-400);
  }
}

._PageHeader_zq6rf_1 > h1 {
  margin-bottom: var(--p-space-150);
}

._GuideContainer_dqfgm_3 {
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
  position: relative;
  margin-top: var(--p-space-400);
  margin-bottom: var(--p-space-400);
}

._GuideContainer_dqfgm_3:first-child {
  margin-top: var(--p-space-800);
}

._GuideContainer_dqfgm_3 > div {
  position: relative;
  margin-top: var(--p-space-400);
}

._DismissingBanner_1iepg_1 {
  color: var(--p-color-text);
}

._Title_1iepg_5 {
  font-weight: var(--p-font-weight-semibold);
  font-size: var(--p-font-size-400);
}

._FooterActions_1iepg_10 {
  margin-top: var(--p-space-300);
}

._FooterActions_1iepg_10 button:last-of-type {
  margin-left: var(--p-space-300);
}

._DismissButton_1iepg_18 {
  position: absolute;
  top: var(--p-space-500);
  right: var(--p-space-500);
}

._FeedbackSubmitted_1x000_1 {
  position: relative;
}

:root {
  --celebration-static-banner-bg: var(--p-color-bg-surface);
  --celebration-static-banner-color: var(--p-color-text);
}

._Banner_amqqd_6 {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: var(--p-space-500);
  color: var(--celebration-static-banner-color);
  background: var(--celebration-static-banner-bg);
  background-size: cover;
  box-shadow: var(--p-shadow-300);
}

@media (min-width: 30.625em) {
  ._Banner_amqqd_6 {
    border-radius: var(--p-border-radius-200);
  }
}

._ContentContainer_amqqd_22 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--p-space-400);
}

._IllustrationContainer_amqqd_29 {
  display: none;
  flex-direction: column;
}

@media (min-width: 65em) {
  ._IllustrationContainer_amqqd_29 {
    display: flex;
    align-items: flex-end;
    margin-right: 4.125rem;
  }
}

._Illustration_amqqd_29 {
  height: 7.25rem;
  width: 7.25rem;
}

._MenuContainer_amqqd_46 {
  display: flex;
}

._MenuItem_amqqd_50:last-of-type {
  margin-left: var(--p-space-200);
}

._Title_amqqd_54 {
  font-weight: var(--p-font-weight-semibold);
  font-size: var(--p-font-size-400);
}

._Message_amqqd_59 {
  margin-top: var(--p-space-200);
}

._Loading_uy308_3 {
  display: none;
}

._MilestoneAwardCelebration_uy308_7 {
  --celebration-static-banner-color: var(--p-color-text-brand-on-bg-fill);
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
  position: relative;
  display: block;
  margin-top: var(--p-space-800);
  border-radius: var(--p-border-radius-200);
}

._MilestoneAwardCelebration10K_uy308_17 {
  --celebration-static-banner-bg: no-repeat
    url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/merchant_awards_10K_bg-RRVPe6MIszbR.png);
}

._MilestoneAwardCelebration100K_uy308_22 {
  --celebration-static-banner-bg: no-repeat
    url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/merchant_awards_100k_bg-BYRj1HhlU4yG.png);
}

._MilestoneAwardCelebration1M_uy308_27 {
  --celebration-static-banner-bg: no-repeat
    url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/merchant_awards_1M_bg-Bf4hEuwtjaj2.png);
}

._Dismissing_uy308_32 {
  --celebration-static-banner-bg: var(--p-color-bg-surface);
}

._Indicator_1cknc_5 {
  position: relative;
  display: block;
  margin-right: var(--p-space-200);
  margin-left: var(--p-space-200);
  width: var(--p-width-200);
  height: var(--p-width-200);
  border-radius: var(--p-border-radius-full);
  background-color: var(--p-color-avatar-six-bg-fill);
  animation: _anim-pulse_1cknc_1 2s 4 linear;
  box-shadow: 0 0 0 0.2rem #b4fed2b3;
}

@keyframes _anim-pulse_1cknc_1 {
  0% {
    box-shadow: 0 0 0 0.2rem #b4fed2b3;
  }

  60% {
    box-shadow: 0 0 0 0.4rem #0000;
  }

  61% {
    box-shadow: 0 0 #0000;
  }

  62% {
    box-shadow: 0 0 #b4fed2;
  }

  to {
    box-shadow: 0 0 0 0.2rem #b4fed2b3;
  }
}

._metricsControllerPadding_1g938_1 {
  margin-left: var(--p-space-400);
  margin-right: var(--p-space-400);
}

._metricButtonStack_sakxn_1 {
  width: 100%;
  overflow-x: auto;
}

._metricButtonStack_sakxn_1 > div:first-child {
  width: 100%;
}

._metricButtonStackUA_sakxn_10 {
  min-height: calc(var(--p-height-1200) + var(--p-height-200));
}

._metricsButtonContainer_sakxn_14 {
  display: flex;
  flex-direction: row;
}

._expandButton_sakxn_19 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 2.25rem;
  padding-left: 0.625rem;
}

._expandButton_sakxn_19 button {
  width: 100%;
}

._expandButton_sakxn_19 button:hover {
  background-color: var(--p-color-bg);
  color: var(--p-color-icon);
}

._expandButton_sakxn_19 button:hover svg {
  fill: var(--p-color-icon);
}

._expandButton_sakxn_19 svg {
  fill: var(--p-color-icon-secondary);
}

._expandButton_sakxn_19 svg:hover {
  fill: var(--p-color-icon);
}

._expandButton_sakxn_19._shadow_sakxn_49 {
  box-shadow: -0.625rem 0 0.9375rem -0.3125rem #0000000d;
}

._pencilContainer_sakxn_55 {
  margin-right: calc(var(--p-space-150) * -1);
}

@media (max-width: 47.9975em) {
  ._pencilContainer_sakxn_55 {
    margin-right: calc(var(--p-space-200) * -1);
  }
}

._infoIconContainer_sakxn_63 {
  display: flex;
  visibility: hidden;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  max-width: 100%;
  border-radius: var(--p-border-radius-100);
}

._infoIconContainer_sakxn_63:focus {
  outline: var(--p-border-width-050) solid var(--p-color-border-focus);
}

._metricEditButtonPopOverContainer_sakxn_81
  li:focus-within
  ._infoIconContainer_sakxn_63,
._metricEditButtonPopOverContainer_sakxn_81
  li:hover
  ._infoIconContainer_sakxn_63 {
  visibility: visible;
}

._prefixContainer_sakxn_91 {
  position: relative;
}

._infoPopoverActivator_sakxn_95 {
  position: absolute;
  top: 0;
  width: 22.375rem;
}

._leftAlign_sakxn_101 {
  position: absolute;
  top: -0.75rem;
  right: 2.25rem;
}

@media (max-width: 47.9975em) {
  ._metricsButton_sakxn_14 {
    flex: 0 0 calc(50% - var(--p-space-400));
  }
}

._metricsButton_sakxn_14:not(:focus):not(:hover):not(:focus-within):not(
    ._metricButtonSelected_sakxn_112
  )
  ._pencilContainer_sakxn_55:not(._listOpen_sakxn_114) {
  max-width: 0;
  overflow: hidden;
}

._metricsButton_sakxn_14:hover [class^="Polaris-Box"],
._metricsButton_sakxn_14:focus [class^="Polaris-Box"] {
  background-color: var(--p-color-bg);
  cursor: pointer;
}

@media (min-width: 48em) {
  ._metricsButtonWidth_sakxn_130 {
    width: 11.5rem;
  }
}

._MetricCardContainer_sakxn_136 {
  --home-metric-card-container-height: 13.125rem;
  height: var(--home-metric-card-container-height);
  padding: var(--p-space-0) var(--p-space-100) var(--p-space-100)
    var(--p-space-100);
}

._MetricCardContainer_sakxn_136._Loading_sakxn_142 {
  padding: var(--p-space-0);
  margin-top: var(--p-space-150);
  height: calc(var(--home-metric-card-container-height) - var(--p-space-150));
}

._MetricCardContainer_sakxn_136 [class^="Polaris-DataTable"] {
  border-radius: 0;
}

._MetricCardContainer_sakxn_136
  [class^="Polaris-DataTable"]
  td:not(:first-child) {
  padding-left: var(--p-space-200);
  padding-right: var(--p-space-200);
  width: var(--p-space-0);
}

._MetricCardContainer_sakxn_136 [class^="Polaris-DataTable"] td:last-child {
  padding-right: var(--p-space-300);
}

._EmptyState_16whr_1 {
  --empty-state-bg-height: 10.875rem;
  padding-top: var(--p-space-1600);
  height: var(--home-metric-card-container-height);
  background: no-repeat bottom center / 100% var(--empty-state-bg-height);
}

._EmptyState_16whr_1._line_16whr_7 {
  background-image: url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/line-D7elCgVj2dfy.svg);
}

._EmptyState_16whr_1._bar_16whr_11,
._EmptyState_16whr_1._dynamicBar_16whr_12,
._EmptyState_16whr_1._simpleHorizontalBar_16whr_13 {
  background-image: url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/bar-Dc-vNfGWGY3T.svg);
}

._EmptyState_16whr_1._table_16whr_17 {
  background: url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/table1-WXz7fno4lBBm.svg)
      no-repeat bottom left / auto var(--empty-state-bg-height),
    url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/table2-Bee8sJXnz01d.svg)
      no-repeat bottom center / 100% var(--empty-state-bg-height),
    url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/table3-BveFVDoWlrE8.svg)
      no-repeat bottom right / auto var(--empty-state-bg-height);
}

._EmptyState_16whr_1._cohorts_16whr_26 {
  background-image: url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/cohorts-CHG0Ery0nnQ0.svg);
}

._EmptyState_16whr_1._donut_16whr_30 {
  background: url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/donut1-Bt7pJUGQLWzG.svg)
      no-repeat 2.25rem 2.5625rem / 17.875rem 8.75rem,
    url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/donut2-CC1QaTJVRaVF.svg)
      no-repeat 15rem 2.0625rem / calc(100% - 17.25rem) 10rem;
}

._analyticsContainer_ql3ja_3 {
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
  margin-top: var(--p-space-500);
}

._CheckoutMigrationCardWrapper_mh3h7_3 {
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
}

._CheckoutMigrationCard_mh3h7_3 {
  background-color: var(--p-color-bg-inverse);
  padding: var(--p-space-300) var(--p-space-400);
  color: var(--p-color-text-critical-on-bg-fill);
}

._CheckoutMigrationCard_mh3h7_3 [class*="Polaris-Link"] {
  color: var(--p-color-text-link-inverse);
}

._CheckoutMigrationCard_mh3h7_3 [class*="Polaris-Link"]:hover {
  color: var(--p-color-text-emphasis-on-bg-fill-active);
}

._homeDivider_1xikp_3 {
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
  margin-top: 1.75rem;
  margin-bottom: var(--p-space-400);
  color: var(--p-color-text);
}

@media (max-width: 30.6225em) {
  ._homeDivider_1xikp_3 h2 {
    margin-left: var(--p-space-500);
  }
}

._homeDivider_1xikp_3:last-child {
  display: none;
}

._Feed_qbmy0_1 {
  margin-bottom: var(--p-space-400);
}

._CardList_qbmy0_5 {
  padding: 0;
  list-style: none;
}

._CardList_qbmy0_5 > li:not(:first-child) {
  margin-top: var(--p-space-400);
}

._ButtonContainer_njmna_1 {
  display: flex;
}

._Wrapper_186da_1 {
  --right-panel-title-offset: var(--p-space-600);
  --chevron-border-width: var(--p-border-width-025);
  --right-chevron-width: 0.6875rem;
  --right-chevron-height: 1.75rem;
  --right-chevron-top: 0.9375rem;
  --right-chevron-mid-point: calc(
    var(--right-chevron-top) + var(--right-chevron-height) / 2
  );
  --right-chevron-bottom: calc(
    var(--right-chevron-top) + var(--right-chevron-height)
  );
  --down-chevron-width: var(--right-chevron-height);
  --down-chevron-height: var(--right-chevron-width);
  --down-chevron-left: 0.75rem;
  --down-chevron-mid-point: calc(
    var(--down-chevron-left) + var(--down-chevron-width) / 2
  );
  --down-chevron-right: calc(
    var(--down-chevron-left) + var(--down-chevron-width)
  );
  display: flex;
}

@media (max-width: 47.9975em) {
  ._Wrapper_186da_1 {
    flex-direction: column;
  }
}

._LeftPanel_186da_33 {
  padding: var(--p-space-500);
  display: flex;
  gap: var(--p-space-300);
  flex-direction: column;
}

._LeftPanel_186da_33 > :last-child {
  margin-top: auto;
}

@media (min-width: 48em) {
  ._LeftPanel_186da_33 {
    width: 100%;
    max-width: calc(36% + var(--right-chevron-width));
    padding-right: calc(var(--right-chevron-width) + var(--p-space-500));
  }
}

._LeftPanelWithImage_186da_50 {
  padding-block-end: 0;
}

._Image_186da_54 {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

._RightPanel_186da_60 {
  position: relative;
  display: flex;
  flex: 1 1;
}

@media (min-width: 48em) {
  ._RightPanel_186da_60 {
    margin-left: calc(var(--right-chevron-width) * -1);
    -webkit-clip-path: polygon(
      0 0,
      100% 0,
      100% 100%,
      0 100%,
      0 var(--right-chevron-bottom),
      var(--right-chevron-width) var(--right-chevron-mid-point),
      0 var(--right-chevron-top)
    );
    clip-path: polygon(
      0 0,
      100% 0,
      100% 100%,
      0 100%,
      0 var(--right-chevron-bottom),
      var(--right-chevron-width) var(--right-chevron-mid-point),
      0 var(--right-chevron-top)
    );
  }
}

@media (max-width: 47.9975em) {
  ._RightPanel_186da_60 {
    margin-top: calc(var(--down-chevron-height) * -1);
    -webkit-clip-path: polygon(
      0 0,
      var(--down-chevron-left) 0,
      var(--down-chevron-mid-point) var(--down-chevron-height),
      var(--down-chevron-right) 0,
      100% 0,
      100% 100%,
      0 100%
    );
    clip-path: polygon(
      0 0,
      var(--down-chevron-left) 0,
      var(--down-chevron-mid-point) var(--down-chevron-height),
      var(--down-chevron-right) 0,
      100% 0,
      100% 100%,
      0 100%
    );
  }
}

._ChevronBorder_186da_96 {
  position: absolute;
  background: var(--p-color-border-secondary);
}

@media (max-width: 47.9975em) {
  ._ChevronBorder_186da_96 {
    width: 100%;
    height: calc(var(--down-chevron-height) + var(--chevron-border-width));
    -webkit-clip-path: polygon(
      0 0,
      calc(var(--down-chevron-left) + 0.00625rem) 0,
      var(--down-chevron-mid-point) var(--down-chevron-height),
      calc(var(--down-chevron-right) - 0.00625rem) 0,
      100% 0,
      100% var(--chevron-border-width),
      var(--down-chevron-right) var(--chevron-border-width),
      var(--down-chevron-mid-point)
        calc(var(--chevron-border-width) + var(--down-chevron-height)),
      var(--down-chevron-left) var(--chevron-border-width),
      0 var(--chevron-border-width)
    );
    clip-path: polygon(
      0 0,
      calc(var(--down-chevron-left) + 0.00625rem) 0,
      var(--down-chevron-mid-point) var(--down-chevron-height),
      calc(var(--down-chevron-right) - 0.00625rem) 0,
      100% 0,
      100% var(--chevron-border-width),
      var(--down-chevron-right) var(--chevron-border-width),
      var(--down-chevron-mid-point)
        calc(var(--chevron-border-width) + var(--down-chevron-height)),
      var(--down-chevron-left) var(--chevron-border-width),
      0 var(--chevron-border-width)
    );
  }
}

@media (min-width: 48em) {
  ._ChevronBorder_186da_96 {
    width: calc(var(--right-chevron-width) + var(--chevron-border-width));
    height: 100%;
    -webkit-clip-path: polygon(
      0 0,
      var(--chevron-border-width) 0,
      var(--chevron-border-width) var(--right-chevron-top),
      calc(var(--chevron-border-width) + var(--right-chevron-width))
        var(--right-chevron-mid-point),
      var(--chevron-border-width) var(--right-chevron-bottom),
      var(--chevron-border-width) 100%,
      0 100%,
      0 calc(var(--right-chevron-bottom) - 0.00625rem),
      calc(var(--right-chevron-width)) var(--right-chevron-mid-point),
      0 calc(var(--right-chevron-top) + 0.00625rem)
    );
    clip-path: polygon(
      0 0,
      var(--chevron-border-width) 0,
      var(--chevron-border-width) var(--right-chevron-top),
      calc(var(--chevron-border-width) + var(--right-chevron-width))
        var(--right-chevron-mid-point),
      var(--chevron-border-width) var(--right-chevron-bottom),
      var(--chevron-border-width) 100%,
      0 100%,
      0 calc(var(--right-chevron-bottom) - 0.00625rem),
      calc(var(--right-chevron-width)) var(--right-chevron-mid-point),
      0 calc(var(--right-chevron-top) + 0.00625rem)
    );
  }
}

._Content_186da_139 {
  display: flex;
  background-color: var(--p-color-bg-surface-secondary);
  padding: var(--p-space-500);
  width: 100%;
}

@media (min-width: 48em) {
  ._Content_186da_139 {
    padding-left: calc(var(--p-space-500) + var(--right-chevron-width) * 0.5);
  }
}

@media (min-width: 48em) {
  ._ContentWithoutHeading_186da_150 {
    padding-block-start: var(--p-space-1200);
  }
}

@media (max-width: 47.9975em) {
  ._LeftPanelTitle_186da_156 {
    padding-inline-end: var(--right-panel-title-offset);
  }
}

@media (min-width: 48em) {
  ._RightPanelTitle_186da_162 {
    padding-inline-end: var(--right-panel-title-offset);
  }
}

._LegendItem_15e7s_1 {
  display: flex;
  align-items: center;
  gap: var(--p-space-200);
  list-style-type: none;
}

._ChartWrapper_17bse_1 {
  pointer-events: none;
}

._LegendWrapper_17bse_5 {
  height: 0;
  overflow: visible;
}

._Mark_17oug_1 {
  --mark-color: var(--p-color-text-disabled);
  position: absolute;
  top: 50%;
  height: calc(100% + var(--p-space-200));
  width: var(--p-border-width-050);
  background-color: var(--mark-color);
  transform: translateY(-50%);
  border-radius: var(--p-border-radius-full);
}

._Comparison_17oug_12 {
  --mark-color: #029de3;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._Comparison_17oug_12:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: block;
  width: calc(var(--p-space-100) * 1.5);
  height: calc(var(--p-space-100) * 1.5);
  border-radius: var(--p-border-radius-full);
  background-color: var(--mark-color);
  transform: translate(-50%, 25%);
}

._Label_17oug_36 {
  white-space: nowrap;
  position: absolute;
  transform: translate(-50%);
}

._LabelBelow_17oug_42 {
  top: 150%;
}

._LabelAbove_17oug_46 {
  bottom: 150%;
}

._LabelInner_17oug_50 {
  display: inline-block;
  transition: transform var(--p-motion-duration-200) var(--p-motion-ease);
}

._Container_1gqx6_1 {
  padding: var(--p-space-1000) 0 var(--p-space-800);
}

._Track_1gqx6_5 {
  position: relative;
  background-color: var(--p-color-border-disabled);
  border-radius: var(--p-border-radius-100);
  height: 1.46875rem;
}

._Visualization_1kssn_8 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

._Mvp_1kssn_14 {
  padding: 0.625rem 1.25rem;
  border: var(--p-border-width-025) solid var(--p-color-border-secondary);
  border-radius: var(--p-border-radius-100);
  border-color: var(--p-color-border-disabled);
}

._Compressed_1kssn_22 {
  padding: 0.625rem;
}

._VisualizationHeader_1kssn_27 {
  display: flex;
  gap: var(--p-space-100);
  margin-bottom: var(--p-space-300);
  align-items: center;
}

._Comparison_1kssn_34 {
  display: flex;
}

._Comparison_1kssn_34 > div {
  display: flex;
  gap: var(--p-space-200);
  flex-direction: column;
  width: 50%;
}

._Comparison_1kssn_34 > div:last-child {
  padding-left: var(--p-space-500);
  border-left: var(--p-border-width-025) solid var(--p-color-border-secondary);
  border-color: var(--p-color-border-disabled);
}

._Comparison_1kssn_34 p {
  margin-top: var(--p-space-100);
}

._Container_15c1o_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 30.6225em) {
  ._Container_15c1o_1 {
    width: 1.75rem;
  }
}

@media (max-width: 30.6225em) {
  ._Container_15c1o_1 svg {
    height: 1.75rem;
    width: 1.75rem;
  }
}

._CompletedInsight_j1rxg_1 {
  display: flex;
}

._Content_j1rxg_5 {
  width: 100%;
  margin-left: var(--p-space-300);
}

._Icon_j1rxg_10 {
  padding-top: var(--p-space-100);
}

._Header_j1rxg_14 {
  display: flex;
  justify-content: space-between;
  gap: var(--p-space-200);
}

._Description_j1rxg_20 {
  width: 100%;
  margin-top: var(--p-space-200);
}

._Action_j1rxg_25 {
  margin-top: var(--p-space-400);
}

._centered_171mp_3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

._centered_171mp_3 > * {
  max-width: 33.75rem;
}

._hasMedia_171mp_14 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media (min-width: 30.625em) {
  ._hasMedia_171mp_14 {
    border-radius: var(--p-border-radius-200);
  }
}

@media (min-width: 38.75em) and (max-width: 47.9975em) {
  ._hasMedia_171mp_14 {
    flex-direction: row;
  }
}

@media screen and (min-width: 53.8125) and (max-width: 65.25em) {
  ._hasMedia_171mp_14 {
    flex-direction: row;
  }
}

@media (min-width: 77.5em) {
  ._hasMedia_171mp_14 {
    flex-direction: row;
  }
}

._MediaContainer_171mp_28 {
  height: 7.5rem;
}

@media (min-width: 38.75em) and (max-width: 47.9975em) {
  ._MediaContainer_171mp_28 {
    flex: 0 0 15.625rem;
    height: auto;
  }
}

@media screen and (min-width: 53.8125) and (max-width: 65.25em) {
  ._MediaContainer_171mp_28 {
    flex: 0 0 15.625rem;
    height: auto;
  }
}

@media (min-width: 77.5em) {
  ._MediaContainer_171mp_28 {
    flex: 0 0 15.625rem;
    height: auto;
  }
}

._hasBadge_171mp_37 ._Header_171mp_38:first-child:before {
  content: "";
  height: 1.25rem;
  width: va;
  float: right;
  display: block;
}

._Caption_171mp_45 {
  display: block;
  margin-bottom: var(--p-space-300);
}

._Header_171mp_38 {
  margin-right: var(--p-space-800);
  padding: var(--p-space-400) var(--p-space-400) 0;
}

._Header_171mp_38 + ._Footer_171mp_54,
._Header_171mp_38:only-child {
  padding: var(--p-space-400);
}

._FullWidthChildrenContainer_171mp_60 {
  padding: var(--p-space-400) 0;
}

._thumbnail_1t181_12 {
  overflow: hidden;
  border-radius: var(--p-border-radius-100);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.5rem;
  height: 2.5rem;
}

._darkenBackground_1t181_20 {
  background-color: var(--p-color-bg-surface-secondary);
}

._Footer_1s023_1 {
  padding: 0 var(--p-space-400) var(--p-space-400);
}

@media (width > 550px) {
  ._CardLayout_1psia_1 {
    --overflow-menu-button-offset: calc(
      var(--p-space-400) * 2 + var(--p-space-500)
    );
    display: flex;
    padding-right: var(--overflow-menu-button-offset);
    gap: var(--p-space-200);
  }
}

._CardContent_1psia_12 {
  flex: 3 1;
}

._CardImage_1psia_16 {
  display: none;
}

@media (width > 550px) {
  ._CardImage_1psia_16 {
    position: relative;
    display: flex;
    flex: 1 0;
    justify-content: center;
    align-items: center;
    margin: var(--p-space-400) 0;
    min-width: 12.5rem;
  }
}

._BadgeContainer_1psia_30 {
  display: inline-block;
  margin-bottom: var(--p-space-500);
}

._Illustration_1psia_35 {
  display: block;
}

@media (width > 550px) {
  ._Illustration_1psia_35 {
    position: absolute;
    width: auto;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
  }
}

._Table_h64al_4 {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

._bottomBorder_h64al_10 {
  border-bottom: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._HeadingCell_h64al_14 {
  min-width: 6.25rem;
  padding: var(--p-space-200) 0;
  text-align: left;
  border-bottom: 0;
  font-size: 0.9375rem;
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-500);
  text-transform: initial;
  letter-spacing: initial;
}

@media (min-width: 48em) {
  ._HeadingCell_h64al_14 {
    font-size: var(--p-font-size-350);
  }
}

._HeadingCell_h64al_14:last-of-type:not(:first-of-type) {
  text-align: right;
}

._Cell_h64al_29 {
  padding: var(--p-space-300) 0;
  border-top: var(--p-border-width-025) solid var(--p-color-border-secondary);
  word-break: break-word;
  border-bottom: 0;
}

._Cell_h64al_29:first-child {
  min-width: 5rem;
  max-width: 25rem;
  padding-left: var(--p-space-400);
  border-left: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._Cell_h64al_29:last-child {
  padding-right: var(--p-space-400);
  border-right: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._Cell_h64al_29:last-child:not(:first-child) {
  text-align: right;
}

._Table_h64al_4 tr:first-child ._Cell_h64al_29:first-child {
  border-top-left-radius: var(--p-border-radius-200);
}

._Table_h64al_4 tr:first-child ._Cell_h64al_29:last-child {
  border-top-right-radius: var(--p-border-radius-200);
}

._Table_h64al_4 tr:last-child ._Cell_h64al_29 {
  border-bottom: var(--p-border-width-025) solid var(--p-color-border-secondary);
}

._Table_h64al_4 tr:last-child ._Cell_h64al_29:first-child {
  border-bottom-left-radius: var(--p-border-radius-200);
}

._Table_h64al_4 tr:last-child ._Cell_h64al_29:last-child {
  border-bottom-right-radius: var(--p-border-radius-200);
}

._Card_16bco_1 {
  border-radius: inherit;
  box-shadow: var(--p-shadow-100);
  background-color: var(--p-color-bg-fill-info);
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: auto;
  background-position: right bottom;
}

@media (max-width: 30.6225em) {
  ._Card_16bco_1 {
    background-image: none !important;
  }
}

._Content_16bco_16 {
  border-radius: var(--p-border-radius-200);
  padding: var(--p-space-400);
}

._Icon_16bco_21 {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

._Message_16bco_26 * {
  color: inherit;
}

._title_16bco_30 {
  font-weight: var(--p-font-weight-bold);
}

._message_16bco_34 > a {
  text-wrap: nowrap;
  color: inherit;
}

._container_1rdfx_1 {
  --overflow-menu-button-offset: calc(var(--p-space-1200) + var(--p-space-100));
  padding: var(--p-space-400) var(--overflow-menu-button-offset)
    var(--p-space-400) var(--p-space-400);
}

._container_1rdfx_1 > div > div:first-child {
  height: 100%;
}

._img_1rdfx_12 {
  max-width: 100%;
  height: auto;
  width: auto;
}

@media (max-width: 30.6225em) {
  ._img_1rdfx_12 {
    display: none;
  }
}

._logo_1rdfx_22 {
  height: 2.125rem;
  width: 10.75rem;
}

._CardLayout_194y6_1 {
  --overflow-menu-button-offset: calc(var(--p-space-1200) + var(--p-space-100));
  padding-right: var(--overflow-menu-button-offset);
}

._CardContent_194y6_6 {
  flex: 3 1;
}

._CardImage_194y6_10 {
  display: none;
}

@media (width > 550px) {
  ._CardImage_194y6_10 {
    position: relative;
    display: flex;
    flex: 1 0;
    justify-content: center;
    align-items: center;
    margin: var(--p-space-400) 0;
    min-width: 12.5rem;
  }
}

._Illustration_194y6_24 {
  display: block;
}

@media (width > 550px) {
  ._Illustration_194y6_24 {
    position: absolute;
    width: auto;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
  }
}

._Badge_1pb1x_3 {
  position: relative;
  z-index: var(--p-z-index-2);
}

._Badge_1pb1x_3:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1875rem;
  border-radius: var(--p-border-radius-100) var(--p-border-radius-100) 0 0;
}

._Tag_1pb1x_16 {
  position: absolute;
  top: 0;
  right: 3.25rem;
}

._Bfcm_1pb1x_22:before {
  background-color: #25958f;
}

._Bfcm_1pb1x_22 ._Tag_1pb1x_16 {
  background: #25958f
    url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/bfcm-tag-rdHmtlKb0TF5.svg)
    no-repeat center center;
  background-size: 2.75rem auto;
  border-radius: 0 0 var(--p-border-radius-100) var(--p-border-radius-100);
  height: 2.1875rem;
  width: 4.4375rem;
}

._Holidays_1pb1x_38:before {
  background-color: var(--p-color-bg-fill-success-secondary);
}

._Holidays_1pb1x_38 ._Tag_1pb1x_16 {
  background: transparent
    url(https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/holidays-badge-CExul60n4xBD.svg)
    no-repeat center center;
  width: 2.25rem;
  height: 2.375rem;
}

._DialogWrapper_8i1du_10 {
  position: relative;
  margin: var(--p-space-200) 0;
  padding: var(--p-space-400);
  border-radius: none;
  background-color: #454f5b;
  color: var(--p-color-text-inverse);
  font-weight: var(--p-font-weight-medium);
}

@media (min-width: 34.375em) {
  ._DialogWrapper_8i1du_10 {
    border-radius: var(--p-border-radius-100);
  }
}

._DialogWrapper_8i1du_10:before {
  content: "";
  position: absolute;
  top: 0.3125rem * -1;
  left: 1.875rem;
  border-right: 0.3125rem solid transparent;
  border-bottom: 0.3125rem solid rgb(69, 79, 91);
  border-left: 0.3125rem solid transparent;
}

._Icon_8i1du_41 {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  line-height: normal;
  height: 0.875rem;
}

._Icon_8i1du_41:hover {
  cursor: pointer;
}

._Button_1gu96_1 {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  line-height: normal;
  font-size: 0.8125rem;
  color: var(--p-color-text-inverse);
}

._Button_1gu96_1:hover {
  cursor: pointer;
  text-decoration: underline;
}

._OverflowMenu_8v8kf_3 {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  margin: var(--p-space-400);
}

._VerticalMenu_8v8kf_12 {
  height: 4.5rem;
  display: flex;
  align-items: center;
  margin: 0 var(--p-space-400);
}

._CardWrapper_8v8kf_20 {
  position: relative;
}

._FeedContainer_1qxrp_3 {
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
  margin-top: var(--p-space-400);
}

._Image_h0o6t_1 {
  display: flex;
  padding-right: calc(var(--p-space-100) + var(--p-space-050));
}

._TaskLink_1e88m_1 {
  display: flex;
  align-items: center;
  gap: var(--p-space-200);
  text-decoration: none;
  padding: var(--p-space-200);
  color: var(--p-color-text);
}

._TaskLink_1e88m_1:hover {
  background-color: var(--p-color-bg-surface-hover);
}

._TaskLink_1e88m_1:active {
  background-color: var(--p-color-bg-surface-active);
}

@media (min-width: 0) and (max-width: 30.6225em) {
  ._TaskLink_1e88m_1 {
    padding-right: var(--p-space-400);
    padding-left: var(--p-space-400);
  }
}

._TaskLink_1e88m_1 img {
  vertical-align: middle;
}

._TasksContainer_iiban_3 {
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
  margin-top: var(--p-space-400);
  margin-bottom: var(--p-space-400);
}

._Grid_vf4vb_1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--p-width-400);
}

@media (max-width: 30.6225em) {
  ._Grid_vf4vb_1 {
    grid-template-columns: 1fr;
  }
}

._InnerWrapper_wa2vl_1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

._Thumbnail_wa2vl_7 {
  border-radius: var(--p-border-radius-200);
  background-color: var(--p-color-bg-surface-tertiary);
}

._MetaItem_wa2vl_12:not(:last-child):after {
  content: "•";
  color: var(--p-color-text-secondary);
  padding-left: var(--p-space-100);
}

._DescriptionWrapper_wa2vl_19 a:link,
._DescriptionWrapper_wa2vl_19 a:active,
._DescriptionWrapper_wa2vl_19 a:visited {
  color: var(--p-color-text-link);
  text-decoration: none;
}

._DescriptionWrapper_wa2vl_19 a:hover {
  color: var(--p-color-text-link-hover);
  text-decoration: underline;
}

._CardFlexWrapper_1afwv_1 {
  display: flex;
}

._CardFlexWrapper_1afwv_1 > * > * {
  display: flex;
}

._TabsWrapper_1kbch_1 {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--p-color-bg-surface);
}

@media (min-width: 0) {
  ._Image_gf9nv_1 {
    position: absolute;
    display: block;
    height: auto;
    object-fit: cover;
    transform: translateY(-7%) rotate(8deg);
    width: 12.375rem;
  }
}

@media (min-width: 0) {
  ._Image_gf9nv_1 {
    right: calc((var(--p-space-2400) + var(--p-space-150)) * -1);
  }
}

@media (min-width: 30.625em) {
  ._Image_gf9nv_1 {
    right: calc(var(--p-space-500) * -1);
  }
}

@media (min-width: 48em) {
  ._Image_gf9nv_1 {
    transform: translateY(-10%) rotate(8deg);
    right: unset;
    width: 17rem;
  }
}

@media (min-width: 90em) {
  ._Image_gf9nv_1 {
    transform: translate(-15%, -10%) rotate(8deg);
  }
}

._ValuePropFlexWrapper_gf9nv_30 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: var(--p-color-bg-surface-secondary);
  text-align: start;
  padding: var(--p-space-400);
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: var(--p-border-radius-200);
}

._ValuePropFlexWrapper_gf9nv_30:hover {
  background-color: var(--p-color-bg-surface-secondary-hover);
}

._ValuePropFlexWrapper_gf9nv_30 > * {
  height: 100%;
}

:root {
  --end-of-feed-marker-half-of-text-height: calc(
    var(--p-space-050) + var(--p-space-200)
  );
  --end-of-feed-marker-divider-height: var(--p-space-025);
}

._Container_mf6ou_10 {
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
  margin-bottom: var(--p-space-600);
  margin-top: var(--p-space-600);
  padding-block-start: var(--end-of-feed-marker-half-of-text-height);
}

._Text_mf6ou_17 {
  margin-block-start: calc(
    (
        var(--end-of-feed-marker-half-of-text-height) +
          var(--end-of-feed-marker-divider-height)
      ) * -1
  );
}

._Container_1eg1m_3 {
  box-sizing: content-box;
  max-width: 49.75rem;
  padding: 0;
  margin: 0 auto;
  margin-bottom: var(--p-space-400);
}
